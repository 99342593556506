import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import useSolana from '../../../hooks/useSolana'
import OfferStep from './OfferStep'
import NextStep from './NextStep'
import SelectNftStep from './SelectNftStep'
import SignStep from './SignStep'
import ShareStep from './ShareStep'

interface CollectionSwapAcceptModalProps {
  isOpen: boolean
  onClose: () => void
}

const CollectionSwapAcceptModal: React.FC<CollectionSwapAcceptModalProps> = function ({
  isOpen,
  onClose,
}) {
  const [step, setStep] = useState(0)

  const { connected, connect } =
    useSolana()
  const { uid } = useAppContext()

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect, connected])

  const handleCloseAndReset = () => {
    setStep(0)
    onClose()
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <OfferStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              setStep(1)
            }}
          />
        )
      case 1:
        return (
          <SelectNftStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              setStep(2)
            }}
          />
        )
      case 2:
        return (
          <SignStep
            previousStep={() => {
              setStep(1)
            }}
            nextStep={() => {
              setStep(3)
            }}
          />
        )
      case 3:
        return (
          <ShareStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              return
            }}
          />
        )
      default:
        return (
          <NextStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              return
            }}
          />
        )
    }
  }

  return (
    <Modal isOpen={true} onClose={onClose} size="4xl" closeOnOverlayClick={false} trapFocus={false}>
      <ModalOverlay />
      <ModalContent className="potato-modal-bg potato-radius " >
        <ModalCloseButton />
        {renderStep()}
      </ModalContent>
    </Modal>
  )
}

export default CollectionSwapAcceptModal
