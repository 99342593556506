import { useEffect, useMemo, useState } from 'react'
import {
  SwapData,
  OnChainSwapType,
  OnChainSwapData,
  TraitCollectionData,
  CollectionData,
} from '../types/collectionSwapV2'
import { calculateOnChainOffer } from '../utils/collectionSwapCalculator'
import { Nft } from '../types/nft'
import { EnvOpts, UTILS } from '@neoswap/solana-collection-swap'
import { BidTraits, getBidsTraits } from '../services/traitbids'
import { arraysEqual } from '../utils'
import { getNftDetails } from '../services/nfts.service'

let envOpts = {
  clusterOrUrl: process.env.REACT_APP_SOLANA_NETWORK!,
  // programId: '2vumtPDSVo3UKqYYxMVbDaQz1K4foQf6A31KiUaii1M7',
  // programId:''
  // idl?: Idl | true,
} as EnvOpts

export interface UseOnChainSwapsType {
  onChainOffers: SwapData[]
  onChainTraitOffers: SwapData[]
  refreshOnChainSwaps: () => void
  isLoadingOnChainSwaps: boolean
}

/**
 * Custom hook for managing on-chain swaps and trait offers
 * @param {Object} params - The parameters for the hook
 * @param {CollectionData[]} params.collectionsData - Array of collection data
 * @param {TraitCollectionData[]} params.traitCollectionsData - Array of trait collection data
 * @param {(nfts: Nft[]) => CollectionData[]} params.findTraitCollectionWBuySellForNfts - Function to find trait collection with buy/sell prices for NFTs
 * @returns {UseOnChainSwapsType} Object containing on-chain offers, trait offers, refresh function, and loading state
 */
export default function useOnChainSwaps({
  collectionsData,
  traitCollectionsData,
  findTraitCollectionWBuySellForNfts
}: {
  collectionsData: CollectionData[]
  traitCollectionsData: TraitCollectionData[]
  findTraitCollectionWBuySellForNfts: (nfts: Nft[]) => CollectionData[]
}): UseOnChainSwapsType {
  const [onChainSwapsData, setOnChainSwapsData] = useState<
    { sda: string; data: OnChainSwapData }[]
  >([])
  const [bidsTraits, setBidsTraits] = useState<BidTraits[]>([])
  const [nftDetails, setNftDetails] = useState<Nft[]>([])
  const [isLoadingOnChainSwaps, setIsLoadingOnChainSwaps] = useState<boolean>(true)

  /**
   * Refreshes the on-chain swaps data
   * @returns {Promise<void>}
   */
  const refreshOnChainSwaps = async (): Promise<void> => {
    setIsLoadingOnChainSwaps(true)
    const sdas = await UTILS.getOpenSda(envOpts)
    setOnChainSwapsData(sdas)
    setIsLoadingOnChainSwaps(false)
  }

  useEffect(() => {
    refreshOnChainSwaps()
  }, [])

  // Memoized array of unique NFT mints from on-chain swap data
  const nftsMints = useMemo(() => {
    return [
      ...onChainSwapsData.map((sda) => sda.data.nftMintMaker),
      ...onChainSwapsData.map((sda) => sda.data.nftMintTaker),
    ]
      .filter((nftMint) => nftMint)
      .filter((nftMint, index, self) => self.indexOf(nftMint) === index)
  }, [onChainSwapsData])

  // Memoized array of trait bid accounts from on-chain swap data
  const traitBidAccounts = useMemo(() => {
    return onChainSwapsData
      .filter((swap) => swap.data.swapType === OnChainSwapType.traits)
      .map((swap) => swap.data.bids.map((bid) => bid.collection))
      .flat()
  }, [onChainSwapsData])

  useEffect(() => {
    const updateBidsTraits = async (traitBidAccounts: string[]) => {
      setIsLoadingOnChainSwaps(true)
      if (traitBidAccounts.length === 0) return
      let bidsRoots = await Promise.all(
        traitBidAccounts.map(async (traitBidAccount) => {
          try {
            let data = await UTILS.getBidAccountData({ bidAccount: traitBidAccount, ...envOpts })
            return { roots: data.roots, bidAccount: traitBidAccount }
          } catch (error) {
            console.log('error', error)
            return undefined
          }
        })
      ).then((bidsRoots) =>
        bidsRoots.filter(
          (bidRoots): bidRoots is { roots: string[]; bidAccount: string } => bidRoots !== undefined
        )
      )

      const traits = await getBidsTraits(bidsRoots)

      let bidsTraits: BidTraits[] = []
      bidsRoots.map((bidRoots) => {
        let tmp = traits.find((bidTrait) => arraysEqual(bidTrait.roots, bidRoots.roots, true))
        if (tmp) {
          bidsTraits.push({ ...tmp, bidAccount: bidRoots.bidAccount })
        }
      })
      setBidsTraits(bidsTraits)
      setIsLoadingOnChainSwaps(false)
    }

    updateBidsTraits(traitBidAccounts)
  }, [traitBidAccounts])

  useEffect(() => {
    setIsLoadingOnChainSwaps(true)
    const nftIds = nftsMints.map((nftMint) => `solana-${nftMint}`)
    getNftDetails(nftIds).then((data) => {
      setNftDetails(data.data)
      setIsLoadingOnChainSwaps(false)
    })
  }, [nftsMints])

  /**
   * Memoized array of on-chain offers
   * Calculates offers based on on-chain swap data, NFT details, and collection data
   */
  const onChainOffers = useMemo(() => {
    if (!collectionsData || collectionsData.length === 0) return []
    if (!nftDetails || nftDetails.length === 0) return []

    let offers = onChainSwapsData
      .filter((swap) => swap.data.swapType == OnChainSwapType.native)
      .map((onChainSwap) => {
        const makerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintMaker)
        const takerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintTaker)
        const makerCollection = collectionsData.find(
          (collection) => collection.onChainId === makerNft?.collection.onChainId
        )

        return onChainSwap.data.bids.map((bid) => {
          const takerCollection = collectionsData.find(
            (collection) => collection.onChainId === bid.collection
          )
          if (!makerCollection || !takerCollection) return undefined

          return calculateOnChainOffer({
            onChainSwap: onChainSwap,
            makerCollection: makerCollection,
            takerCollection: takerCollection,
            makerNft: makerNft,
            takerNft: takerNft,
            bid: bid,
          })
        })
      })
      .flat()
      .filter((offer): offer is SwapData => offer !== undefined)

    return offers
  }, [onChainSwapsData, nftDetails, collectionsData])

  /**
   * Memoized array of on-chain trait offers
   * Calculates trait offers based on on-chain swap data, NFT details, collection data, and trait data
   */
  const onChainTraitOffers = useMemo(() => {
    if (!bidsTraits || bidsTraits.length === 0) return []
    if (!nftDetails || nftDetails.length === 0) return []
    if (!traitCollectionsData || traitCollectionsData.length === 0) return []

    let offers = onChainSwapsData
      .filter((swap) => swap.data.swapType == OnChainSwapType.traits)
      .map((onChainSwap) => {
        const makerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintMaker)
        const takerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintTaker)
        if (!makerNft) return undefined
        
        let makerCollection: CollectionData | undefined = findTraitCollectionWBuySellForNfts([makerNft])[0]
        if (!makerCollection) {
          makerCollection = collectionsData.find(
            (collection) => collection.collectionName === makerNft?.collection.name
          )
        }

        if (!makerCollection) return undefined

        return onChainSwap.data.bids.map((bid) => {
          const bidTraits = bidsTraits.find((bidTrait) => bidTrait.bidAccount === bid.collection)
          if (!bidTraits) return undefined
          const bidTrait = bidTraits.traits[0]
          const traitId = `${bidTrait.trait}_${bidTrait.values[0]}`.toLowerCase()

          const takerCollection = traitCollectionsData.find(
            (collection) => collection.traitId.toLowerCase() === traitId
          )
          if (!makerCollection || !takerCollection) return undefined

          let offer = calculateOnChainOffer({
            onChainSwap: onChainSwap,
            makerCollection: makerCollection,
            takerCollection: takerCollection,
            makerNft: makerNft,
            takerNft: takerNft,
            bid: bid,
            roots: bidTraits.roots,
          })
          
          return offer
        })
      })
      .flat()
      .filter((offer): offer is SwapData => offer !== undefined)
    return offers
  }, [onChainSwapsData, nftDetails, collectionsData, traitCollectionsData, bidsTraits])

  return {
    onChainOffers,
    onChainTraitOffers,
    refreshOnChainSwaps,
    isLoadingOnChainSwaps,
  }
}
