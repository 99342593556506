import React, { useState, useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

const ANNOUNCEMENTS = [
    'Rewards Revamp: Our Meow-ssive Update Is Here!',
    'Potato Token Will Be Launched on SOL'
]

const AnnouncementContainer = styled(Box)`
  overflow: hidden;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
`

const AnnouncementText = styled.div<{ offset: number }>`
  position: absolute;
  white-space: nowrap;
  transform: translateX(${props => props.offset}px);
`

const SPEED = 1.5 // Pixels per frame, adjust as needed

const AnnouncementBar = () => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [offset, setOffset] = useState(0)
    const containerRef = useRef<HTMLDivElement>(null)
    const textRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const containerWidth = containerRef.current?.offsetWidth || 300
        const textWidth = textRef.current?.offsetWidth || 300

        const animationFrame = () => {
            setOffset(prevOffset => {
                const newOffset = prevOffset - SPEED
                if (newOffset <= -textWidth) {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % ANNOUNCEMENTS.length)
                    return containerWidth
                }
                return newOffset
            })
            requestAnimationFrame(animationFrame)
        }

        const animationId = requestAnimationFrame(animationFrame)

        return () => cancelAnimationFrame(animationId)
    }, [])

    return (
        <AnnouncementContainer py={2} className="announcement mb-2" ref={containerRef}>
            <AnnouncementText
                offset={offset}
                className="archivo-black"
                ref={textRef}
            >
                {ANNOUNCEMENTS[currentIndex]}
            </AnnouncementText>
        </AnnouncementContainer>
    )
}

export default AnnouncementBar