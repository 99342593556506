import { Heading, Spinner } from "@chakra-ui/react";
import Logo from "../assets/logo.png";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import LandingFooter from "./LandingFooter";
import { Link } from "react-router-dom";

export default function Updates() {
  const [data, setData] = useState<any>({});

  useEffect(() => {
    import("../data/updates.json").then((data) => {
      setData(data);
    });
  }, []);

  const updates = [...(data?.updates || [])].reverse();

  const getDate = (timestamp: number) => {
    return dateFormat(timestamp, "mmmm dS yyyy");
  };

  return (
    <div className="landing-page">
      <div className="content">
        <div className="page-header">
          <div className="logo-wrapper">
            <Link to={'/'}>
              <img src={Logo} alt="NeoSwap" className="logo" />
            </Link>
            <span className="beta-tag">beta</span>
          </div>
          <Heading as="h1" fontSize="5xl">
            Updates
          </Heading>
          <Heading as="h4" fontSize="md" className="sub-title">
            Below you will find the latest development updates.
          </Heading>
        </div>
        {!data?.updates ? (
          <div className="loader">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#ed6cd8"
              size="xl"
            />
          </div>
        ) : (
          <div className="updates">
            {updates.map((item) => {
              return (
                <div key={item.version} className="update-item box">
                  <div className="update-header">
                    <h3>NeoSwap version {item.version}</h3>
                    <p>{typeof item.date === "number" ? getDate(item.date) : item.date}</p>
                  </div>

                  <ul>
                    {item.updates.map((update: any, index: number) => {
                      return <li key={index}>{update}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        )}

      </div>
      <LandingFooter />
    </div>
  );
}
