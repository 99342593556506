import { ModalBody } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SurplusComponent from '../Surplus'
import SwapDetailsComponent from '../SwapDetails'
import ActionButton from '../Buttons/ActionButton'
import { CopyButton, TwitterButton } from '../Buttons/SharingButtons'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import { useUA } from '../../../contexts/userTracking'

interface DetailsStepProps {
  previousStep: () => void
}

const DetailsStep: React.FC<DetailsStepProps> = function ({ previousStep }) {
  const { uid } = useAppContext()
  const { selectedBid, solToUsd, transactionsStatus } = useCollectionSwaps()
  const { addGAEvent } = useUA()

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid, true)
  }, [selectedBid, uid])

  return (
    <ModalBody p={0} className="potato-modal-bg potato-radius text-dark-potato">
      <div className="p-6">
        <h2 className="pb-4 archivo-black font-2xl">Create Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && <SwapDetailsComponent {...swapOverview} past={false} />}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && (
              <div className="flex flex-col justify-center h-full">
                <SurplusComponent
                  userSavings={swapOverview?.userSavings}
                  chainUsdValue={solToUsd}
                  calculatedDate={''}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center gap-2 px-6 pb-6">
        <ActionButton
          onClick={previousStep}
          text="< Back"
        />
        <CopyButton
          onClick={() => addGAEvent('create-swap_swap-link_copy')}
          mode={'bid'}
          isDisabled={transactionsStatus !== 'success'}
          disabledTooltip={'Sign swap first'}
        />
        <TwitterButton
          mode={"you-will-save"}
          onClick={() => addGAEvent('create-swap_swap_share')}
          isDisabled={transactionsStatus !== 'success'}
          disabledTooltip={'Sign swap first'}
        />
      </div>
    </ModalBody>
  )
}

export default DetailsStep
