import { Box, Link, Skeleton, Text } from '@chakra-ui/react';

interface Transaction {
  description: string;
  hash?: string;
  status: string;
}

interface TransactionTableProps {
  isLoadingTransactions: boolean;
  transactions: Transaction[] | null;
}

const TransactionTable: React.FC<TransactionTableProps> = ({ isLoadingTransactions, transactions }) => {
  const formatStatus = (status: string) => {
    let color = "";
    switch (status) {
      case "pending":
        color = "text-starAtlas-brightYellow";
        break;
      case "broadcast":
        color = "text-starAtlas-brightYellow";
        break;
      case "success":
        color = "text-green-500";
        break;
      case "failed":
        color = "text-red-500";
        break;
      case "Timeout":
        color = "text-red-500";
        break;
      default:
        color = "text-white";
        break;
    }
    return <span className={`${color} capitalize`}>{status}</span>;
  };

  return (
    <>
      <Text className=" text-dark-potato  text-lg archivo-black text-left w-full">Swap Status:</Text>
      {transactions && transactions.length > 1 ? (<p className='text-starAtlas-brightYellow text-sm font-normal leading-none'>You need to sign multiple transactions; please wait and do not close this modal.</p>) :
        (<p className='text-starAtlas-brightYellow text-sm font-normal leading-none'>You need to sign this transaction; please wait and do not close this modal.</p>)
      }
      <Box overflowX="auto" width="full" overflowY="auto" maxHeight="370px">
        <table className="w-full  text-dark-potato  border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Action</th>
              <th style={{ textAlign: 'left' }}>Tx hash</th>
              <th style={{ textAlign: 'left' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingTransactions ? (
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={`skeleton_row_${index}`} className=' text-xs p-2 h-10 table-bg !rounded-lg'>
                  <td className='pl-4'><Skeleton height="20px" /></td>
                  <td><Skeleton height="20px" /></td>
                  <td><Skeleton height="20px" /></td>
                </tr>
              ))
            ) : transactions && transactions.map((tx, index) => (
              <tr key={tx.description + '-' + index} className=' text-xs p-2 h-10 table-bg  !rounded-lg'>
                <td className='pl-4'>{tx.description ? tx.description.slice(0, 30) : ""}</td>
                <td>
                  {tx.hash ? (
                    <Link
                      href={`https://solscan.io/tx/${tx.hash}`}
                      isExternal
                      textDecoration="underline"
                      _hover={{ textDecoration: "underline" }}
                      color="#FFBE4D"
                      _focus={{ boxShadow: "none" }}
                    >
                      {tx.hash.slice(0, 3) + "..." + tx.hash.slice(-3)}
                    </Link>
                  ) : '...'}
                </td>
                <td>
                  {formatStatus(tx.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default TransactionTable;
