import React, { useEffect } from 'react'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { formatSignificantDigits } from '../../../utils'
import { SwapData } from '../../../types/collectionSwapV2'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import { useAppContext } from '../../../contexts/appContext'
import ActionButton from './ActionButton'
import { useClipboard } from '@chakra-ui/react'

type TwitterMode = 'i-saved' | 'you-will-save'

const formatSol = (value: any) => {
  return `${!isNaN(parseFloat(value)) ? formatSignificantDigits(parseFloat(value), 2) : '0.00'}`
}

const openWindow = (url: string) => {
  window.open(
    url,
    '',
    'left=0,top=50,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
  )
}

const formatIntentUrl = ({
  selectedBid,
  mode,
  uid,
}: {
  selectedBid: SwapData
  mode: TwitterMode
  uid?: string
}) => {
  let user = 'taker' // in the future this can be maker depending on the mode

  const savingsData = user === 'taker' ? selectedBid.takerSwapSavings : selectedBid.makerSwapSavings
  const savings = formatSol(savingsData.savings)

  const giveCollection =
    user === 'taker' ? selectedBid.takerCollection : selectedBid.makerCollection
  const getCollection = user === 'taker' ? selectedBid.makerCollection : selectedBid.takerCollection

  let giveTwitterHandle = giveCollection.twitterHandle.replace('https://twitter.com/', '')
  let getTwitterHandle = getCollection.twitterHandle.replace('https://twitter.com/', '')

  let bidId = swapDataToOverview(selectedBid, uid, true).bidId

  let url: string, text: string

  if (mode === 'i-saved') {
    url = bidId
      ? `https://app.potatocat.ai/share/explore?bidId=${bidId}&type=d`
      : 'https://app.potatocat.ai/explore'
    text = `Meow! I saved ${savings} SOL by swapping 1 @${giveTwitterHandle} for 1 @${getTwitterHandle} on @potatocat_ai powered by @tulle_ai!
        \n\nStart saving your $SOL here: `
  } else if (mode === 'you-will-save') {
    url = bidId
      ? `https://app.potatocat.ai/share/explore?bidId=${bidId}`
      : 'https://app.potatocat.ai/explore'
    text = `Meow! Save ${savings} SOL by swapping 1 @${giveTwitterHandle} for 1 @${getTwitterHandle} on @potatocat_ai powered by @tulle_ai!
        \n\nLink: `
  } else {
    throw new Error('Invalid mode')
  }

  let shareURL = 'https://twitter.com/intent/tweet?'
  shareURL += `url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`

  return shareURL
}

interface TwitterButtonProps {
  mode: TwitterMode
  onClick?: () => void
  isDisabled?: boolean
  disabledTooltip?: string
}

export const TwitterButton: React.FC<TwitterButtonProps> = ({
  mode,
  onClick,
  isDisabled,
  disabledTooltip,
}) => {
  const { uid } = useAppContext()
  const { selectedBid } = useCollectionSwaps()

  const handleActionButton = () => {
    if (!selectedBid) throw new Error('No selected bid')

    openWindow(formatIntentUrl({ selectedBid, mode, uid }))

    if (onClick) onClick()
  }

  return (
    <ActionButton
      onClick={handleActionButton}
      text={'Share on X'}
      isDisabled={isDisabled}
      tooltipDisabledText={disabledTooltip}
    />
  )
}

type CopyMode = 'bid' | 'my-swaps'

interface CopyButtonProps {
  mode: CopyMode
  onClick?: () => void
  isDisabled?: boolean
  disabledTooltip?: string
}
export const CopyButton: React.FC<CopyButtonProps> = ({
  mode,
  onClick,
  isDisabled,
  disabledTooltip,
}) => {
  const { uid } = useAppContext()
  const { selectedBid, modalMode, selectedTraitCollectionSlug } = useCollectionSwaps()
  const { onCopy, setValue, hasCopied } = useClipboard('')

  useEffect(() => {
    if (mode === 'bid' && selectedBid) {
      const bidId = swapDataToOverview(selectedBid, uid, true).bidId
      if (modalMode === 'trait' && selectedTraitCollectionSlug) {
        setValue(`https://app.potatocat.ai/share/collection/${selectedTraitCollectionSlug}?bidId=${bidId}`)
      } else {
        setValue(`https://app.potatocat.ai/share/explore?bidId=${bidId}`)
      }
    } else if (mode === 'my-swaps') {
      setValue(`https://app.potatocat.ai/profile?address=${uid?.replace('solana-', '')}`)
    }
  }, [mode, selectedBid, uid, modalMode, selectedTraitCollectionSlug])

  const handleClick = () => {
    onCopy()
    if (onClick) onClick()
  }

  return (
    <ActionButton
      onClick={handleClick}
      text={hasCopied ? 'Copied' : 'Copy Link'}
      isDisabled={isDisabled}
      tooltipDisabledText={disabledTooltip}
    />
  )
}
