import { SwapOverview, Item, SwapData } from '../types/collectionSwapV2'
import { UTILS, NEOSWAP_PROGRAM_ID } from '@neoswap/solana-collection-swap'

export const swapDataToOverview = (
  swap: SwapData,
  uid: string | undefined,
  isMaker: boolean = false
): SwapOverview => {
  let giveItemMaker: Item
  if (swap.makerNft) {
    giveItemMaker = {
      image: swap.makerNft!.image,
      collectionName: swap.makerCollection.collectionName,
      nftName: swap.makerNft?.name || 'Any',
      onChainId: swap.makerNft?.address || '',
    }
  } else {
    giveItemMaker = {
      image: swap.makerCollection.imageUrl!,
      collectionName: swap.makerCollection.collectionName,
      nftName: 'Any',
      onChainId: swap.makerCollection.onChainId,
    }
  }

  // console.log('swap.takerCollection: ', swap.takerCollection)
  // console.log('swap: ', swap)
  let getItemMaker: Item
  if (swap.takerNft) {
    getItemMaker = {
      image: swap.takerNft!.image,
      collectionName: swap.takerCollection.collectionName,
      nftName: swap.takerNft?.name || 'Any',
      onChainId: swap.takerNft?.address || '',
    }
  } else {
    getItemMaker = {
      image: 'traitKey' in swap.takerCollection ? swap.takerCollection.image : swap.takerCollection.imageUrl!,
      collectionName: swap.takerCollection.collectionName,
      nftName: 'traitKey' in swap.takerCollection ? `${swap.takerCollection.traitKey}: ${swap.takerCollection.traitValue}` : 'Any',
      onChainId: swap.takerCollection.onChainId,
    }
  }

  const isCurrentMaker = isMaker || swap.onChainSwap?.data.maker === uid?.replace('solana-', '') || !swap.isOnChain

  const userSavings = isCurrentMaker ? swap.makerSwapSavings : swap.takerSwapSavings
  let giveItem = isCurrentMaker ? giveItemMaker : getItemMaker
  let getItem = isCurrentMaker ? getItemMaker : giveItemMaker

  let swapOverview: SwapOverview = {
    giveItem,
    getItem,
    userSavings,
    endTime: swap.onChainSwap?.data?.endTime || 0,
    isMaker: isCurrentMaker,
    isOnChain: swap.isOnChain,
    traitId: 'traitId' in swap.takerCollection ? swap.takerCollection.traitId : undefined,    
  }

  if (swap.bidId) {
    swapOverview.bidId = swap.bidId
  } else if (uid && isMaker && swap.makerNft?.address) {
    let maker = uid.replace('solana-', '')
    let sda = UTILS.getSda(maker, swap.makerNft?.address, NEOSWAP_PROGRAM_ID)
    swapOverview.bidId = `${sda}-${getItemMaker.onChainId}`
  }
  
  return swapOverview
}
