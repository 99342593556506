import { ModalBody, Text } from '@chakra-ui/react'
import CloseButton from '../Buttons/CloseButton'

interface MySwapModalProps {
  onClose: () => void
  nextStep: () => void
}

const MySwapModal: React.FC<MySwapModalProps> = function ({ onClose, nextStep }) {
  return (
    <ModalBody p={0}>
      <div className="flex flex-col items-center justify-center mb-4">
        <div className="flex flex-col items-center">
          <Text className="pt-4">My Swap</Text>
        </div>
      </div>
      <div className="w-full flex gap-2 px-6 pb-6">
        <CloseButton onClick={onClose} />
      </div>
    </ModalBody>
  )
}

export default MySwapModal
