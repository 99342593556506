import { useState, useCallback, useEffect } from 'react'
import {
  UTILS,
  BundleTransaction,
  CREATE_INSTRUCTIONS as colIx,
  TakeSArg,
  EnvOpts,
  TraitBid,
} from '@neoswap/solana-collection-swap'
import { useToast } from '@chakra-ui/react'
import { useAppContext } from '../contexts/appContext'
import { useUA } from '../contexts/userTracking'
import useSolana from './useSolana'
import { getProof, createTraitBid, TraitFilter } from '../services/traitbids'
import { SwapData } from '../types/collectionSwapV2'

const CHECK_TRANSACTION_INTERVAL = 5000

declare global {
  interface BigInt {
    toJSON(): Number
  }
}

BigInt.prototype.toJSON = function () {
  return Number(this)
}

/**
 * Configuration for the Solana environment
 */
let envOpts = {
  prioritizationFee: 1e6,
  clusterOrUrl: process.env.REACT_APP_SOLANA_NETWORK!,
  // programId: '2vumtPDSVo3UKqYYxMVbDaQz1K4foQf6A31KiUaii1M7',
  // programId:''
  // idl?: Idl | true,
} as EnvOpts

export type TransactionsStatus = 'loading' | 'signing' | 'checking' | 'success' | 'error'

interface UseTransactionsProps {
  selectedBid: SwapData | undefined
  bidsToCreate: SwapData[] | undefined
  isSwapTraitBid: boolean | undefined
  waveFees: boolean
}

export interface UseTransactionsType {
  transactions: BundleTransaction[]
  setTransactions: React.Dispatch<React.SetStateAction<BundleTransaction[]>>
  transactionsStatus: TransactionsStatus | undefined
  fetchTakeSwapTransactions: () => void
  fetchMakeSwapTransactions: () => void
  fetchTraitMakeSwapTransactions: () => void
  fetchCancelSwapTransactions: () => void
  fetchCancelSingleBidTransactions: () => void
}

/**
 * Custom hook for managing transactions related to NFT swaps
 * @param {UseTransactionsProps} props - The properties required for the hook
 * @returns {UseTransactionsType} An object containing transaction-related state and functions
 */
const useTransactions = ({
  selectedBid,
  bidsToCreate,
  isSwapTraitBid,
  waveFees,
}: UseTransactionsProps): UseTransactionsType => {
  const [transactions, setTransactions] = useState<BundleTransaction[]>([])
  const [transactionsStatus, setTransactionsStatus] = useState<TransactionsStatus | undefined>(
    undefined
  )

  const { checkTransactions, signTransactions } = useSolana()
  const { uid } = useAppContext()
  const toast = useToast()
  const { addGAEvent } = useUA()

  /**
   * Fetches transactions for taking a swap
   */
  const fetchTakeSwapTransactions = useCallback(async () => {
    if (!uid) {
      console.log('No uid for takeSwap', uid)
      return
    }
    if (!selectedBid || !selectedBid.onChainSwap) return
    if (!selectedBid.takerNft?.address) return
    if (!selectedBid.onChainSwap.bid) return
    addGAEvent('any_take-swap_fetch-txs', { bidId: selectedBid.bidId })

    setTransactionsStatus('loading')
    let randomNumber = Math.ceil(Math.random() * 100)
    if (randomNumber === 42) randomNumber = 43
    if (waveFees) randomNumber = 42

    let args: TakeSArg = {
      swapDataAccount: selectedBid.onChainSwap?.sda,
      taker: uid.replace('solana-', ''),
      nftMintTaker: selectedBid.takerNft?.address,
      bid: selectedBid.onChainSwap.bid,
      n: randomNumber,
    }
    if (isSwapTraitBid) {
      const proofResponse = await getProof({
        mint: selectedBid.takerNft?.address,
        roots: selectedBid.roots || [],
      })
      if (!proofResponse || !proofResponse.success) throw new Error('No proof response found')
      args.traitProofs = proofResponse.data.proof
      args.traitIndex = proofResponse.data.index
    }

    console.log('args', args)
    let transactions: BundleTransaction[] = []
    try {
      transactions = await colIx.createTakeAndCloseSwapInstructions({ ...args, ...envOpts })
    } catch (error: any) {
      setTransactionsStatus('error')
      addGAEvent('any_take-swap_fetch-txs-error', { bidId: selectedBid.bidId })
      if (error.message) {
        console.error('error', error.message)
        const errorMessage = error?.message?.message?.message
        if (
          typeof errorMessage === 'string' &&
          errorMessage.includes('Account does not exist or has no data')
        ) {
          toast({
            title: 'Error',
            description: 'Ooops! Swap has been taken :(',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        }
      }
      return
    }

    setTransactions(transactions)
    setTransactionsStatus('signing')
    addGAEvent('any_take-swap_fetch-txs-success', { bidId: selectedBid.bidId })
  }, [selectedBid, waveFees, uid, isSwapTraitBid, envOpts])

  /**
   * Fetches transactions for making a trait-based swap
   */
  const fetchTraitMakeSwapTransactions = useCallback(async () => {
    if (!uid) {
      console.log('No uid for makeSwap', uid)
      return
    }
    if (!bidsToCreate || bidsToCreate.length < 1) {
      console.log('No bidsToCreate for makeSwap', bidsToCreate)
      return
    }

    const giveNft = bidsToCreate[0].makerNft

    if (!giveNft) {
      console.log('No giveNft for makeSwap', giveNft)
      return
    }
    for (const bid of bidsToCreate) {
      if (!bid.makerNft || bid.makerNft.address !== giveNft.address) {
        console.log('Bid makerNft does not match giveNft for makeSwap', bid)
        return
      }
    }

    addGAEvent('any_make-swap_fetch-txs', { nft: giveNft.address })

    setTransactionsStatus('loading')

    const makerBids = await Promise.all(
      bidsToCreate.map(async (bid) => {
        const getCollection = bid.takerCollection
        if (!('traitId' in getCollection)) {
          throw new Error('No traitId found')
        }
        const traitFilters: TraitFilter[] = [
          {
            trait: getCollection.traitKey,
            values: [getCollection.traitValue],
          },
        ]

        console.log('traitFilters', traitFilters)

        const traitBidResponse = await createTraitBid({
          collectionId: getCollection.onChainId,
          traits: traitFilters,
        })

        if (!traitBidResponse || !traitBidResponse.success)
          throw new Error('No trait bid response found')

        console.log('traitBidResponse', traitBidResponse)

        const makeSwapDetails = bid.makerSwapOffer
        const takeSwapDetails = bid.takerSwapOffer
        const collectionMint = getCollection.onChainId
        const amount = -Math.trunc(makeSwapDetails.baseCost)
        const makerNeoswapFee = Math.trunc(makeSwapDetails.fee)
        const makerRoyalties = Math.trunc(makeSwapDetails.royalties)
        const takerNeoswapFee = Math.trunc(takeSwapDetails.fee)
        const takerRoyalties = Math.trunc(takeSwapDetails.royalties)

        const makerTraitBids = {
          collection: collectionMint,
          amount,
          makerNeoswapFee,
          takerNeoswapFee,
          takerRoyalties,
          makerRoyalties,
          proofs: traitBidResponse.data.roots,
        } as TraitBid

        return makerTraitBids
      })
    )

    let args = {
      maker: uid.replace('solana-', ''),
      nftMintMaker: giveNft.address,
      paymentMint: 'So11111111111111111111111111111111111111112',
      traitBids: makerBids,
      endDate: Math.floor(Date.now() / 1000) + 86400 * 5,
    }

    console.log('args', args)

    try {
      const inTransactions = (await colIx.createMakeTraitSwapInstructions({ ...args, ...envOpts }))
        .bTxs

      setTransactions(inTransactions)
    } catch (error: any) {
      setTransactionsStatus('error')
      addGAEvent('any_make-swap_fetch-txs-error', { nft: giveNft.address })
      console.error('error', error)

      return
    }

    setTransactionsStatus('signing')
    addGAEvent('any_make-swap_fetch-txs-success', { nft: giveNft.address })
  }, [bidsToCreate, uid])

  /**
   * Fetches transactions for making a regular swap
   */
  const fetchMakeSwapTransactions = useCallback(async () => {
    if (!uid) {
      console.log('No uid for makeSwap', uid)
      return
    }
    if (!bidsToCreate || bidsToCreate.length < 1) {
      console.log('No bidsToCreate for makeSwap', bidsToCreate)
      return
    }

    const giveNft = bidsToCreate[0].makerNft

    if (!giveNft) {
      console.log('No giveNft for makeSwap', giveNft)
      return
    }
    for (const bid of bidsToCreate) {
      if (!bid.makerNft || bid.makerNft.address !== giveNft.address) {
        console.log('Bid makerNft does not match giveNft for makeSwap', bid)
        return
      }
    }

    addGAEvent('any_make-swap_fetch-txs', { nft: giveNft.address })

    setTransactionsStatus('loading')
    const makerBids = bidsToCreate.map((bid) => {
      const makeSwapDetails = bid.makerSwapOffer
      const takeSwapDetails = bid.takerSwapOffer
      const collectionMint = bid.takerCollection.onChainId

      const amount = -Math.trunc(makeSwapDetails.baseCost)
      const makerNeoswapFee = Math.trunc(makeSwapDetails.fee)
      const makerRoyalties = Math.trunc(makeSwapDetails.royalties)
      const takerNeoswapFee = Math.trunc(takeSwapDetails.fee)
      const takerRoyalties = Math.trunc(takeSwapDetails.royalties)

      let makerBid = {
        collection: collectionMint,
        amount,
        makerNeoswapFee,
        takerNeoswapFee,
        takerRoyalties,
        makerRoyalties,
      }

      return makerBid
    })

    let args = {
      maker: uid.replace('solana-', ''),
      nftMintMaker: giveNft.address,
      paymentMint: 'So11111111111111111111111111111111111111112',
      bids: makerBids,
      endDate: Math.floor(Date.now() / 1000) + 86400 * 5,
    }
    console.log('args', args)

    try {
      const inTransactions = (await colIx.createMakeSwapInstructions({ ...args, ...envOpts })).bTxs

      setTransactions(inTransactions)
    } catch (error: any) {
      setTransactionsStatus('error')
      addGAEvent('any_make-swap_fetch-txs-error', { nft: giveNft.address })
      console.error('error', error)

      return
    }

    setTransactionsStatus('signing')
    addGAEvent('any_make-swap_fetch-txs-success', { nft: giveNft.address })
  }, [bidsToCreate, uid])

  /**
   * Fetches transactions for cancelling a swap
   */
  const fetchCancelSwapTransactions = useCallback(async () => {
    if (!uid) {
      console.log('No uid for cancelSwap', uid)
      return
    }
    if (!selectedBid || !selectedBid.onChainSwap) return

    addGAEvent('any_cancel-swap_fetch-txs', { sda: selectedBid.onChainSwap.sda })

    setTransactionsStatus('loading')
    const args = {
      swapDataAccount: selectedBid.onChainSwap.sda,
      signer: uid.replace('solana-', ''),
    }
    console.log('args', args)
    const transactions = await colIx.createCancelSwapInstructions({ ...args, ...envOpts })

    setTransactions([transactions])
    setTransactionsStatus('signing')
    addGAEvent('any_cancel-swap_fetch-txs-success', { sda: selectedBid.onChainSwap.sda })
  }, [selectedBid, uid])

  /**
   * Fetches transactions for cancelling a single bid
   */
  const fetchCancelSingleBidTransactions = useCallback(async () => {
    if (!uid) {
      console.log('No uid for cancelSingleBid', uid)
      return
    }
    if (!selectedBid || !selectedBid.onChainSwap || !selectedBid.onChainSwap.bid) return

    addGAEvent('any_cancel-single-bid_fetch-txs', { bidId: selectedBid.bidId })
    setTransactionsStatus('loading')
    const args = {
      rmBids: [selectedBid.onChainSwap.bid],
      swapDataAccount: selectedBid.onChainSwap.sda,
      maker: uid.replace('solana-', ''),
    }
    const transactions = await colIx.createRmBidBt({ ...args, ...envOpts })

    setTransactions([transactions])
    setTransactionsStatus('signing')
    addGAEvent('any_cancel-single-bid_fetch-txs-success', { bidId: selectedBid.bidId })
  }, [selectedBid, uid])

  /**
   * Converts transaction description to GA event name
   * @param {BundleTransaction} tx - The transaction
   * @returns {string} GA event name
   */
  const txDescriptionToGA = (tx: BundleTransaction) => {
    const DESC = UTILS.DESC
    let action = Object.keys(DESC).find((key) => DESC[key as keyof typeof DESC] === tx.description)

    if (!action) return tx.description

    switch (action) {
      case 'makeSwap':
        return 'make-swap'
      case 'takeSwap':
        return 'take-swap'
      case 'payRoyalties':
        return 'pay-royalties'
      case 'payMakerRoyalties':
        return 'pay-royalties'
      case 'payTakerRoyalties':
        return 'pay-royalties'
      case 'claimSwap':
        return 'claim-swap'
      case 'cancelSwap':
        return 'cancel-swap'
      case 'addBid':
        return 'add-bid'
      case 'rmBid':
        return 'cancel-single-bid'
      case 'setTime':
        return 'update-end-time'
      case 'close':
        return 'close-swap-account'
    }
  }

  /**
   * Adds GA events for transactions
   * @param {BundleTransaction[]} transactions - The transactions
   * @param {'sign' | 'confirm'} type - The type of action
   * @param {'success' | 'error'} outcome - The outcome of the action
   */
  const addGAEventForTransactions = (
    transactions: BundleTransaction[],
    type: 'sign' | 'confirm',
    outcome: 'success' | 'error'
  ) => {
    for (let i = 0; i < transactions.length; i++) {
      const tx = transactions[i]
      const txDescription = txDescriptionToGA(tx)
      addGAEvent(`any_${txDescription}_${type}-tx-${outcome}`, { hash: tx.hash })
    }
  }

  /**
   * Handles signing of transactions
   */
  const handleSignTransactions = async () => {
    try {
      let txIdxs = transactions.map((tx, i) => i)

      let minPriority = transactions.reduce((acc, tx) => {
        if (tx.priority === undefined || tx.status !== 'pending') return acc
        return Math.min(acc, tx.priority)
      }, Number.MAX_SAFE_INTEGER)

      txIdxs = txIdxs.filter(
        (i) => transactions[i].priority === minPriority && transactions[i].status === 'pending'
      )

      if (!txIdxs.length) return

      let transactionsTmp = await signTransactions(
        transactions.filter((tx, i) => txIdxs.includes(i))
      )
      addGAEventForTransactions(transactionsTmp, 'sign', 'success')

      transactionsTmp = transactionsTmp.concat(transactions.filter((tx, i) => !txIdxs.includes(i)))

      setTransactions([...transactionsTmp])
    } catch (error: any) {
      addGAEventForTransactions(transactions, 'sign', 'error')
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setTransactionsStatus('error')
    }
  }

  /**
   * Adds GA events for transaction status changes
   * @param {BundleTransaction[]} newTxs - The new transactions
   * @param {BundleTransaction[]} oldTxs - The old transactions
   */
  const addGAEventForTxStatusChange = (
    newTxs: BundleTransaction[],
    oldTxs: BundleTransaction[]
  ) => {
    let txUpdates = newTxs.filter((tx) => {
      let txOld = oldTxs.find((txOld) => txOld.hash === tx.hash)
      if (!txOld) return false

      return txOld.status !== tx.status
    })

    let txUpdatesSuccess = txUpdates.filter((tx) => tx.status === 'success')
    let txUpdatesError = txUpdates.filter((tx) => tx.status === 'failed' || tx.status === 'Timeout')

    addGAEventForTransactions(txUpdatesSuccess, 'confirm', 'success')
    addGAEventForTransactions(txUpdatesError, 'confirm', 'error')
  }

  /**
   * Handles checking of transactions
   */
  const handleCheckTransactions = async () => {
    if (!transactions.length) return
    let transactionsTmp = await checkTransactions(transactions)
    addGAEventForTxStatusChange(transactionsTmp, transactions)
    setTransactions([...transactionsTmp])
  }

  useEffect(() => {
    console.log('transactions', transactions)
    // 'loading' | 'signing' | 'checking' | 'success' | 'error'
    let noTxs = transactions.length === 0
    let allSuccess = transactions.every((tx) => tx.status === 'success')
    let someFailed = transactions.some((tx) => tx.status === 'failed' || tx.status === 'Timeout')
    let someChecking = transactions.some((tx) => tx.status === 'broadcast')
    let someSigning = transactions.some((tx) => tx.status === 'pending')

    if (noTxs && transactionsStatus !== 'loading') return setTransactionsStatus(undefined)
    if (noTxs && transactionsStatus === 'loading') return

    if (allSuccess) return setTransactionsStatus('success')
    if (someChecking) return setTransactionsStatus('checking')
    if (someFailed) return setTransactionsStatus('error')
    if (someSigning) return setTransactionsStatus('signing')
  }, [transactions])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (transactionsStatus === 'signing') handleSignTransactions()
    if (transactionsStatus === 'checking') {
      handleCheckTransactions()
      interval = setInterval(() => {
        handleCheckTransactions()
      }, CHECK_TRANSACTION_INTERVAL)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [transactionsStatus])

  return {
    transactions,
    setTransactions,
    transactionsStatus,
    fetchTakeSwapTransactions,
    fetchMakeSwapTransactions,
    fetchTraitMakeSwapTransactions,
    fetchCancelSwapTransactions,
    fetchCancelSingleBidTransactions,
  }
}

export default useTransactions
