import { Outlet, useLocation } from 'react-router-dom'
import { Box, Link, Text } from '@chakra-ui/react'
import Navbar from '../navbar'
import lines from '../../assets/lines.png'
import LandingFooter from '../../pages/LandingFooter'

const version = process.env.REACT_APP_VERSION

export default function Layout() {
  const location = useLocation()

  interface StyleMap {
    [key: string]: string
  }

  const linesStyles: StyleMap = {
    '/ai-trades/*': 'opacity-5',
    default: 'opacity-5',
  }

  const getLinesStyle = (pathname: string): string => {
    // Check for an exact match first
    if (linesStyles[pathname]) {
      return linesStyles[pathname]
    }

    // Check for a wildcard match
    for (const key in linesStyles) {
      if (key.endsWith('/*') && pathname.startsWith(key.slice(0, -2))) {
        return linesStyles[key]
      }
    }

    // If none of the above, return default
    return linesStyles.default
  }

  return (
    <>
      <div id="top-bar"></div>

      <div
        style={{
          backgroundImage: `url(${lines})`,
          zIndex: -1,
        }}
        className={`fixed w-full h-full bg-no-repeat bg-cover bg-center ${getLinesStyle(
          location.pathname
        )}`}
      />

      <Box id="container">
        <div id="page">
          <Navbar />
          <div className="page-content page-width">
            <Outlet />
          </div>
          <div id="footer">
            {/* <Link href="/updates">v.{version} Alpha</Link>{' '} */}
            {/* <span className="sep">|</span> <Link href="/faq">FAQ</Link> */}
            <LandingFooter />

          </div>
        </div>
        <div id="side-panel"></div>
      </Box>
    </>
  )
}
