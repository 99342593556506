import { ModalBody } from '@chakra-ui/react'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SurplusComponent from '../Surplus'
import SwapDetailsComponent from '../SwapDetails'
import useSolana from '../../../hooks/useSolana'
import ActionButton from '../Buttons/ActionButton'
import CloseButton from '../Buttons/CloseButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import { formatSignificantDigits } from '../../../utils'
import { useUA } from '../../../contexts/userTracking'
import { TraitCollectionData } from '../../../types/collectionSwapV2'

interface SelectNftStepProps {
  onClose: () => void
  nextStep: () => void
}

const SelectNftStep: React.FC<SelectNftStepProps> = function ({ onClose, nextStep }) {
  const { uid, nfts } = useAppContext()
  const { connected, connect } = useSolana()
  const { selectedBid, solToUsd, setSelectedBid, modalMode } = useCollectionSwaps()
  const [tooltipLabel, setTooltipLabel] = useState<string>('Please select an NFT first')
  const [takerNftId, setTakerNftId] = useState<string | null>(null)

  const { addGAEvent } = useUA()

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect])

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid)
  }, [selectedBid, uid])

  useEffect(() => {
    if (!takerNftId) {
      setTooltipLabel('Please select an NFT first')
    } else {
      setTooltipLabel('')
    }
  }, [takerNftId])

  const takerNft = useMemo(() => {
    return nfts?.find((nft) => nft.itemId === takerNftId)
  }, [nfts, takerNftId])

  const handleNextStep = useCallback(() => {
    if (!connected) return connect()

    if (!takerNft || !selectedBid) return

    setSelectedBid({ ...selectedBid, takerNft })
    addGAEvent('accept-modal_select-nft_next-step', { takerNftId: takerNft.itemId })
    return nextStep()
  }, [connected, connect, nextStep, takerNft, selectedBid, setSelectedBid])

  const allowedNfts = useMemo(() => {
    if (swapOverview && nfts) {
      return nfts.filter(
        (nft) => nft.collection.onChainId === selectedBid?.takerCollection.onChainId
      ).filter((nft) => {
        if (swapOverview.traitId) {
          const ids = nft.attributes.map((attribute) => `${attribute.trait_type}_${attribute.value}`.toLowerCase())
          return ids.includes(swapOverview.traitId.toLowerCase())
        }
        return true
      })
    } else {
      return [];
    }
  }, [swapOverview, nfts, selectedBid, modalMode])

  return (
    <ModalBody p={0}>
      <div className="p-6 text-dark-potato">
        <h2 className="pb-4 archivo-black font-2xl">Instant Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            <>
              <h4 className="pb-2">
                <span className='font-bold'>Select NFT to <span className="text-secondary-pinkFloyd">Give</span></span>
                <p className="font-medium text-sm">
                  Please select the item you would like to give.
                </p>
              </h4>
              <div>
                <div
                  className={`${nfts && nfts.length > 0 && 'grid grid-cols-3 gap-3'
                    } max-h-[470px] overflow-y-auto px-2  collections-box pt-3 pb-3 potato-radius`}
                >
                  {allowedNfts.length > 0 ? (
                    allowedNfts.map((nft) => {
                      return (
                        <div
                          key={nft.itemId}
                          className={`relative collection-card potato-radius-sm cursor-pointer    overflow-hidden  ${takerNftId === nft.itemId
                            ? 'border-2 border-strongGreen'
                            : ''
                            }`}
                        >
                          <img
                            src={nft.image}
                            alt="NFT"
                            className="w-full"
                            onClick={() => setTakerNftId(nft.itemId)}
                          />
                          {takerNftId === nft.itemId && (
                            <div className="absolute top-12 left-10">
                              <svg
                                width="42"
                                height="28"
                                viewBox="0 0 42 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.86261 14.8096L14.3866 25.7697"
                                  stroke="#008E20"
                                  strokeWidth="4"
                                  strokeLinecap="round"
                                  strokeLinejoin='round'
                                />
                                <path
                                  d="M39.1374 2.22998L14.3864 25.77"
                                  stroke="#008E20"
                                  strokeWidth="4"
                                  strokeLinecap="round"
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                          )}
                          <h4 className="text-sm p-2 pb-1 archivo-black">
                            {nft.collection.name && nft.collection.name.length > 9
                              ? `${nft.collection.name.slice(0, 9)}...`
                              : nft.collection.name}
                          </h4>
                          <h4 className="text-xs px-2 pb-2">
                            {nft.name.length > 13 ? `${nft.name.slice(0, 13)}...` : nft.name}
                          </h4>
                        </div>
                      )
                    })
                  ) : (
                    <div className="w-full flex items-center justify-center px-3 py-20 text-center">
                      You don't have any {swapOverview?.getItem.collectionName} NFTs available
                      in your wallet.
                    </div>
                  )}
                </div>

                {swapOverview?.userSavings.swapCost &&
                  swapOverview?.userSavings.swapCost > 0 ? (
                  <>
                    <div className="w-full flex flex-col items-center justify-center">
                      <span className='archivo-black'>+</span>
                      <div className="new-transparent-bg text-3xl archivo-black  px-6 w-fit">
                        {formatSignificantDigits(
                          Math.abs(swapOverview?.userSavings.swapCost), 4
                        )}{' '}
                        SOL
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            <div className="flex flex-col justify-center h-full">
              <h4 className="archivo-black pb-2">
                You <span className="text-strongGreen">Get</span>
                {/* {
                          userSavings.neoswapCost < 0 ?
                            <span className='archivo-black'>{`+ ${formatToTwoSignificantDigitsAfterDecimal(Math.abs(userSavings.neoswapCost))} SOL`}</span>
                            : null} */}
              </h4>
              <div className="flex flex-row md:flex-col gap-2 items-start md:items-center justify-center h-full">
                {swapOverview?.userSavings.swapCost &&
                  swapOverview?.userSavings.swapCost < 0 ? (
                  <>
                    <div className="w-36 h-36 potato-radius aspect-square border mobile-swap-bg flex items-center justify-center rounded-md">
                      <span className="archivo-black text-xl text-center">
                        {formatSignificantDigits(
                          Math.abs(swapOverview?.userSavings.swapCost), 2
                        )}{' '}
                        SOL
                      </span>
                    </div>
                    <div className="pt-16 md:pt-0">+</div>
                  </>
                ) : null}

                <div className="w-36">
                  <img
                    src={swapOverview?.getItem.image}
                    alt="Placeholder"
                    className="potato-radius cursor-pointer hover:opacity-80"
                    onClick={() => {
                      window.open(`https://solscan.io/token/${''}`, '_blank')
                    }}
                  />
                  <h5 className="archivo-black pt-2">{swapOverview?.getItem.collectionName}</h5>
                  <h6>{swapOverview?.getItem.collectionName}</h6>
                  {/* {userSavings.neoswapCost < 0 ? <span className='text-center w-full block pt-2'>{`+ ${formatToTwoSignificantDigitsAfterDecimal(Math.abs(userSavings.neoswapCost))} SOL`}</span> : null} */}
                </div>
              </div>

              {swapOverview?.userSavings && (
                <SurplusComponent
                  userSavings={swapOverview?.userSavings}
                  chainUsdValue={solToUsd}
                  calculatedDate={''}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full justify-between flex gap-2 px-6 pb-6">
        <CloseButton onClick={onClose} />
        <ActionButton
          onClick={handleNextStep}
          text="Accept"
          tooltipDisabledText={tooltipLabel}
          isDisabled={!takerNftId}
        />
      </div>
    </ModalBody>
  )
}

export default SelectNftStep
