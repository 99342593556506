import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import { confetti } from '../../../services/confetti'
import useSolana from '../../../hooks/useSolana'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import NextStep from './NextStep'
import OfferStep from './OfferStep'
import SignStep from './SignStep'

interface CollectionSwapMySwapModalProps {
  isOpen: boolean
  onClose: () => void
}

const CollectionSwapMySwapModal: React.FC<CollectionSwapMySwapModalProps> = function ({
  isOpen,
  onClose,
}) {
  const { setModalMode, selectedBid } = useCollectionSwaps()
  const [step, setStep] = useState(0)

  const { connected, connect } =
    useSolana()

  const { uid } = useAppContext()

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect])

  useEffect(() => {
    if (selectedBid && 'traitId' in selectedBid.takerCollection) {
      setModalMode('trait')
    } else {
      setModalMode('collection')
    }
  }, [selectedBid, setModalMode])

  const handleCloseAndReset = () => {
    setStep(0)
    onClose()
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <OfferStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              setStep(1)
            }}
          />
        )
      case 1:
        return (
          <SignStep
            onClose={handleCloseAndReset}
            previousStep={() => {
              setStep(0)
            }}
            nextStep={() => { }}
          />
        )
      default:
        return (
          <NextStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              return
            }}
          />
        )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false} trapFocus={false}>
      <ModalOverlay />
      {/* <ModalBody p={0}>
          <div className="flex flex-col items-center justify-center mb-4">
            <div className="flex flex-col items-center">
              <Text className="pt-4">My Swap</Text>
            </div>
          </div>
          <div className="w-full flex gap-2 px-6 pb-6">
            <CloseButton onClick={onClose} />
          </div>
        </ModalBody> */}
      <ModalContent className="potato-modal-bg potato-radius">
        <ModalCloseButton />
        {renderStep()}
      </ModalContent>
    </Modal>
  )
}

export default CollectionSwapMySwapModal
