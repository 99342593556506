import {
  Box,
  Text,
  Link,
  Flex,
  Image,
} from '@chakra-ui/react'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import useSolana from '../../../hooks/useSolana'
import { useAppContext } from '../../../contexts/appContext'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { GiveCollectionModal, GetCollectionModal } from '../PickerModal/CollectionModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import SwapTable from '../SwapTable'
import SwapModal from '../SwapModal'
import { CollectionData, FilterConfigV2 } from '../../../types/collectionSwapV2'
import CustomTooltip from '../CustomTooltip'
import { useUA } from '../../../contexts/userTracking'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { getPotateosForBids } from '../../../utils'
import { useNavigate } from 'react-router-dom'

const SHOW_POTATEOS = true

const DEFAULT_GIVE_COLLECTIONS = ['Mad Lads']
const DEFAULT_GET_COLLECTIONS = ['Mad Lads', 'DeGods', 'Claynosaurz', 'SMB Gen2']

const Picker: React.FC = function ({ }) {
  const navigate = useNavigate()
  const {
    calculatedPointsConfiguration,
    setGiveNfts,
    getOnChainOffers,
    getOffChainOffers,
    collectionsData,
    handleCreateSwaps,
  } = useCollectionSwaps()


  const handleRemoveGetCollection = (collection: CollectionData) => {
    const updatedCollections = getCollectionNames.filter((c) => c !== collection.collectionName)

    setGetCollectionNames(updatedCollections)
  }

  const handleRemoveGiveCollection = (collection: CollectionData) => {
    const updatedCollections = giveCollectionNames.filter((c) => c !== collection.collectionName)

    setGiveCollectionNames(updatedCollections)
  }


  const { signIn, connected, connect } = useSolana()
  const { uid, signedIn, publicKey } = useAppContext()
  const { addGAEvent } = useUA()

  const [getCollectionNames, setGetCollectionNames] = useState<string[]>(DEFAULT_GET_COLLECTIONS)
  const [giveCollectionNames, setGiveCollectionNames] = useState<string[]>(DEFAULT_GIVE_COLLECTIONS)

  const giveCollections = useMemo(() => {
    return collectionsData.filter((collection) => giveCollectionNames.includes(collection.collectionName))
  }, [collectionsData, giveCollectionNames])

  const getCollections = useMemo(() => {
    return collectionsData.filter((collection) => getCollectionNames.includes(collection.collectionName))
  }, [collectionsData, getCollectionNames])

  const [giveModalOpen, setGiveModalOpen] = useState(false)
  const [getModalOpen, setGetModalOpen] = useState(false)

  const createLink = useMemo(() => {
    const makeSlugs = (names: string[]) =>
      names.map((name) => name.toLowerCase().replace(' ', '_')).join(',')

    const giveParam = makeSlugs(giveCollectionNames)
    const getParam = makeSlugs(getCollectionNames)

    const params = new URLSearchParams()
    if (giveParam) params.append('give', giveParam)
    if (getParam) params.append('get', getParam)

    return `/explore${params.toString() ? `?${params.toString()}` : ''}`
  }, [giveCollectionNames, getCollectionNames])

  const onChainFilterConfig = useMemo(() => {
    return {
      makerCollectionNames: getCollectionNames,
      takerCollectionNames: giveCollectionNames,
      bestFor: 'taker',
      makerExclude: [publicKey || ''],
    } as FilterConfigV2
  }, [getCollectionNames, giveCollectionNames, publicKey])

  const offChainFilterConfig = useMemo(() => {
    return {
      makerCollectionNames: giveCollectionNames,
      takerCollectionNames: getCollectionNames,
      // bestFor: 'maker', // no need to filter best offers for offchain swaps
    } as FilterConfigV2
  }, [getCollectionNames, giveCollectionNames])

  const offers = useMemo(() => {
    let onChainOffers = getOnChainOffers(onChainFilterConfig)
    let offChainOffers = getOffChainOffers(offChainFilterConfig)

    return [...onChainOffers, ...offChainOffers]
  }, [getOnChainOffers, getOffChainOffers, onChainFilterConfig, offChainFilterConfig])

  const handleCreateSwapsButton = useCallback(() => {
    addGAEvent(`picker_create-swaps_click`)
    handleCreateSwaps(offers, 'collection')
  }, [offers])

  useEffect(() => {
    if (!connected && uid) {
      connect()
    }
  }, [connected, uid, connect])

  const canCreateSwap = useMemo(() => {
    return connected && uid && giveCollectionNames.length > 0 && getCollectionNames.length > 0
  }, [connected, uid, giveCollectionNames, getCollectionNames])

  useEffect(() => {
    return () => {
      setGiveNfts([])
    }
  }, [])

  const handleGiveCollectionClick = () => {
    addGAEvent('picker_give-collection_click')
    setGiveModalOpen(true)
  }

  const handleGetCollectionClick = () => {
    if (giveCollectionNames.length === 0) return
    addGAEvent('picker_get-collection_click')
    setGetModalOpen(true)
  }

  const renderCollectionContent = () => (
    <>
      <Flex w="100%" alignItems="center" className="swap-ui-placeholder potato-radius px-2 py-8">
        {giveCollections.length ? (
          <Box position="relative" display="inline-block">
            <Image
              key={'giveCollection'}
              src={giveCollections[0]?.imageUrl}
              alt={giveCollections[0]?.collectionName}
              className="potato-radius-sm new-bg-transparent"
              boxSize="40px"
              mr={2}
              onClick={handleGiveCollectionClick}
            />
            <SmallCloseIcon
              color="white"
              position="absolute"
              background="#FC822F"
              rounded="full"
              top="0"
              right="2"
              boxSize="9px"
              cursor="pointer"
              _hover={{ color: 'gray.500' }}
              onClick={() => handleRemoveGiveCollection(giveCollections[0])}
            />
          </Box>
        ) : (
          <Box
            className="potato-radius-sm new-bg-transparent"
            boxSize="40px"
            onClick={handleGiveCollectionClick}
          />
        )}
        <button
          className="px-4 py-1 potato-btn-radius primary-potato-btn text-white inter-bold swap-btn"
          onClick={handleGiveCollectionClick}
        >
          Give
          <ChevronDownIcon />
        </button>
      </Flex>

      <Flex
        mt={2}
        w="100%"
        alignItems="center"
        className="swap-ui-placeholder potato-radius px-2 py-8"
      >
        {getCollections.length ? (
          <Flex flexWrap="wrap" alignItems="center">
            {getCollections.map((collection, index) => (
              <Fragment key={index}>
                {index > 0 && (
                  <Text fontSize="sm" mr="1">
                    {' '}
                    OR{' '}
                  </Text>
                )}
                {index > 0 && index % 4 === 0 && <Flex w="100%" h="4" />}
                <Box position="relative" display="inline-block">
                  <Image
                    src={collection.imageUrl}
                    alt={collection.collectionName}
                    boxSize="40px"
                    borderRadius="md"
                    mr={2}
                    onClick={handleGetCollectionClick}
                  />
                  <SmallCloseIcon
                    color="white"
                    position="absolute"
                    background="#FC822F"
                    rounded="full"
                    top="0"
                    right="2"
                    boxSize="9px"
                    cursor="pointer"
                    _hover={{ color: 'gray.500' }}
                    onClick={() => handleRemoveGetCollection(collection)}
                  />
                </Box>
              </Fragment>
            ))}
          </Flex>
        ) : (
          <Box
            className="potato-radius-sm new-bg-transparent"
            boxSize="40px"
            onClick={handleGetCollectionClick}
          />
        )}
        <CustomTooltip
          label="Please select a collection to give first"
          isDisabled={giveCollections.length > 0}
        >
          <button
            className="px-4 py-1 potato-btn-radius primary-potato-btn text-white inter-bold swap-btn"
            onClick={handleGetCollectionClick}
            disabled={giveCollections.length === 0}
          >
            Get
            <ChevronDownIcon />
          </button>
        </CustomTooltip>
      </Flex>
    </>
  )

  const [isLoading, setIsLoading] = useState(true)
  const filteredCollections = useMemo(() => {
    const filtered = collectionsData
      .filter((collection) => collection.enabledTraitBids)
      .sort((a, b) => {
        if (a.orderImportance === undefined && b.orderImportance === undefined) return 0;
        if (a.orderImportance === undefined) return 1;
        if (b.orderImportance === undefined) return -1;
        return a.orderImportance - b.orderImportance;
      });

    setIsLoading(filtered.length === 0);
    return filtered;
  }, [collectionsData]);

  const renderCollectionTabs = () => (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      overflowX="auto"
      py={2}
      borderTopRadius="xl"
      mb="-2px"  // Overlap with the main container
    >
      <Box
        as="button"
        px={4}
        py={2}
        borderRadius="full"
        className='swap-ui'
        mr={2}
        fontSize="sm"
        fontWeight="bold"
      >
        Swap
      </Box>
      {isLoading ? (
        // Skeleton loader for up to 5 circles
        <>
          {[...Array(5)].map((_, index) => (
            <div key={index} className="w-9 h-9 rounded-full bg-gray-200 animate-pulse mr-2"></div>
          ))}
        </>
      ) : (
        filteredCollections.map((collection) => (
          <Box
            key={collection.slug}
            as="button"
            onClick={() => navigate(`/collection/${collection.slug}`)}
            position="relative"
            mr={2}
          >
            <Image
              src={collection.imageUrl}
              alt={collection.collectionName}
              boxSize="36px"
              borderRadius="full"
              border="2px solid transparent"
              _hover={{ border: "2px solid orange" }}
            />
          </Box>
        ))
      )}
    </Flex>
  )

  return (
    <Box maxW="sm" mx="auto">
      {renderCollectionTabs()}
      <Box
        className="swap-ui"
        bg="white"
        borderRadius="xl"
        p={4}
        boxShadow="md"
      >
        {renderCollectionContent()}

        <Box textAlign="center" mt={2}>
          {!connected || !uid || !signedIn ? (
            <button
              className="w-full px-8 py-2 potato-btn-radius primary-potato-btn text-center archivo-black text-white"
              onClick={signIn}
            >
              Connect Wallet
            </button>
          ) : (
            <button
              className="w-full px-8 py-2 potato-btn-radius primary-potato-btn text-center archivo-black text-white"
              onClick={() => handleCreateSwapsButton()}
              disabled={!canCreateSwap}
            >
              Create Swaps{' '}
              {SHOW_POTATEOS && getCollections.length
                ? `(+ ${calculatedPointsConfiguration.pointsPerMakerAcceptedSwap +
                getPotateosForBids(
                  getCollections.length,
                  calculatedPointsConfiguration.pointsPerActiveBid
                )
                } 🥔)`
                : ''}
            </button>
          )}

          {offers.length > 0 && (
            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-full">
                <Text fontSize="sm" className="archivo-black text-left" mt={5}>
                  Best Swaps🔝
                </Text>
              </div>
              <SwapTable
                offers={offers}
                sortConfig={{ sortBy: 'savings', sortDirection: 'desc' }}
                showCreateSwap={false}
                showMobileCard={true}
                showPotaeos={true}
                mode={'picker'}
              />
              <SwapModal />
            </div>
          )}

          {offers.length > 0 && (
            <Text fontSize="sm" mt={2} color="#FC822F" textDecoration="underline">
              <Link href={createLink} target='_blank' onClick={() => addGAEvent('picker_view-more_click')}>
                + View more
              </Link>
            </Text>
          )}

          <Text fontSize="sm" mt={2}>
            Earn 🥔 Potatoes!
          </Text>
        </Box>

        <GiveCollectionModal
          isOpen={giveModalOpen}
          onClose={() => setGiveModalOpen(false)}
          collectionNames={giveCollectionNames}
          setCollectionNames={setGiveCollectionNames}
          setGetCollectionNames={setGetCollectionNames}
        />
        <GetCollectionModal
          isOpen={getModalOpen}
          onClose={() => setGetModalOpen(false)}
          giveCollectionNames={giveCollectionNames}
          collectionNames={getCollectionNames}
          setCollectionNames={setGetCollectionNames}
        />
      </Box>
    </Box>
  )
}

export default Picker