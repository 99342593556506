import React from 'react'
import { Tooltip } from '@chakra-ui/react'

interface CloseButtonProps {
  onClick: () => void
  text?: string
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, text }) => (
  <button
    className="w-full p-2 potato-radius default-potato-btn archivo-black"
    onClick={onClick}
  >
    {text ? text : "Close"}
  </button>
)

export default CloseButton
