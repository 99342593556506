import { useCallback, useMemo } from 'react'
import {
  SwapData,
  FilterConfigV2,
  TraitFilterConfigV2,
  CollectionData,
} from '../types/collectionSwapV2'
import { calculateOffer, calculateOffers, updateFees } from '../utils/collectionSwapCalculator'
import {
  filterOffersV2,
  filterCollectionsV2,
  filterOffersByTraitsV2,
  filterTraitCollectionsV2,
} from '../utils/filterV2'
import useCollections, { UseCollectionsType } from './useCollections'
import { useAppContext } from '../contexts/appContext'
import useOnChainSwaps, { UseOnChainSwapsType } from './useOnChainSwaps'
import { Nft } from '../types/nft'

/**
 * Interface extending UseCollectionsType and UseOnChainSwapsType with additional methods for handling swaps
 */
export interface UseSwapsType extends UseCollectionsType, UseOnChainSwapsType {
  getOnChainOffers: (filterConfig?: FilterConfigV2) => SwapData[]
  getOffChainOffers: (filterConfig: FilterConfigV2) => SwapData[]
  getTraitOnChainOffers: (filterConfig?: TraitFilterConfigV2) => SwapData[]
  getTraitCalculatedOffers: (filterConfig: TraitFilterConfigV2) => SwapData[]
  getTraitOfferForNfts: (makerNft: Nft, takerNft: Nft) => SwapData | null
}

/**
 * Custom hook for managing swaps, including on-chain and off-chain offers
 * @returns {UseSwapsType} Object containing methods and data for handling swaps
 */
export default function useSwaps(): UseSwapsType {
  const collections = useCollections()
  const { collectionsData, traitCollectionsData, findTraitCollectionWBuySellForNfts } = collections

  const onChainSwaps = useOnChainSwaps({
    collectionsData,
    traitCollectionsData,
    findTraitCollectionWBuySellForNfts,
  })
  const { onChainOffers, onChainTraitOffers } = onChainSwaps

  const { pointsMultipliers, uid, publicKey } = useAppContext()
  const waveFees = useMemo(() => {
    return pointsMultipliers && pointsMultipliers.length > 0 ? true : false
  }, [pointsMultipliers])

  /**
   * Retrieves and filters on-chain offers based on the provided filter configuration
   * @param {FilterConfigV2} filterConfig - Configuration for filtering offers
   * @returns {SwapData[]} Filtered array of on-chain offers
   */
  const getOnChainOffers = useCallback(
    (filterConfig?: FilterConfigV2): SwapData[] => {
      let offers = filterOffersV2(onChainOffers, filterConfig)
      if (waveFees) {
        offers = offers.map((offer) => {
          if (offer.onChainSwap?.data.maker == publicKey) {
            offer = updateFees({ swapData: offer, makerFeeRate: 0 })
          } else {
            offer = updateFees({ swapData: offer, takerFeeRate: 0 })
          }
          return offer
        })
      }
      return offers
    },
    [onChainOffers, waveFees, publicKey]
  )

  /**
   * Calculates and filters off-chain offers based on the provided filter configuration
   * @param {FilterConfigV2} filterConfig - Configuration for filtering offers
   * @returns {SwapData[]} Filtered array of off-chain offers
   */
  const getOffChainOffers = useCallback(
    (filterConfig: FilterConfigV2): SwapData[] => {
      let offers = calculateOffers({
        giveCollections: filterCollectionsV2(
          collectionsData,
          filterConfig.makerCollectionNames,
          filterConfig.makerCollectionIds
        ),
        getCollections: filterCollectionsV2(
          collectionsData,
          filterConfig.takerCollectionNames,
          filterConfig.takerCollectionIds
        ),
        waveMakerFees: waveFees,
      })

      offers = filterOffersV2(offers, filterConfig)
      return offers
    },
    [collectionsData, waveFees]
  )

  /**
   * Calculates offers based on trait configurations
   * @param {TraitFilterConfigV2} filterConfig - Configuration for filtering trait-based offers
   * @returns {SwapData[]} Array of calculated offers based on traits
   */
  const getTraitCalculatedOffers = useCallback(
    (filterConfig: TraitFilterConfigV2): SwapData[] => {
      let giveCollections: CollectionData[] = []
      if (filterConfig.makerNfts) {
        giveCollections = findTraitCollectionWBuySellForNfts(filterConfig.makerNfts)
      } else {
        giveCollections = filterTraitCollectionsV2(
          traitCollectionsData,
          filterConfig.makerCollectionNames,
          filterConfig.makerCollectionIds,
          filterConfig.makerTraitIds
        )
      }

      let getCollections = filterTraitCollectionsV2(
        traitCollectionsData,
        filterConfig.takerCollectionNames,
        filterConfig.takerCollectionIds,
        filterConfig.takerTraitIds
      )

      let calculatedOffers = calculateOffers({
        giveCollections: giveCollections,
        getCollections: getCollections,
        waveMakerFees: waveFees,
      })
      return calculatedOffers
    },
    [waveFees, traitCollectionsData]
  )

  /**
   * Retrieves and filters on-chain offers based on trait configurations
   * @param {TraitFilterConfigV2} filterConfig - Configuration for filtering trait-based offers
   * @returns {SwapData[]} Filtered array of on-chain offers based on traits
   */
  const getTraitOnChainOffers = useCallback(
    (filterConfig?: TraitFilterConfigV2) => {
      let offers = filterOffersByTraitsV2(onChainTraitOffers, filterConfig)
      if (waveFees) {
        offers = offers.map((offer) => {
          if (offer.onChainSwap?.data.maker == publicKey) {
            offer = updateFees({ swapData: offer, makerFeeRate: 0 })
          } else {
            offer = updateFees({ swapData: offer, takerFeeRate: 0 })
          }
          return offer
        })
      }
      return offers
    },
    [onChainTraitOffers, waveFees, publicKey]
  )

  const getTraitOfferForNfts = useCallback(
    (makerNft: Nft, takerNft: Nft) => {
      try {
        const makerCollection = findTraitCollectionWBuySellForNfts([makerNft])[0]
        const takerCollection = findTraitCollectionWBuySellForNfts([takerNft])[0]
        const offer = calculateOffer({
          makerCollection,
          takerCollection,
          waveMakerFees: waveFees,
        })
        return offer
      } catch (error) {
        console.error(error)
        return null
      }
    },
    [waveFees]
  )

  return {
    ...collections,
    ...onChainSwaps,
    getOnChainOffers,
    getOffChainOffers,
    getTraitOnChainOffers,
    getTraitCalculatedOffers,
    getTraitOfferForNfts,
  }
}
