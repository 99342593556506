import React, { useEffect } from 'react'
import CustomTooltip from './CustomTooltip'
import ProposeSwapButton from './ProposeSwapButton'
import { useMediaQuery } from 'react-responsive'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext'

const ProposeSwapStickyButton: React.FC = () => {
  const { calculatedPointsConfiguration } = useCollectionSwaps()
  const [scroll, setScroll] = React.useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50)
    })
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (

    <div
      className={`fixed transition-bottom duration-500 ${scroll || isMobile ? 'bottom-10' : '-bottom-[1000px]'
        } left-0 w-screen flex items-center justify-center`}
      style={{ zIndex: 99999 }}
    >
      <div className="background-white potato-radius p-2 flex items-center">
        <div className="px-6 flex items-center gap-2">
          <span className='text-dark-potato'>{`Earn ${calculatedPointsConfiguration.pointsPerMakerAcceptedSwap}🥔 Potatoes!`}</span>
          <CustomTooltip label={`When your swap is accepted, you will earn ${calculatedPointsConfiguration.pointsPerMakerAcceptedSwap} potatoes!`}>
            <div tabIndex={0} style={{ cursor: 'pointer', display: 'inline-block' }}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.6665 12.6666H9.33317V7.66663H7.6665V12.6666ZM8.49984 5.99996C8.73595 5.99996 8.93386 5.9201 9.09359 5.76038C9.25331 5.60065 9.33317 5.40274 9.33317 5.16663C9.33317 4.93051 9.25331 4.7326 9.09359 4.57288C8.93386 4.41315 8.73595 4.33329 8.49984 4.33329C8.26373 4.33329 8.06581 4.41315 7.90609 4.57288C7.74636 4.7326 7.6665 4.93051 7.6665 5.16663C7.6665 5.40274 7.74636 5.60065 7.90609 5.76038C8.06581 5.9201 8.26373 5.99996 8.49984 5.99996ZM8.49984 16.8333C7.34706 16.8333 6.26373 16.6145 5.24984 16.177C4.23595 15.7395 3.354 15.1458 2.604 14.3958C1.854 13.6458 1.26025 12.7638 0.822754 11.75C0.385254 10.7361 0.166504 9.65274 0.166504 8.49996C0.166504 7.34718 0.385254 6.26385 0.822754 5.24996C1.26025 4.23607 1.854 3.35413 2.604 2.60413C3.354 1.85413 4.23595 1.26038 5.24984 0.822876C6.26373 0.385376 7.34706 0.166626 8.49984 0.166626C9.65261 0.166626 10.7359 0.385376 11.7498 0.822876C12.7637 1.26038 13.6457 1.85413 14.3957 2.60413C15.1457 3.35413 15.7394 4.23607 16.1769 5.24996C16.6144 6.26385 16.8332 7.34718 16.8332 8.49996C16.8332 9.65274 16.6144 10.7361 16.1769 11.75C15.7394 12.7638 15.1457 13.6458 14.3957 14.3958C13.6457 15.1458 12.7637 15.7395 11.7498 16.177C10.7359 16.6145 9.65261 16.8333 8.49984 16.8333Z"
                  fill="#828282"
                />
              </svg>
            </div>
          </CustomTooltip>
        </div>
        <div>
          <ProposeSwapButton mode={"floating"} />
        </div>
      </div>
    </div>
  )
}

export default ProposeSwapStickyButton
