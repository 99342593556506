import { useEffect, useMemo, useState } from 'react'
import { Connection, PublicKey, Transaction, SystemProgram } from '@solana/web3.js'
import { useAppContext } from '../contexts/appContext'

import { useWallet } from '@solana/wallet-adapter-react'
import { useUA } from '../contexts/userTracking'

import { useWalletModal } from '@solana/wallet-adapter-react-ui'

// Constants for error messages
const NO_WALLET_ERR = 'noWalletErr'
const CONNECT_WALLET_ERR = 'connectWalletErr'
const NO_ADDRESS_ERR = 'noAddressErr'
const PRIMARY_WALLET_ERR = 'primaryWalletErr'
const LEDGER_SIGN_MESSAGE_ERR = 'WalletSignMessageError'

const chainName: string = 'solana'

/**
 * Custom hook for Solana wallet authentication
 * @returns {Object} An object containing Solana wallet authentication methods and state
 */
export default function useSolanaAuth() {
  // State variables
  const [autoConnect, setautoConnect] = useState(false)
  const [refireSignin, setRefireSignIn] = useState(false)
  const [ledgerSignMessageError, setLedgerSignMessageError] = useState(false)

  // Context and hooks
  const {
    getEncodedNonce,
    getToken,
    getTokenFromSignedTransaction,
    signInWithToken,
    signOut,
    signingIn,
    updateState,
    signedIn,
  } = useAppContext()
  const { addGAEvent } = useUA()
  const solanaWallet = useWallet()
  const solanaWalletModal = useWalletModal()

  const network = process.env.REACT_APP_SOLANA_NETWORK!

  // Memoized wallet address
  const address = useMemo(() => {
    return solanaWallet?.publicKey?.toBase58()
  }, [solanaWallet.publicKey])

  const connected = solanaWallet.connected

  // Effect to handle re-firing sign-in
  useEffect(() => {
    if (!refireSignin) return
    if (!solanaWallet.wallet) {
      return
    }
    signIn()
    setRefireSignIn(false)
  }, [refireSignin, solanaWallet.wallet])

  /**
   * Handles the sign-in process
   */
  const signIn = async () => {
    try {
      updateState({ signingIn: true })
      console.log('signing in', connected, address, solanaWallet.wallet)
      addGAEvent('any_wallet_initiate_sign_in', { wallet: solanaWallet?.wallet?.adapter?.name })

      if (!connected && !solanaWallet.wallet) {
        throw new Error(NO_WALLET_ERR)
      }

      if (!connected || !address) {
        throw new Error(CONNECT_WALLET_ERR)
      }

      let token = ''
      if (ledgerSignMessageError) {
        // Handle Ledger sign message error by creating a transaction
        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: new PublicKey(address),
            toPubkey: new PublicKey(address),
            lamports: 0,
          })
        )

        const connection = new Connection(network!, 'confirmed')
        const { blockhash } = await connection.getLatestBlockhash()
        transaction.recentBlockhash = blockhash
        transaction.feePayer = new PublicKey(address)

        if (!solanaWallet.signTransaction)
          throw new Error("Error Ledger doesn't have signTransaction")

        const signedTransaction = await solanaWallet.signTransaction(transaction)
        const serializedTransaction = signedTransaction.serialize()
        const transactionBase64 = Buffer.from(serializedTransaction).toString('base64')
        token = await getTokenFromSignedTransaction(transactionBase64, 'solana')
      } else {
        // Normal sign-in process
        const message = (await getEncodedNonce(
          address,
          chainName,
          solanaWallet.wallet?.adapter.name
        )) as string
        const encoded = new TextEncoder().encode(message)

        if (!solanaWallet.signMessage) throw new Error(NO_WALLET_ERR)

        const resp = await solanaWallet.signMessage(encoded)
        const signature = Buffer.from(resp).toString('base64')
        token = await getToken(address, chainName, signature)
      }
      if (!token) throw new Error('No token')

      await signInWithToken(token)

      addGAEvent('any_wallet_sign_in', { wallet: solanaWallet?.wallet?.adapter?.name })
      updateState({ signingIn: false })
    } catch (e: any) {
      // Error handling
      const refireErrors = [CONNECT_WALLET_ERR, NO_WALLET_ERR, PRIMARY_WALLET_ERR, NO_ADDRESS_ERR]
      let refire = refireErrors.includes(e.message)

      if (e.message === NO_WALLET_ERR) {
        addGAEvent('any_wallet_select-wallet')
        solanaWalletModal.setVisible(true)
      }

      if (e.message === CONNECT_WALLET_ERR) {
        await solanaWallet.connect()
        addGAEvent('any_wallet_connect', { wallet: solanaWallet?.wallet?.adapter?.name })
      }

      if (
        e.name === 'WalletSignMessageError' &&
        (e.message.includes('unsupportedOperation') || e.message.includes('rejected'))
      ) {
        setLedgerSignMessageError(true)
        refire = true
      }

      if (refire) {
        setRefireSignIn(true)
      } else {
        updateState({ signingIn: false })
        console.error('Error signing in:', e)
      }
    }
  }

  // Effect to handle auto-connect
  useEffect(() => {
    if (autoConnect && solanaWallet.wallet) {
      handleConnect()
    }
  }, [solanaWallet.wallet, autoConnect])

  /**
   * Handles connecting to the wallet
   */
  const handleConnect = async () => {
    try {
      if (!connected && !solanaWallet.wallet) {
        solanaWalletModal.setVisible(true)
        throw new Error(NO_WALLET_ERR)
      }
      await solanaWallet.connect()
    } catch (e: any) {
      if (e.message === NO_WALLET_ERR) {
        setautoConnect(true)
      }
    }
  }

  /**
   * Handles signing out
   */
  const handleSignout = async () => {
    await signOut()
  }

  // Return object with authentication methods and state
  return {
    address: address,
    publicKey: solanaWallet.publicKey,
    connect: handleConnect,
    signIn,
    signOut: handleSignout,
    connected,
    connecting: signingIn,
    wallet: solanaWallet.wallet,
    signTransaction: solanaWallet.signTransaction,
    signAllTransactions: solanaWallet.signAllTransactions,
    network,
  }
}
