import { BsTwitter, BsDiscord, BsMedium } from "react-icons/bs";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function LandingFooter() {
  return <div className="w-full new-bg-transparent pt-6 pb-6">
    <div className="homefooter">
      <div className="copyright text-white">
        <h1>
          <span>
            <span>Copyright &copy; </span> {new Date().getFullYear()}{" "}
            Potato Cat
          </span>
          <span id="line">|</span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span id="line">|</span>
          <Link to="/terms-of-service">Terms of Service</Link>
          <span id="line">|</span>
          <Link to="/faq">Frequently Asked Questions</Link>
        </h1>
      </div>

      <div className=" flex justify-end gap-3 footerlist">
        <button className="text-white">
          {" "}
          <a
            href="https://twitter.com/potatocat_ai"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <BsTwitter />
          </a>
        </button>
        {/* <button className="text-white">

          <a
            href="https://discord.gg/4mfqbBkGRS"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <BsDiscord />{" "}
          </a>{" "}
        </button> */}

      </div>


    </div>

    <div className="w-full ">


      <div className="footer-tulle-logo">
        <a href="https://tulle.ai" target="_blank" rel="noreferrer">
          <img
            src={require('../assets/tulle-flat-white.png')}
            alt="Tulle AI Logo"
          />
        </a>
      </div>
    </div>
  </div>
}
