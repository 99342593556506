import React, { useState } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons'
import { ChevronUpIcon } from '@chakra-ui/icons'
import { useUA } from '../contexts/userTracking';


// Define the props interface for FAQItem
interface FAQItemProps {
    id: string;
    question: string;
    answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ id, question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { addGAEvent } = useUA();

    return (
        <li>
            <a
                onClick={() => {
                    setIsOpen(!isOpen)
                    addGAEvent('faq_question_toggle', question)
                }}
                className={isOpen ? '' : 'collapsed'}
                data-bs-toggle="collapsefq"
                aria-expanded={isOpen}
                aria-controls={id}
            >
                {question}
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}


            </a>
            <div id={id} className={`collapsefq ${isOpen ? 'show' : ''}`} data-bs-parent=".faq-list">
                <p>{answer}</p>
            </div>
        </li>
    );
};

const FAQList: React.FC = () => {
    const faqs = [
        {
            id: 'faq1',
            question: 'What is Potato Cat?',
            answer: 'Potato Cat is a revolutionary NFT swap discovery platform on Solana, powered by Tulle. It allows users to easily find and execute NFT swaps, repositioning their portfolios with minimal SOL expenditure.'
        },
        {
            id: 'faq2',
            question: 'How is Potato Cat different from other NFT trading platforms?',
            answer: 'Potato Cat uniquely positions itself as a "marketplace of swaps," focusing on direct NFT-to-NFT exchanges. Our platform makes it easy to find swap opportunities, automatically determines fair deals, and calculates potential savings.'
        },
        {
            id: 'faq3',
            question: 'How does Potato Cat work?',
            answer: 'Potato Cat creates a marketplace of swaps by matching users with complementary interests. Our proposal engine automatically generates fair deals and calculates potential savings compared to traditional buying and selling.'
        },
        {
            id: 'faq4',
            question: 'How does Potato Cat save me money?',
            answer: 'By facilitating direct swaps, Potato Cat helps users avoid crossing the bid/ask spread twice, which typically occurs in traditional marketplace transactions. This results in significant savings for both parties involved in a swap.'
        },
        {
            id: 'faq5',
            question: 'Does Potato Cat charge fees?',
            answer: 'Potato Cat\'s fee is a percentage of the savings you make by using our platform instead of traditional buying and selling. During the early access period, there are no fees.'
        },
        {
            id: 'faq6',
            question: 'How does Potato Cat handle NFT traits?',
            answer: 'Currently, Potato Cat treats all NFTs from the same collection as equal. We plan to incorporate trait-based distinctions in future updates.'
        },
        {
            id: 'faq7',
            question: 'Can I propose multiple swaps at once?',
            answer: 'Yes! Potato Cat allows you to propose multiple swaps using the same NFT and SOL, similar to making offers on multiple collections simultaneously.'
        },
        {
            id: 'faq8',
            question: 'Does Potato Cat pay royalties to NFT creators?',
            answer: 'Yes, we automatically pay royalties on the SOL that changes hands during a swap.'
        },
        {
            id: 'faq9',
            question: 'What are Potatoes?',
            answer: 'Potatoes are rewards earned by early adopters of the Potato Cat platform. They come with perks such as a 25% boost during the early access period.'
        },
        {
            id: 'faq10',
            question: 'How can I get early access to Potato Cat?',
            answer: 'You can sign up for early access at potatocat.ai. Early adopters receive exclusive benefits, including earning Potatoes and zero fees during the early access period.'
        },
        {
            id: 'faq11',
            question: 'Which projects are supporting Potato Cat?',
            answer: 'We\'re proud to have support from major projects including Mad Lads, Backpack, MonkeDAO, Mutant Labs, and Chads, with more partnerships in the works.'
        },
        {
            id: 'faq12',
            question: 'I\'m a project owner. How can my project partner with Potato Cat?',
            answer: 'We\'re actively seeking more partners for our launch. Please DM us on Twitter (@potatocat_ai) to discuss partnership opportunities.'
        },
        {
            id: 'faq13',
            question: 'When is the official launch date?',
            answer: 'Potato Cat\'s official launch date is July 24th, 2024. Sign up for early access now to be ready for the launch and to gain exclusive early adopter benefits.'
        }
    ];

    return (
        <Box className='faq mb-8'>
            <Center mt={"40px"} className="section-title archivo-black " >
                <h2>
                    FAQs
                </h2>
            </Center>
            <div className="faq-list" >

                <ul>
                    {faqs.map((faq) => (
                        <FAQItem key={faq.id} id={faq.id} question={faq.question} answer={faq.answer} />
                    ))}
                </ul>
            </div>

        </Box>

    );
};

export default FAQList;