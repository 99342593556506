import { Helmet } from "react-helmet-async";
import { Heading } from "@chakra-ui/react";
import ProfileForm from "../components/ProfileForm";

export default function Profile() {
    return (
        <>
            <Helmet>
                <title>Potato Cat -  Edit Profile</title>
            </Helmet>
            <Heading className=" archivo-black text-center" as="h2" size="lg">
                Edit Profile
            </Heading>
            <ProfileForm />
        </>
    );
}