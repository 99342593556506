

import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useAppContext } from '../contexts/appContext'
import Firebase from "../services/firebase";
import { Avatar, Box, Flex, useToast } from '@chakra-ui/react'
import { useUA } from '../contexts/userTracking'

import SwapTable from '../components/CollectionSwap/SwapTable'
import ProposeSwapStickyButton from '../components/CollectionSwap/ProposeSwapStickyButton'
import SwapModal from '../components/CollectionSwap/SwapModal'
import ShortenedAddress from '../components/ShortenedAddress'
import { httpsCallable } from "firebase/functions";
import CustomTooltip from "../components/CollectionSwap/CustomTooltip";
import { getLeaderboardPoints, getPointsMultiplier } from '../services/user.service';
import { LeaderboardUserPoints, PointsMultiplier } from '../types/collectionSwapV2';
import { shortenAddress } from '../utils';
import { useCollectionSwaps } from '../contexts/CollectionSwapsContext';

type PointsProps = {
  points?: number
  swaps?: number
  saved?: number
  custom?: number
}

const Profile: React.FC = () => {
  const {
    uid,
    displayName,
    avatar,
    pointsMultipliers,
  } = useAppContext();
  const { getOnChainOffers, getTraitOnChainOffers } = useCollectionSwaps();

  const { addGAEvent } = useUA();

  const location = useLocation();
  const [points, setPoints] = useState<PointsProps>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedUser, setIsLoggedUser] = useState<boolean>(false);
  const [searchedUserAvatar, setSearchedUserAvatar] = useState<string>("");
  const [searchedUserDisplayName, setSearchedUserDisplayName] = useState<string>("");
  const [searchedUserPointsMultiplier, setSearchedUserPointsMultiplier] = useState<PointsMultiplier[]>([]);

  const searchedUser = useMemo(() => {
    async function setSearchedUser(user: string) {
      setIsLoading(true)
      let userData: LeaderboardUserPoints = {}
      try {
        userData = await getLeaderboardPoints(user);
      } catch (e) {
        console.log("Error getting leaderboard points", e)
      }
      setPoints({ swaps: userData.swaps, points: userData.points });

      if (user === uid) {
        setSearchedUserAvatar(avatar || "");
        setSearchedUserDisplayName(displayName || shortenAddress(uid));
        setSearchedUserPointsMultiplier(pointsMultipliers || []);
      } else {
        setSearchedUserAvatar(userData.image || "");
        setSearchedUserDisplayName(userData.name || shortenAddress(user));
        // const pointsMultipliers = await getPointsMultiplier(user);
        // setSearchedUserPointsMultiplier(pointsMultipliers);
      }
      setIsLoading(false)
    }

    const params = new URLSearchParams(location.search);
    const address = params.get('address');
    if (address && address !== uid) {
      setIsLoggedUser(false);
      const user = address.includes("solana-") ? address : `solana-${address}`;
      setSearchedUser(user);
      return user
    }
    setIsLoggedUser(true);
    if (uid) setSearchedUser(uid);
    return uid;
  }, [avatar, displayName, location.search, pointsMultipliers, uid]);

  const offers = useMemo(() => {
    if (!searchedUser) return [];
    const userAddress = searchedUser.split("-")[1];
    const isLoggedUser = searchedUser === uid;
    const filterConfig = {
      maker: [userAddress],
      keepNegativeSavings: isLoggedUser,
    }
    const traitOffers = getTraitOnChainOffers(filterConfig);
    const onChainOffers = getOnChainOffers(filterConfig);

    return [...onChainOffers, ...traitOffers];
  }, [searchedUser, uid, getOnChainOffers, getTraitOnChainOffers]);

  const navigate = useNavigate()

  const handleMenuClick = (path: string) => {
    navigate(path)
  };

  return (
    <>
      <Helmet>
        <title>Potato Cat - Profile</title>
      </Helmet>
      <Box className="max-w-7xl mx-auto">
        <Box className="potato-radius new-bg-transparent shadow-lg p-3 sm:p-4 md:p-6 flex flex-col items-center relative mb-4 sm:mb-8">
          <Flex
            align="center"
            justify="space-between"
            w="full"
            direction={{ base: 'column', md: 'row' }}
          >
            <Flex align="center" mb={{ base: 4, md: 0 }} direction={{ base: 'column', sm: 'row' }}>
              <Avatar size={{ base: "xl", sm: "2xl" }} src={searchedUserAvatar} mb={{ base: 3, sm: 0 }} mr={{ base: 0, sm: 4 }} />
              <Box textAlign={{ base: 'center', sm: 'left' }}>
                <Flex align="center" justify={{ base: 'center', sm: 'flex-start' }} wrap="wrap">
                  <h1 className="text-2xl sm:text-3xl archivo-black avatar-name mb-2">{searchedUserDisplayName}</h1>

                  {searchedUserPointsMultiplier?.length
                    ? searchedUserPointsMultiplier.map((booster) => (
                      <CustomTooltip key={booster.name} label={booster.name + ' 🥔Potatoes Boost! '}>
                        <h1 className="text-sm font-bold booster p-1 potato-radius cursor-pointer ml-2 mb-2">+{booster.multiplier * 100}%</h1>
                      </CustomTooltip>
                    ))
                    : null}
                </Flex>

                <Flex align="center" justify={{ base: 'center', sm: 'flex-start' }} wrap="nowrap">
                  {isLoggedUser && (
                    <button
                      className="secondary-potato-btn mt-2 px-3 sm:px-4 py-1 sm:py-2 font-bold text-sm sm:text-base"
                      onClick={() => {
                        addGAEvent("profile_edit-profile-button_click")
                        handleMenuClick('/edit-profile')
                      }}
                    >
                      Edit Profile
                    </button>
                  )}
                  <Box ml={2}>
                    <ShortenedAddress address={searchedUser?.split("-")[1] || ""} />
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Flex
              direction="row"
              align="center"
              justify="center"
              gap={{ base: 2, sm: 4, md: 8 }}
              className="mt-4 w-full sm:w-auto"
            >
              <Box textAlign="center" mb={{ base: 0, md: 0 }} flex="1">
                <p className="text-sm sm:text-md font-semibold whitespace-nowrap">Completed Swaps</p>
                <div className="potato-radius new-bg-transparent py-1 sm:py-2 mt-1 sm:mt-2">
                  <p className="text-lg sm:text-xl md:text-2xl font-bold">{isLoading ? "..." : points.swaps || 0}</p>
                </div>
              </Box>
              <Box textAlign="center" flex="2">
                <p className="text-sm sm:text-md font-semibold"> Total Potatoes </p>
                <div className="potato-radius new-bg-transparent py-1 sm:py-2 mt-1 sm:mt-2">
                  <p className="text-lg sm:text-xl md:text-2xl font-bold ml-2 mr-2" >{isLoading ? "..." : `${Math.round(points.points || 0)} 🥔`}</p>
                </div>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <h1 className="text-2xl archivo-black mb-4">{isLoggedUser ? "Your" : ""} Swaps</h1>

      <div className="max-w-7xl mx-auto  ">

        <SwapTable
          offers={offers}
          sortConfig={{ sortBy: "time", sortDirection: "asc" }}
        />

        <SwapModal />

      </div>

      <ProposeSwapStickyButton />
    </>
  )
}

export default Profile

