import { useMemo } from "react";
import { useCollectionSwaps } from "../../contexts/CollectionSwapsContext";
import { useAppContext } from "../../contexts/appContext";
import LoadingModal from "./LoadingModal";
import MySwapModal from "./MySwapModal";
import AcceptModal from "./AcceptModal";
import CreateModal from "./CreateModal";
import NotFoundModal from "./NotFoundModal";

const SwapModal: React.FC = function () {
    const { selectedBidId, onModalClose, selectedBid, isLoading, isCreateModalOpen } = useCollectionSwaps();
    const { uid } = useAppContext();

    const address = useMemo(() => uid?.replace("solana-", ""), [uid]);
    const isOpen = useMemo(() => selectedBidId !== null, [selectedBidId]);
    const swapNotFound = useMemo(() => isOpen && !selectedBid, [isOpen, selectedBid]);
    const isMaker = useMemo(() => address && selectedBid?.onChainSwap?.data.maker === address, [selectedBid, address]);

    const isLoadingOpen = useMemo(() => isLoading && isOpen, [isLoading, isOpen]);
    const isNotFoundOpen = useMemo(() => !isLoadingOpen && isOpen && swapNotFound, [isLoadingOpen, isOpen, swapNotFound]);
    const isAcceptOpen = useMemo(() => !isLoadingOpen && isOpen && !swapNotFound && !isMaker, [isLoadingOpen, isOpen, isMaker, swapNotFound]);
    const isMySwapOpen = useMemo(() => !isLoadingOpen && isOpen && !swapNotFound && isMaker, [isLoadingOpen, isOpen, isMaker, swapNotFound]);
    const isCreateOpen = useMemo(() => !isLoadingOpen && isCreateModalOpen, [isLoadingOpen, isCreateModalOpen]);

    const renderModal = () => {
        if (isLoadingOpen) {
            return (
                <LoadingModal isOpen={isLoadingOpen} onClose={onModalClose} />
            );
        }

        if (isNotFoundOpen) {
            return (
                <NotFoundModal isOpen={isNotFoundOpen} onClose={onModalClose} />
            );
        }

        if (isMySwapOpen) {
            return (
                <MySwapModal isOpen={isMySwapOpen} onClose={onModalClose} />
            );
        }

        if (isAcceptOpen) {
            return (
                <AcceptModal isOpen={isAcceptOpen} onClose={onModalClose} />
            );
        }

        if (isCreateOpen) {
            return (
                <CreateModal isOpen={isCreateOpen} onClose={onModalClose} />
            );
        }

        return null;
    };

    return <>{renderModal()}</>;
}

export default SwapModal;