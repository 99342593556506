import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext'
import { useUA } from '../../contexts/userTracking'

interface ProposeSwapButtonProps {
  mode?: "floating" | "no_swaps" | "global"
  type?: 'normal' | 'trait'
}

const ProposeSwapButton: React.FC<ProposeSwapButtonProps> = function ({
  mode = "global",
  type = 'normal'
}) {
  const navigate = useNavigate();
  const { addGAEvent } = useUA();
  const { setActiveTab } = useCollectionSwaps();

  const onOpenPropose = () => {
    addGAEvent('any_propose-swap-button_click', { mode });
    if (type === 'normal') {
      navigate("/create");
    } else {
      setActiveTab('swap');
    }
  };

  return (
    <button
      id="createSwap"
      onClick={onOpenPropose}
      // onClick={() => {
      //   if (!uid) {
      //     signIn()
      //   } else {
      //     onOpenPropose()
      //   }
      // }}
      className="px-8 py-2 potato-btn-radius primary-potato-btn text-center text-white archivo-black "
    >
      Create Swap
    </button>
  )
}

export default ProposeSwapButton
