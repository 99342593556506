import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout from "./components/layout";
import { ProtectedRoute } from "./components/protectedRoute";
import Connect from "./pages/Connect";
import Home from "./pages/Home";
import Feedback from "./pages/Feedback";
import Updates from "./pages/Updates";
import Faq from "./pages/Faq";
import Profile from "./pages/Profile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Image from "./pages/Image";
import CollectionSwap from "./pages/CollectionSwap";
import TwitterOAuthCallback from "./pages/TwitterCallbackRedirect";
import Create from "./pages/Create";
import EditProfile from "./pages/EditProfile";
import Leaderboard from "./pages/Leaderboard";
import Rewards from "./pages/Rewards";
import Collection from "./pages/Collection";

export default function Router() {
  const location = useLocation();

  // This closes mobile menu on page change
  useEffect(() => {
    let toggle = document.getElementById("toggle");

    if (toggle) {
      (toggle as HTMLInputElement).checked = false;
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<Home />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="updates" element={<Updates />} />
      <Route path="feedback" element={<Feedback />} />
      <Route path="/" element={<Layout />}>
        <Route path="faq" element={<Faq />} />
        <Route path="terms-of-service" element={<Terms />} />
        <Route path="explore" element={<CollectionSwap />} />
        <Route path="profile" element={<Profile />} />
        <Route path="leaderboard" element={<Leaderboard />} />
        <Route path="rewards" element={<Rewards />} />
        <Route path="collection/:collectionSlug" element={<Collection />} />
        <Route path="create" element={<Create />} />
        <Route index element={<Create />} />
        <Route
          path="terms-and-conditions"
          element={<Navigate to="/terms-of-service" />}
        />
        <Route path="connect" element={<Connect />} />
        <Route
          path="edit-profile"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="twitter-callback"
          element={
            <TwitterOAuthCallback />
          }
        />
      </Route>
    </Routes>
  );
}
