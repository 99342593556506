import {
  CollectionData,
  FilterConfigV2,
  SwapData,
  TraitCollectionData,
  TraitFilterConfigV2,
} from '../types/collectionSwapV2'

export function filterCollectionsV2(
  collections: CollectionData[],
  names?: string[],
  ids?: string[]
): CollectionData[] {
  if (!names && !ids) return collections
  const lowerCaseNames = names?.map((name) => name.toLowerCase()) || []

  return collections.filter(
    (collection) =>
      lowerCaseNames.includes(collection.collectionName.toLowerCase()) ||
      ids?.includes(collection.onChainId)
  )
}

export function filterTraitCollectionsV2(
  collections: TraitCollectionData[],
  names?: string[],
  ids?: string[],
  traitIds?: string[]
): TraitCollectionData[] {
  collections = filterCollectionsV2(collections, names, ids) as TraitCollectionData[]
  return collections.filter((collection) => traitIds?.includes(collection.traitId))
}

function filterOffersByCollectionsV2(
  offers: SwapData[],
  type: 'maker' | 'taker',
  names: string[],
  ids: string[]
) {
  const lowerCaseNames = names.map((name) => name.toLowerCase())
  return offers.filter((offer) => {
    let collectionName =
      type === 'maker' ? offer.makerCollection.collectionName : offer.takerCollection.collectionName
    let collectionId =
      type === 'maker' ? offer.makerCollection.onChainId : offer.takerCollection.onChainId
    return lowerCaseNames.includes(collectionName.toLowerCase()) || ids.includes(collectionId)
  })
}

export function filterOffersV2(
  offers: SwapData[],
  filterConfig?: FilterConfigV2,
  verbose?: boolean
): SwapData[] {
  // verbose = true
  if (verbose) console.log('filterConfig', filterConfig)
  if (verbose) console.log('offers initial', offers.length)

  if (!filterConfig?.keepNegativeSavings) {
    offers = offers
      .filter((offer) => offer.makerSwapSavings.savings >= 0)
      .filter((offer) => offer.takerSwapSavings.savings >= 0)
    if (verbose) console.log('offers after savings', offers.length)
  }

  if (filterConfig?.makerCollectionIds || filterConfig?.makerCollectionNames) {
    const names = filterConfig?.makerCollectionNames || []
    const ids = filterConfig?.makerCollectionIds || []

    offers = filterOffersByCollectionsV2(offers, 'maker', names, ids)
    if (verbose) console.log('offers after makerCollections', offers.length)
  }

  if (filterConfig?.takerCollectionIds || filterConfig?.takerCollectionNames) {
    const names = filterConfig?.takerCollectionNames || []
    const ids = filterConfig?.takerCollectionIds || []

    offers = filterOffersByCollectionsV2(offers, 'taker', names, ids)
    if (verbose) console.log('offers after takerCollections', offers.length)
  }

  if (filterConfig?.maker && filterConfig.maker.length > 0) {
    offers = offers.filter((offer) =>
      filterConfig.maker?.includes(offer.onChainSwap?.data.maker ?? '')
    )
    if (verbose) console.log('offers after maker', offers.length)
  }

  if (filterConfig?.taker && filterConfig.taker.length > 0) {
    offers = offers.filter((offer) =>
      filterConfig.taker?.includes(offer.onChainSwap?.data.taker ?? '')
    )
    if (verbose) console.log('offers after taker', offers.length)
  }

  if (filterConfig?.makerExclude && filterConfig.makerExclude.length > 0) {
    offers = offers.filter(
      (offer) => !filterConfig.makerExclude?.includes(offer.onChainSwap?.data.maker ?? '')
    )
    if (verbose) console.log('offers after makerExclude', offers.length)
  }

  if (filterConfig?.takerExclude && filterConfig.takerExclude.length > 0) {
    offers = offers.filter(
      (offer) => !filterConfig.takerExclude?.includes(offer.onChainSwap?.data.taker ?? '')
    )
    if (verbose) console.log('offers after takerExclude', offers.length)
  }

  if (filterConfig?.bestFor) {
    offers = filterBestOffers(offers, filterConfig.bestFor === 'maker')
    if (verbose) console.log('offers after best', offers.length)
  }

  if (verbose) console.log('offers final', offers.length)
  return offers
}

export function filterOffersByMakerNftTraitsV2(
  offers: SwapData[],
  makerTraitIds: string[]
): SwapData[] {
  return offers.filter((offer) => {
    const makerNftTraitIds =
      offer.makerNft?.attributes.map((attribute) =>
        (attribute.trait_type + '_' + attribute.value).toLowerCase()
      ) || []

    return makerNftTraitIds.some((traitId) => makerTraitIds.includes(traitId))
  })
}

export function filterOffersByTraitsV2(
  offers: SwapData[],
  filterConfig?: TraitFilterConfigV2,
  verbose?: boolean
): SwapData[] {
  offers = filterOffersV2(offers, filterConfig, verbose)

  if (filterConfig?.makerTraitIds && filterConfig.makerTraitIds.length > 0) {
    offers = filterOffersByMakerNftTraitsV2(offers, filterConfig.makerTraitIds)
    if (verbose) console.log('offers after makerTraitIds', offers.length)
  }

  if (filterConfig?.takerTraitIds && filterConfig.takerTraitIds.length > 0) {
    offers = offers.filter((offer) => {
      if ('traitId' in offer.takerCollection) {
        let bidTraitId = offer.takerCollection.traitId
        return (filterConfig.takerTraitIds ?? []).includes(bidTraitId)
      }
      return false
    })
    if (verbose) console.log('offers after takerTraitIds', offers.length)
  }

  if (filterConfig?.takerNfts && filterConfig.takerNfts.length > 0) {
    offers = offers.filter((offer) => {
      if ('traitId' in offer.takerCollection) {
        let bidTraitId = offer.takerCollection.traitId
        let nftTraitIds =
          filterConfig.takerNfts?.flatMap((nft) =>
            nft.attributes.map((attribute) =>
              (attribute.trait_type + '_' + attribute.value).toLowerCase()
            )
          ) || []

        return nftTraitIds.includes(bidTraitId)
      }
      return false
    })
    if (verbose) console.log('offers after takerNfts', offers.length)
  }

  return offers
}

function filterBestOffers(offers: SwapData[], isMakerPerspective: boolean): SwapData[] {
  let bestOffers: Record<string, { data: SwapData; savings: number }> = {}
  offers.forEach((offer) => {
    let key = `${offer.makerCollection.collectionName}-${offer.takerCollection.collectionName}`
    let savings = isMakerPerspective
      ? offer.makerSwapSavings.savings
      : offer.takerSwapSavings.savings

    let newOffer = { data: offer, savings }

    if (!bestOffers[key]) {
      bestOffers[key] = newOffer
    } else if (savings > bestOffers[key].savings) {
      bestOffers[key] = newOffer
    }
  })
  // console.log('bestOffers', bestOffers)

  return Object.values(bestOffers).map((bestOffer) => bestOffer.data)
}
