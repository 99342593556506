import { Item } from "../../types/collectionSwapV2";

export default function NftItem({ direction, image, collectionName, nftName }: Item & { direction: "Get" | "Give" }) {
  return (
    <div>
      <div className="whitespace-nowrap  text-center" style={{ fontSize: "10px" }}>

        You <span className={`${direction == 'Give' ? "text-nsPink" : "text-strongGreen"}`}> {direction == 'Give' ? "Give" : "Get"}</span>
      </div>

      <div className="overflow-hidden ">
        <div>
          <img src={image} className='potato-radius-sm' style={{ "maxWidth": "44px" }} alt="nft" />
        </div>

        {/* <div className="bg-slate-600 p-2">
          <h5 className='archivo-black text-xxs'>{collectionName.length > 7 ? `${collectionName.substring(0, 7)}...` : collectionName}</h5>
        </div> */}
      </div>
    </div>
  );
}