import { DateTime, DurationObjectUnits } from "luxon";
import { Nft } from "../types/nft";
import IconSolana from "../components/Icons/IconSolana";
import IconStacks from "../components/Icons/IconStacks";
import IconEthereum from "../components/Icons/IconEthereum";
import IconBitcoin from "../components/Icons/IconBitcoin";
import PendingImg from "../assets/pending.jpg";
import { NftMetadata } from "ns-types";
import { TokenBalances } from "../contexts/appContext";
import _ from 'lodash';

export function arraysEqual(arr1: any[], arr2: any[], unordered: boolean = false): boolean {
    if (unordered) {
        return _.isEqual([...arr1].sort(), [...arr2].sort());
    }
    return _.isEqual(arr1, arr2);
}

export const roundValue = (
  value: number,
  trunc: number = 3,
  direction: string = "up"
) => {
  if (value === 0 || !isFinite(value)) return 0;
  const units = 10 ** trunc;
  if (direction === "up") {
    return Math.ceil(value * units) / units;
  } else {
    return Math.floor(value * units) / units;
  }
};

// Utility function to format a number to have 2 significant digits after the decimal point
// export function formatToTwoSignificantDigitsAfterDecimal(num: number): string {
//   if (Math.abs(num) < 1 && Math.abs(num) > 0) {
//     const leadingZeros = Math.floor(-Math.log10(Math.abs(num)));
//     return num.toFixed(leadingZeros + 2);
//   } else {
//     return num.toFixed(2);
//   }
// }
function nZeros(n: number) {
  return "0"
}

export function formatSignificantDigits(num: number, digits: number): string {
  if (num === 0) return "0." + nZeros(digits);

  const absNum = Math.abs(num);
  let digitsAux: number;

  if (absNum < 10 ** -digits) {
    // For very small numbers, use exponential notation
    return "<0." + nZeros(digits - 1) + "1"
  } else if (absNum < 1) {
    // For numbers between 0.01 and 1, find the first non-zero digit
    digitsAux = -Math.floor(Math.log10(absNum)) + 1;
  } else {
    // For numbers >= 1, always use 2 decimal places
    digitsAux = digits;
  }

  // Use Number.prototype.toPrecision() and then parseFloat() to remove trailing zeros
  return parseFloat(num.toPrecision(digitsAux + 1)).toFixed(digitsAux);
}
// This function ensures that the value submitted is valid
// and able to be submitted. It returns a boolean and a reason
type CheckCanSubmitValParams = {
  theValue?: number | string;
  min: number;
  max: number;
  reservePrice: any;
  decimals: number;
  isSecretReserve: boolean;
  isSilentAuction: boolean;
  currentHighestBid: number;
  incrementToken: number;
  incrementPct: number;
  abbr: string;
  truncate: number;
  roomType: string;
  maxbalance: number;
};

export const checkCanSubmitVal: (params: CheckCanSubmitValParams) => {
  canSubmit: boolean;
  reason: string[];
} = ({
  theValue = "0.00",
  min,
  max,
  decimals,
  currentHighestBid,
  incrementToken,
  incrementPct,
  abbr,
  roomType,
  truncate,
  maxbalance
}) => {
    let normalizedValue = Number(theValue) * 10 ** decimals;
    const minAmount = min * 10 ** decimals;

    let minIncrement = 0;

    if (Number.isFinite(currentHighestBid)) {
      minIncrement = currentHighestBid + Math.max(incrementToken, currentHighestBid * incrementPct);

      minIncrement = minIncrement / 10 ** decimals
      minIncrement = roundValue(minIncrement, truncate, 'down')
      minIncrement = minIncrement * 10 ** decimals
    }

    const reasons: string[] = [];

    if (normalizedValue > max) {
      reasons.push(
        `Your bid exceeds the available ${abbr} in your wallet. Please add more ${abbr} or bid the max amount available.`
      );
    } else if (normalizedValue > maxbalance) {
      reasons.push("Bid is more than spending limit!");
    } else if (!theValue) {
      reasons.push("Please enter a bid amount");
    } else if (normalizedValue < minAmount) {
      reasons.push(`Bid is less than minimum bid! (${roundValue(min, truncate, 'down') + " " + abbr})`);
    } else if (roomType !== "smartAuction" && normalizedValue > max) {
      reasons.push("Bid is more than spending limit!");
    } else if (!maxbalance) {
      reasons.push("Bid is more than spending limit!");
    } else if (normalizedValue === currentHighestBid) {
      // const higherValue =
      //   currentHighestBid +
      //   Math.max(incrementToken, currentHighestBid * incrementPct);
      // reasons.push(
      //   `If you would like to bid, please bid ${formatButtonLabel(
      //     roundValue(higherValue / 10 ** decimals, truncate),
      //     abbr
      //   )}`
      // );
    } else if (
      normalizedValue > currentHighestBid &&
      normalizedValue < minIncrement
    ) {
      reasons.push(
        "Your bid does not meet the minimum increment for a new highest bid."
      );
    }

    return {
      canSubmit: reasons.length === 0,
      reason: reasons,
    };
  };

export const createTotalPages = (ids: string[], limit: number) => {
  if (!ids) return 1;
  return Math.ceil(ids.length / limit) || 1;
};

export const getFormattedId = (id: string) => {
  let start = id.substring(0, 3),
    end = id.slice(-3),
    result = start + "..." + end;

  return "#" + result;
};

export const paginate = (
  array: string[],
  pageSize: number,
  pageNumber: number
) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const filterCompleted = (completed: any) => {
  const c = completed || {};
  return Object.keys(c).filter((uid) => {
    return c[uid];
  });
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const hasError = (errors: any, touched: any, field: string) => {
  return touched[field] && errors[field];
};

export const trucPublicKey = (publicKey: string) => {
  if (!publicKey) return "";
  return `${publicKey?.slice(0, 4)}...${publicKey?.slice(-4)}`;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const createRows = (value: any) => {
  if (!value) return {};
  return value?.docs.reduce((acc: any, doc: any) => {
    return {
      ...acc,
      ...doc.data(),
    };
  }, {});
};

export const getFloorPrice = (nft?: Nft | NftMetadata) => {
  if (
    !nft?.collection?.floorPrice?.floorPrice?.valueConverted ||
    !nft?.collection?.floorPrice?.floorPrice?.decimals
  )
    return 0;

  return (
    nft.collection.floorPrice.floorPrice?.valueConverted /
    10 ** nft.collection.floorPrice.floorPrice?.decimals
  );
};

export const createFloor = (nft?: Nft | NftMetadata, truncate?: number) => {
  if (
    !nft?.collection?.floorPrice?.floorPrice?.valueConverted ||
    !nft?.collection?.floorPrice?.floorPrice?.decimals
  )
    return "---";

  const symbol = nft.collection.floorPrice.floorPrice?.symbol;
  return `${roundValue(getFloorPrice(nft), truncate)} ${symbol}`;
};

export const getReserveLabel = (
  reservePrice: number,
  decimals: number,
  abbr: string,
  truncate: number = 3
) => {
  let reservePriceLabel = "--";
  if (reservePrice === 0) {
    reservePriceLabel = "FREE";
  }
  if (reservePrice && reservePrice >= 0) {
    reservePriceLabel = `${roundValue(
      reservePrice / 10 ** decimals,
      truncate
    )} ${abbr}`;
  }
  return reservePriceLabel;
};

export const getMinBidLabel = (
  min: number,
  decimals: number,
  abbr: string,
  truncate: number = 3
) => {
  let minLabel = "--";
  if (min === 0) {
    minLabel = "FREE";
  }
  if (min && min >= 0) {
    minLabel = `${roundValue(
      min / 10 ** decimals,
      truncate
    )} ${abbr}`;
  }
  return minLabel;
};


export const formatButtonLabel = (amount: number, abbr: string) => {
  let formattedAmount = amount;
  if (amount < 0) {
    formattedAmount = 0;
  }
  return formattedAmount + " " + abbr;
};

export const getVolumeLabel = (
  volume: number,
  decimals: number,
  abbr: string,
  truncate: number = 3
) => {
  let volumeLabel = "--";
  if (volume && volume >= 0) {
    volumeLabel = `${roundValue(
      volume / 10 ** decimals,
      truncate
    ).toLocaleString("en-US")} ${abbr}`;
  }
  return volumeLabel;
};

export const getUrlContentType = async (uri: string): Promise<string> => {
  const xhttp = new XMLHttpRequest();
  return new Promise(function (resolve, reject) {
    xhttp.open("HEAD", uri);
    xhttp.onreadystatechange = function () {
      if (this.readyState == this.DONE) {
        console.log(this.status);
        console.log(this.getResponseHeader("Content-Type"));
        resolve(this.getResponseHeader("Content-Type") || "");
      }
    };
    xhttp.send();
  });
};
export const scrubOfUndefined = (obj: any) =>
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

export const getIcon = (chain: string, width?: string, height?: string) => {
  switch (chain?.toLowerCase()) {
    case "ethereum":
      return <IconEthereum width={width} height={height} />;
    case "solana":
      return <IconSolana width={width} height={height} />;
    case "stacks":
      return <IconStacks width={width} height={height} />;
    case "bitcoin":
      return <IconBitcoin width={width} height={height} />;
    default:
      return null;
  }
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const createInitialPrice = (
  nft?: Nft,
  decimals?: number,
  listing?: { reservePrice: number, listingPrice: number },
  truncate: number = 3
) => {
  if (!decimals) return 0;
  if (listing?.listingPrice) {
    return roundValue(listing.listingPrice / 10 ** decimals, truncate);
  }
  if (listing?.reservePrice) {
    return roundValue(listing.reservePrice / 10 ** decimals, truncate);
  }

  if (!nft?.collection?.floorPrice?.floorPrice?.valueConverted) return 0;
  return roundValue(
    nft.collection.floorPrice.floorPrice?.valueConverted /
    10 ** nft.collection.floorPrice.floorPrice?.decimals,
    truncate
  );
};

export const formatName = (name: string) => {
  if (name.length > 15) {
    return name.slice(0, 15) + "...";
  } else {
    return name;
  }
};

export const checkImage = (url: string) => {
  var img = new Image();
  img.src = url;

  img.onerror = function () {
    // Error loading image, replace with default image
    // var defaultUrl = 'path/to/default-image.jpg';
    // img.src = defaultUrl;
    return PendingImg;
  };

  return url;
};

export const checkCollectionMediaType = (collectionName: string) => {
  // Storing the names of the collections
  const knownVideoCollections = ["Presale 90stx", "Worry Nft Music"];
  if (collectionName && knownVideoCollections.includes(collectionName)) {
    return "video";
  } else {
    return "image";
  }
};

export const getTimeLeft = (
  status: string,
  startAt: number,
  biddingSeconds: number,
  listingSeconds: number,
  partyDuration: number,
  timeIncrements: any = ["days", "hours", "minutes", "seconds"]
) => {
  const isLive = startAt < Date.now() / 1000;

  let time = startAt;

  if (partyDuration && isLive) {
    time += partyDuration;
  }

  time += biddingSeconds;

  return DateTime.fromSeconds(time, { zone: "UTC" })
    .diff(DateTime.now().setZone("UTC"), timeIncrements)
    .toObject();
};

export const getTokenInfo = (
  tokenBalances?: TokenBalances,
  activeChainBalance?: string
) => {
  return tokenBalances?.tokens?.find((ele) => ele.id === activeChainBalance);
};

export const getAbbr = (
  tokenBalances?: TokenBalances,
  activeChainBalance?: string
) => {
  const token = getTokenInfo(tokenBalances, activeChainBalance);
  return token?.abbr || "";
};

export const getTruncate = (
  tokenBalances?: TokenBalances,
  activeChainBalance?: string
) => {
  const token = getTokenInfo(tokenBalances, activeChainBalance);
  return token?.truncate || 0;
};

export const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const shortenAddress = (uid: string | undefined): string => {
  const addr = uid?.split("-")[1] || ""
  return `${addr.slice(0, 4)}...${addr.slice(-4)}`;
};


export const getPotateosForBids = (numberOfBids: number, potateosPerBids: number[]) => {
  const potateosPerBid = potateosPerBids.slice(0, numberOfBids + 1).reduce((acc, val) => acc + val, 0)
  return potateosPerBid
}