import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { UserbackProvider } from '@userback/react'
import { HelmetProvider } from 'react-helmet-async'
import reportWebVitals from './reportWebVitals'
import Router from './router'
import theme from './theme'
import Firebase from './services/firebase'
import AppProvider from './contexts/appContext'
import ErrorBoundary from './components/ErrorBoundary'
import TalkJSChatbox from './components/TalkJSChatbox'
import UserAnalyticsProvider from './contexts/userTracking'
import NeoSwapWalletProvider from './contexts/walletContext'
import { CollectionSwapsProvider } from './contexts/CollectionSwapsContext'

import 'react-tooltip/dist/react-tooltip.css'
import './css/app.scss'
import './tailwind-output.css'
import { useMediaQuery } from 'react-responsive'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const App = () => {
  useEffect(() => {
    document.addEventListener(
      'scroll',
      (e: Event) => {
        let nav = document.getElementById('nav-bar')

        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          if (nav) {
            nav.classList.add('fixed')
          }
        } else {
          if (nav) {
            nav.classList.remove('fixed')
          }
        }
      },
      { passive: true }
    )
  }, [])

  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <UserbackProvider
            token={process.env.REACT_APP_USERBACK_TOKEN!}
            options={{
              after_send: () => {
                if (window.location.pathname !== '/feedback') return
                window.location.href = '/'
              },
            }}
          >
            <UserAnalyticsProvider>
              <ChakraProvider
                theme={theme}
                toastOptions={{ defaultOptions: { position: isMobile ? 'top' : 'bottom' } }}
              >
                <NeoSwapWalletProvider>
                  <AppProvider>
                    <CollectionSwapsProvider>
                      <ErrorBoundary>
                            <Router />
                            <TalkJSChatbox />
                      </ErrorBoundary>
                    </CollectionSwapsProvider>
                  </AppProvider>
                </NeoSwapWalletProvider>
              </ChakraProvider>
            </UserAnalyticsProvider>
          </UserbackProvider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  )
}

Firebase.initApp.then(() => {
  root.render(<App />)
})

reportWebVitals()
