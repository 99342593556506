import { Alert, AlertDescription, AlertIcon, ModalBody } from '@chakra-ui/react'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SwapDetailsComponent from '../SwapDetails'
import useSolana from '../../../hooks/useSolana'
import ActionButton, { ActionButtonProps } from '../Buttons/ActionButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import TransactionTable from '../TransactionTable'

interface SignStepProps {
  previousStep: () => void
  nextStep: () => void
  onClose: () => void
}

const SignStep: React.FC<SignStepProps> = function ({ onClose, previousStep, nextStep }) {
  const { uid } = useAppContext()
  const { connected, connect } = useSolana()
  const { selectedBid, transactions, fetchCancelSwapTransactions, fetchCancelSingleBidTransactions, transactionsStatus, setShouldRefresh, waveFees } =
    useCollectionSwaps()
  const [actionButtonProps, setActionButtonProps] = useState<ActionButtonProps>({
    onClick: () => { },
    isDisabled: false,
    text: 'Canceling...',
  })
  const [backButtonProps, setBackButtonProps] = useState<ActionButtonProps>({
    onClick: previousStep,
    isDisabled: false,
    text: 'Back',
  })

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect, connected])

  const singleBid = useMemo(() => {
    if (!selectedBid) return null
    return selectedBid.onChainSwap?.numBids === 1
  }, [selectedBid])

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid, true)
  }, [selectedBid, uid])

  const handleFetchTransactions = useCallback(() => {
    if (selectedBid) {
      if (!singleBid) {
        fetchCancelSingleBidTransactions()
      } else {
        fetchCancelSwapTransactions()
      }
    }
  }, [selectedBid, fetchCancelSwapTransactions, fetchCancelSingleBidTransactions])

  useEffect(() => {
    handleFetchTransactions()
  }, [handleFetchTransactions])

  useEffect(() => {
    if (transactionsStatus === 'success') {
      setShouldRefresh(true)
      setActionButtonProps({
        onClick: () => { },
        isDisabled: true,
        text: 'Canceled',
      })
      setBackButtonProps({
        onClick: onClose,
        isDisabled: false,
        text: 'Close',
      })
      nextStep()
    } else if (transactionsStatus === 'error') {
      setActionButtonProps({
        onClick: handleFetchTransactions,
        isDisabled: false,
        text: 'Retry',
      })
    } else if (transactionsStatus === 'loading') {
      setActionButtonProps({
        onClick: () => { },
        isDisabled: true,
        text: 'Canceling...',
      })
    }
  }, [transactionsStatus, nextStep, handleFetchTransactions, setShouldRefresh, onClose])

  return (
    <ModalBody p={0}>
      <div className="p-6">
        <h2 className="pb-4 archivo-black  text-dark-potato font-2xl">My Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && <SwapDetailsComponent {...swapOverview} past={false} />}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            <TransactionTable
              isLoadingTransactions={!transactions.length}
              transactions={transactions}
            />
            {!waveFees && (
              <Alert
                status="info"
                className="info-alert my-4"
                background={
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.30) 0%, rgba(19, 13, 13, 0.15) 100%)'
                }
                borderRadius={'4px'}
                padding={'20px'}
              >
                <AlertIcon color={'#6C60FF'} />
                <AlertDescription className="text-xs" lineHeight={'14px'}>
                  {
                    'Potato Cat charges a 5% fee on savings, and royalties are paid on the SOL exchanged.'
                  }
                </AlertDescription>
              </Alert>
            )}
          </div>
        </div>
      </div>
      <div className="w-full justify-between  flex gap-2 px-6 pb-6">
        {/* <CloseButton onClick={onClose} /> */}
        <ActionButton {...backButtonProps} />
        <ActionButton {...actionButtonProps} />
      </div>
    </ModalBody>
  )
}

export default SignStep
