import { Alert, AlertDescription, AlertIcon, ModalBody } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import ActionButton, { ActionButtonProps } from '../Buttons/ActionButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import TransactionTable from '../TransactionTable'
import MobileSwapCard from '../MobileSwapCard'
import CloseButton from '../Buttons/CloseButton'
import { confetti } from '../../../services/confetti'
import useSolana from '../../../hooks/useSolana'
import { Points } from '../Points'
import { useUA } from '../../../contexts/userTracking'
import { CopyButton } from '../Buttons/SharingButtons'
import { formatSignificantDigits } from '../../../utils'
import { SwapData, SwapOverview } from '../../../types/collectionSwapV2'

interface SignStepProps {
  previousStep: () => void
  nextStep: () => void
  onClose: () => void
  hasStartedSigning: boolean
  setHasStartedSigning: (value: boolean) => void
}

const SignStep: React.FC<SignStepProps> = function ({
  previousStep,
  nextStep,
  onClose,
  hasStartedSigning,
  setHasStartedSigning,
}) {
  const { uid } = useAppContext()
  const { connected, connect } = useSolana()
  const {
    transactions,
    fetchMakeSwapTransactions,
    fetchTraitMakeSwapTransactions,
    transactionsStatus,
    setSelectedBid,
    setShouldRefresh,
    waveFees,

    bidsToCreate,
    modalMode,
  } = useCollectionSwaps()

  const { addGAEvent } = useUA()

  const offers = useMemo(() => {
    if (!bidsToCreate) return []

    return bidsToCreate
      .map((offer) => {
        const swapOverview = swapDataToOverview(offer, uid, true)
        return {
          offer,
          swapOverview,
        }
      })
      .filter((offer): offer is { offer: SwapData; swapOverview: SwapOverview } => offer !== null)
  }, [
    bidsToCreate,
  ])

  useEffect(() => {
    let nft = offers[0]?.offer.makerNft
    let amount = 0

    offers.forEach(({ swapOverview }) => {
      amount = Math.max(amount, swapOverview.userSavings.swapCost)
    })

    let tooltipActiveText = `You will escrow`
    if (nft) {
      tooltipActiveText += ` ${nft.name}`
      if (amount > 0) {
        tooltipActiveText += ' and'
      }
    }
    if (amount > 0) {
      tooltipActiveText += ` ${formatSignificantDigits(amount, 2)} SOL`
    }

    if (actionButtonProps.text === 'Sign') {
      setActionButtonProps({
        ...actionButtonProps,
        tooltipActiveText,
      })
    }
  }, [offers])

  const handleFetchTransactions = useCallback(() => {
    if (
      uid && bidsToCreate
    ) {
      addGAEvent('any_create-modal_fetch-transactions')
      if (modalMode === 'trait') {
        fetchTraitMakeSwapTransactions()
      } else {
        fetchMakeSwapTransactions()
      }
      setHasStartedSigning(true)
    }
  }, [
    uid,
    bidsToCreate,
    fetchMakeSwapTransactions,
    fetchTraitMakeSwapTransactions,
    modalMode,
  ])

  const [actionButtonProps, setActionButtonProps] = useState<ActionButtonProps>({
    onClick: handleFetchTransactions,
    isDisabled: false,
    text: 'Sign',
    tooltipActiveText: 'You will escrow your NFT',
  })
  const [backButtonProps, setBackButtonProps] = useState<ActionButtonProps>({
    onClick: previousStep,
    isDisabled: false,
    text: 'Back',
  })

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect])

  useEffect(() => {
    if (transactionsStatus === 'success') {
      setShouldRefresh(true)
      setActionButtonProps({
        onClick: () => {},
        isDisabled: true,
        text: 'Created',
      })
      confetti.fire()
      setBackButtonProps({
        onClick: onClose,
        isDisabled: false,
        text: 'Close',
      })
    } else if (transactionsStatus === 'error') {
      setActionButtonProps({
        onClick: handleFetchTransactions,
        isDisabled: false,
        text: 'Retry',
      })
    } else if (transactionsStatus === 'loading') {
      setActionButtonProps({
        onClick: () => {},
        isDisabled: true,
        text: 'Signing...',
      })
    }
  }, [transactionsStatus, nextStep, handleFetchTransactions])

  return (
    <ModalBody p={0} className="potato-modal-bg potato-radius text-dark-potato">
      <div className="p-6">
        <h2 className="pb-4 archivo-black font-2xl">Create Swap(s)</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* MAKER */}
            {(offers.length > 0)
            // ((modalMode === 'trait' && getTraitCollections && getTraitCollections.length > 0) ||
            //   (modalMode !== 'trait' && getCollections && getCollections.length > 0)) 
              && (
              <div className="max-h-[470px] collections-box pt-2 pb-2 potato-radius p-2 flex flex-col gap-2 overflow-y-auto overflow-x-auto md:overflow-x-hidden">
                {offers.map(({ offer, swapOverview }) => {
                  return (
                    <MobileSwapCard
                      key={
                        'traitId' in offer.takerCollection
                          ? offer.takerCollection.traitId
                          : offer.takerCollection.collectionId
                      }
                      {...swapOverview}
                      onSelectSwap={() => {}}
                      onCreateSwaps={() => {}}
                      onView={() => {
                        addGAEvent('any_create-modal_view-offer', { offer })
                        setSelectedBid(offer)
                        nextStep()
                      }}
                      showView={true}
                    />
                  )
                })}
              </div>
            )}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {hasStartedSigning && (
              <TransactionTable
                isLoadingTransactions={!transactions.length}
                transactions={transactions}
              />
            )}

            <Points mode="create" bidsNumber={offers.length} />
            <Points mode="proposal_accepted" />

            {!waveFees && (
              <Alert
                status="info"
                className="info-alert my-4 potato-radius"
                background={
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.30) 0%, rgba(19, 13, 13, 0.15) 100%)'
                }
                padding={'20px'}
              >
                <AlertIcon color={'#eb792b'} />
                <AlertDescription className="text-xs " lineHeight={'14px'}>
                  {
                    'Potato Cat charges a 5% fee on savings, and royalties are paid on the SOL exchanged.'
                  }
                </AlertDescription>
              </Alert>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center gap-2 px-6 pb-6">
        <CloseButton {...backButtonProps} />
        {actionButtonProps.text !== 'Created' && <ActionButton {...actionButtonProps} />}
        {actionButtonProps.text === 'Created' && (
          <CopyButton
            mode={'my-swaps'}
            onClick={() => addGAEvent('create-swap_user-link_copy')}
            isDisabled={transactionsStatus !== 'success'}
            disabledTooltip={'Sign swap first'}
          />
        )}
      </div>
    </ModalBody>
  )
}

export default SignStep
