import { ModalBody } from '@chakra-ui/react'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SurplusComponent from '../Surplus'
import SwapDetailsComponent from '../SwapDetails'
import useSolana from '../../../hooks/useSolana'
import ActionButton from '../Buttons/ActionButton'
import CloseButton from '../Buttons/CloseButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import { Points } from '../Points'
import { useUA } from '../../../contexts/userTracking'

interface OfferStepProps {
  onClose: () => void
  nextStep: () => void
}

const OfferStep: React.FC<OfferStepProps> = function ({ onClose, nextStep }) {
  const { uid, balance, nfts } = useAppContext()
  const { connected, connect, signIn } = useSolana()
  const { selectedBid, solToUsd } = useCollectionSwaps()
  const [tooltipLabel, setTooltipLabel] = useState<string>('')
  const [notEnoughBalance, setNotEnoughBalance] = useState<boolean>(false)

  const { addGAEvent } = useUA()

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect, connected])

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid)
  }, [selectedBid, uid])

  const hasRequiredNft = useMemo(() => {
    if (!nfts || !selectedBid) return false
    let hasCollection = nfts.some((nft) => nft.collection.onChainId === selectedBid.takerCollection.onChainId)
    if (!hasCollection) return false

    if (swapOverview?.traitId) {
      let traitId = swapOverview.traitId.toLowerCase()
      return nfts.some(nft => nft.attributes.some((attribute) => `${attribute.trait_type}_${attribute.value}`.toLowerCase() === traitId))
    }
    return true
  }, [nfts, selectedBid])

  useEffect(() => {
    if (balance && nfts && swapOverview) {
      if (
        !hasRequiredNft
      ) {
        setNotEnoughBalance(true)
        setTooltipLabel('You do not own the required NFTs')
      } else if (swapOverview?.userSavings.swapCost > balance) {
        setNotEnoughBalance(true)
        setTooltipLabel('Not enough balance')
      } else {
        setNotEnoughBalance(false)
        setTooltipLabel('')
      }
    }
  }, [balance, nfts, swapOverview, selectedBid, hasRequiredNft])

  const handleNextStep = useCallback(() => {
    if (!uid) return signIn()
    if (!connected) connect()
    if (notEnoughBalance || !swapOverview || !hasRequiredNft) return
    addGAEvent('accept-modal_swap-now_next-step')
    return nextStep()
  }, [connected, connect, nextStep, signIn, uid, notEnoughBalance, swapOverview, hasRequiredNft])

  return (
    <ModalBody p={0}>
      <div className="p-6 text-dark-potato">
        <h2 className="pb-4 archivo-black font-2xl  ">Instant Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && <SwapDetailsComponent {...swapOverview} past={false} />}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && (
              <div className="flex flex-col justify-center h-full">
                <SurplusComponent
                  userSavings={swapOverview?.userSavings}
                  chainUsdValue={solToUsd}
                  calculatedDate={''}
                />
                <Points mode="accept" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between gap-2 px-6 pb-6">
        <CloseButton onClick={onClose} />
        <ActionButton
          onClick={handleNextStep}
          text="Swap Now"
          tooltipDisabledText={tooltipLabel}
          isDisabled={notEnoughBalance || !swapOverview}
        />
      </div>
    </ModalBody>
  )
}

export default OfferStep
