import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Flex,
  Switch,
} from '@chakra-ui/react'
import CloseButton from '../Buttons/CloseButton'
import { useAppContext } from '../../../contexts/appContext'
import React, { useEffect, useMemo, useState } from 'react'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { CollectionData } from '../../../types/collectionSwapV2'
import ActionButton from '../Buttons/ActionButton'
import { formatSignificantDigits } from '../../../utils'
import AngryCat from '../../../assets/angrypc.png'
import CustomTooltip from '../CustomTooltip'
import { useUA } from '../../../contexts/userTracking'
import { useUserback } from '@userback/react'
import { CheckIcon, SearchIcon, SmallCloseIcon } from '@chakra-ui/icons'
import PotateosBadge from '../PotatoBadge'

// Constants
const SHOW_POTATEOS = true
const MAX_SELECTIONS = 12

// Interfaces
interface DisplayCollectionsProps {
  displayCollections: CollectionData[]
  handleCollectionClick?: (collection: CollectionData) => void
}

interface CollectionDisplayProps {
  collection: CollectionData
  isSelected?: boolean
  points?: number
  showPoints?: boolean
  disableSelection?: boolean
  savings?: number
  showSavings?: boolean
  swapCost?: number
  showSwapCost?: boolean
  tooltipMessage?: string
  handleCollectionClick?: (collection: CollectionData) => void
}

interface PickerColectionProps {
  isOpen: boolean
  onClose: () => void
  collectionNames: string[]
  setCollectionNames: (collectionNames: string[]) => void
  giveCollectionNames?: string[]
  setGetCollectionNames?: (collectionNames: string[]) => void
}

interface SearchInputProps {
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

interface ShowAllCollectionsToggleProps {
  uid: string
  modalText: string
  showAllColecttions: boolean
  handleShowAllCollectionsToggle: () => void
}
interface BaseCollectionModalProps {
  isOpen: boolean
  renderCollections: () => React.ReactNode
  handleClose: () => void
}

// Reusable Components
const NoCollectionToShow: React.FC = () => (
  <div className="w-full flex flex-col items-center justify-center px-3 py-20   text-center">
    <p className="mb-4 archivo-black text-lg">Meow! No collections to show</p>
    <p className="mb-4 text-sm"> Disconnect your wallet to see all available swaps. </p>
    <img
      src={AngryCat}
      style={{ maxWidth: '100px', width: '100%', height: 'auto' }}
      alt="Angry Potato Cat"
    />
  </div>
)

const ShowAllCollectionsToggle: React.FC<ShowAllCollectionsToggleProps> = ({
  uid,
  modalText,
  showAllColecttions,
  handleShowAllCollectionsToggle,
}) => {
  return (
    <Flex justify="space-between" align="center" mb={2}>
      <h4 className="font-bold pb-2">{modalText}</h4>
      {uid && (
        <Switch
          isChecked={!showAllColecttions}
          onChange={handleShowAllCollectionsToggle}
          size="lg"
          sx={{
            '.chakra-switch__track': {
              bg: showAllColecttions ? '#FC822F' : '#99D6E9',
            },
          }}
        ></Switch>
      )}
    </Flex>
  )
}

const SearchInput: React.FC<SearchInputProps> = ({ searchTerm, setSearchTerm }) => (
  <div className="relative mb-4">
    <input
      type="text"
      placeholder="Search collections..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="w-full py-2 potato-radius"
      style={{ paddingLeft: '30px' }}
    />
    <SearchIcon
      color="gray.300"
      position="absolute"
      left="10px"
      top="50%"
      transform="translateY(-50%)"
      zIndex={2}
      pointerEvents="none"
    />
    <SmallCloseIcon
      color="white"
      background="gray.300"
      rounded="full"
      _hover={{ bg: 'gray.400' }}
      position="absolute"
      right="10px"
      top="50%"
      transform="translateY(-50%)"
      zIndex={2}
      onClick={() => setSearchTerm('')}
    />
  </div>
)

const CollectionDisplay: React.FC<CollectionDisplayProps> = ({
  collection,
  points,
  showPoints,
  isSelected,
  disableSelection,
  savings,
  showSavings,
  swapCost,
  showSwapCost,
  tooltipMessage,
  handleCollectionClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  let collectionName =
    collection.collectionName.length > 20
      ? `${collection?.collectionName.slice(0, 17)}...`
      : collection.collectionName

  return (
    <CustomTooltip label={tooltipMessage} isDisabled={!tooltipMessage}>
      <Box
        position="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          key={collection.collectionId}
          className={`flex flex-col sm:flex-row gap-3 sm:gap-0 items-center space-x-4 p-2 collection-card potato-radius cursor-pointer relative ${isSelected ? 'ring-2 ring-orange-500 bg-orange-500 text-white' : ''
            } ${disableSelection ? 'cursor-not-allowed' : ''}`}
          onClick={() => !disableSelection && handleCollectionClick!(collection)}
          tabIndex={0}
          style={{ cursor: 'pointer', opacity: disableSelection ? 0.6 : 1 }}
        >
          <div className="relative">
            <img
              src={collection?.imageUrl}
              alt={collection?.slug}
              className="w-10 h-10 object-cover rounded-full shadow-sm"
            />
            {isSelected && (
              <div className="absolute top-2 left-3">
                <CheckIcon color="#008E20" boxSize={4} />
              </div>
            )}
          </div>
          <div className="flex justify-between flex-1 w-full !ml-0 sm:!ml-3">
            <div className="flex flex-col items-start justify-center">
              <h5 className="font-bold">{collectionName}</h5>
              {showSwapCost ? <SwapCostDisplay swapCost={swapCost} /> : null}
            </div>
            {showSavings && <SwapSavingsDisplay swapSavings={savings} />}
          </div>
        </div>
        {(showPoints && !disableSelection) ? (
          <PotateosBadge
            points={points}
            isSelected={isSelected}
            disableSelection={disableSelection}
            position="absolute"
            top={['-5px', '-5px', '-10px']}
            right="-2px"
            px={0.5}
            py={0.5}
            isSmall={true}
            isHovered={isHovered}
          />
        ) : null}
      </Box>
    </CustomTooltip>
  )
}

// Base Modal Component
const BaseCollectionModal: React.FC<BaseCollectionModalProps> = ({
  isOpen,
  renderCollections,
  handleClose,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={handleClose}
    size="2xl"
    closeOnOverlayClick={false}
    trapFocus={false}
  >
    <ModalOverlay />
    <ModalContent className="potato-modal-bg potato-radius" maxW={'400px'}>
      <ModalHeader></ModalHeader>
      <ModalBody p={0} className="text-dark-potato">
        <div className="flex flex-col px-4 justify-center mb-4">{renderCollections()}</div>
        <div className="w-full flex justify-between gap-2 px-6 pb-6">
          <CloseButton onClick={handleClose} />
          <ActionButton onClick={handleClose} text={'Select'} />
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
)

// Main Components
export const GiveCollectionModal: React.FC<PickerColectionProps> = ({
  isOpen,
  onClose,
  collectionNames,
  setCollectionNames,
  setGetCollectionNames,
}) => {
  const { uid, nfts } = useAppContext()
  const { collectionsData } = useCollectionSwaps()
  const giveCollections = useMemo(
    () =>
      collectionNames.map((collectionName) =>
        collectionsData.find((collection) => collection.collectionName === collectionName)
      ),
    [collectionNames, collectionsData]
  )

  const { addGAEvent } = useUA()

  const [searchTerm, setSearchTerm] = useState('')

  const [showAllColecttions, setShowAllCollections] = useState(false)

  const handleShowAllCollectionsToggle = () => {
    setShowAllCollections((prev) => !prev)
    setCollectionNames([])
  }

  useEffect(() => {
    addGAEvent('picker-modal_give-collection_open')
  }, [])

  const handleClose = () => {
    setSearchTerm('')
    onClose()
  }
  const { open } = useUserback()

  const userSupportedCollections = useMemo(() => {
    if (!nfts) return []

    const nftCollectionIds = new Set(nfts.map((nft) => nft.collection.onChainId))

    const filteredCollections = collectionsData.filter((collection) =>
      nftCollectionIds.has(collection.onChainId)
    )

    return filteredCollections
  }, [nfts, collectionsData])

  const displayCollections = useMemo(() => {
    let filteredCollections =
      showAllColecttions || !uid ? collectionsData : userSupportedCollections

    if (searchTerm) {
      filteredCollections = filteredCollections.filter((collection) =>
        collection.collectionName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    return filteredCollections.sort((a, b) => a.collectionName.localeCompare(b.collectionName))
  }, [searchTerm, showAllColecttions, collectionsData, uid, userSupportedCollections])

  const handleCollectionClick = (collection: CollectionData) => {
    let updatedCollections
    const isSelected = collectionNames.some((c) => c === collection.collectionName)
    if (isSelected) {
      updatedCollections = collectionNames.filter((c) => c !== collection.collectionName)
    } else {
      updatedCollections = [collection.collectionName]
    }
    if (
      collectionNames.length > 0 &&
      collectionNames[0] !== collection.collectionName &&
      setGetCollectionNames
    ) {
      setGetCollectionNames([])
    }
    addGAEvent('picker_give-collection_select', { collection: collection.collectionName })
    setCollectionNames(updatedCollections)
    handleClose()
  }

  const ShowCollectionsToGiveList: React.FC<DisplayCollectionsProps> = ({
    displayCollections,
    handleCollectionClick,
  }) => (
    <div className="potato-radius" style={{ overflow: 'hidden' }}>
      <Box className="grid grid-cols-1 gap-2 max-h-[470px] overflow-y-auto p-2 collections-box pt-2 pb-2 potato-radius potato-scrollbar">
        {displayCollections && displayCollections.length > 0 ? (
          displayCollections.map((collection) => {
            const isSelected = giveCollections.some(
              (giveCollection) => giveCollection?.collectionId === collection.collectionId
            )

            return (
              <CollectionDisplay
                isSelected={isSelected}
                collection={collection}
                handleCollectionClick={handleCollectionClick}
              />
            )
          })
        ) : (
          <NoCollectionToShow />
        )}
      </Box>
    </div>
  )

  const renderCollections = () => (
    <>
      <ShowAllCollectionsToggle
        uid={uid!}
        modalText={'Choose Collection to Give'}
        showAllColecttions={showAllColecttions}
        handleShowAllCollectionsToggle={handleShowAllCollectionsToggle}
      />
      <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <ShowCollectionsToGiveList
        displayCollections={displayCollections}
        handleCollectionClick={handleCollectionClick}
      />

      <div className="text-center text-sm pb-4 mt-2 font-bold">
        Can't find a collection?{' '}
        <u className="text-secondary-potato cursor-pointer" onClick={() => open('feature_request')}>
          Click here
        </u>{' '}
        to request it.
      </div>
    </>
  )

  return (
    <BaseCollectionModal
      key={'give-collection-modal'}
      isOpen={isOpen}
      renderCollections={renderCollections}
      handleClose={handleClose}
    />
  )
}

const SwapCostDisplay: React.FC<{ swapCost?: number }> = ({ swapCost }) => {
  if (!swapCost) return '-'

  let swapCostString = formatSignificantDigits(Math.abs(swapCost), 2)

  return (
    <div className="text-xs font-bold">
      + You{' '}
      <span className={`${swapCost > 0 ? 'text-nsPink' : 'text-strongGreen'}`}>
        {swapCost > 0 ? 'Give' : 'Get'}
      </span>{' '}
      {swapCostString} SOL
    </div>
  )
}

const SwapSavingsDisplay: React.FC<{ swapSavings?: number }> = ({ swapSavings }) => {
  if (!swapSavings) return '-'

  let swapSavingsString = formatSignificantDigits(Math.abs(swapSavings), 2)

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <span className="text-xs">Savings</span>
      <h5 className="font-bold text-xs text-strongGreen no-shadow">
        {swapSavings ? `${swapSavingsString} SOL` : null}
      </h5>
    </div>
  )
}

const getPointsPerActiveBid = (index: number, pointsPerActiveBid: Record<number, number>) => {
  return pointsPerActiveBid[index + 1] - pointsPerActiveBid[index]
}

export const GetCollectionModal: React.FC<PickerColectionProps> = ({ isOpen, onClose, collectionNames, setCollectionNames, giveCollectionNames }) => {
  const { uid, balance } = useAppContext()
  const {
    collectionsData,
    getOffChainOffers,
    calculatedPointsConfiguration,
  } = useCollectionSwaps()

  const { addGAEvent } = useUA()

  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    addGAEvent('picker-modal_get-collection_open')
  }, [])

  const handleClose = () => {
    setSearchTerm('')
    onClose()
  }

  const calculatedOffers = useMemo(() => {
    if (!giveCollectionNames) return []
    return getOffChainOffers({
      makerCollectionNames: giveCollectionNames,
    })
  }, [getOffChainOffers, giveCollectionNames])

  const displayCollections = useMemo(() => {
    let filteredCollections

    filteredCollections = collectionsData

    if (searchTerm) {
      filteredCollections = filteredCollections.filter((collection) =>
        collection.collectionName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }
    filteredCollections.sort((a, b) => a.collectionName.localeCompare(b.collectionName))

    filteredCollections = filteredCollections
      .map((collection) => {
        const selectedIndex = collectionNames.findIndex(
          (c) => c === collection.collectionName
        )
        const isSelected = selectedIndex !== -1

        const points = isSelected
          ? getPointsPerActiveBid(selectedIndex, calculatedPointsConfiguration.pointsPerActiveBid)
          : getPointsPerActiveBid(
              collectionNames.length,
              calculatedPointsConfiguration.pointsPerActiveBid
            )

        let offer = calculatedOffers.find(
          (offer) =>
            offer.makerCollection.collectionName === (giveCollectionNames?.[0] || '') &&
            offer.takerCollection.collectionName === collection.collectionName
        )

        const savings = offer?.makerSwapSavings.savings || -1
        const swapCost = offer?.makerSwapSavings.swapCost || 0

        const notEnoughBalance = uid && balance !== undefined && swapCost > balance
        const maxSelectionsReached = collectionNames.length >= MAX_SELECTIONS

        const isDisabled = !isSelected && (notEnoughBalance || maxSelectionsReached)

        const tooltipMessage = isDisabled
          ? notEnoughBalance
            ? `You don't have enough SOL. Add ${formatSignificantDigits(
                Math.abs(swapCost - balance),
                2
              )} SOL to proceed.`
            : `You can only select up to ${MAX_SELECTIONS} collections.`
          : ''

        return {
          collection,
          isSelected,
          points,
          savings,
          swapCost,
          disableSelection: isDisabled,
          tooltipMessage,
          notEnoughBalance,
        }
      })
      .filter((collection) => collection.savings !== -1)

    filteredCollections = filteredCollections.sort(
      (a, b) => Number(a.notEnoughBalance) - Number(b.notEnoughBalance)
    )
    // console.log('calculatedOffers', calculatedOffers)
    // console.log('filteredCollections', filteredCollections)

    return filteredCollections
  }, [searchTerm, collectionsData, giveCollectionNames, collectionNames, calculatedOffers])

  const handleCollectionClick = (collection: CollectionData) => {
    let updatedCollections

    const isSelected = collectionNames.some((c) => c === collection.collectionName)
    if (isSelected) {
      updatedCollections = collectionNames.filter((c) => c !== collection.collectionName)
    } else {
      if (collectionNames.length < MAX_SELECTIONS) {
        updatedCollections = [...collectionNames, collection.collectionName]
      } else {
        return
      }
    }
    addGAEvent('picker_get-collection_select', { collection: collection.collectionName })

    console.log(
      'updatedcollections', updatedCollections)
    setCollectionNames(updatedCollections)
  }

  const renderCollections = () => {
    let totalPoints = calculatedPointsConfiguration.pointsPerActiveBid[collectionNames.length]
    let showPotateos = collectionNames.length > 0 && giveCollectionNames && giveCollectionNames.length > 0 && SHOW_POTATEOS

    return (
      <>
        <div className="flex items-center justify-between">
          <h4 className="font-bold pb-2">
            {'Choose Collection(s) to '}
            <span className="text-strongGreen">Get</span>
          </h4>
          {showPotateos && <PotateosBadge points={totalPoints} isSelected={true} />}
        </div>
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <div className="potato-radius" style={{ overflow: 'hidden' }}>
          <Box className="grid grid-cols-1 gap-2 max-h-[470px] overflow-y-auto p-1 collections-box pt-3 pb-3 potato-radius  potato-scrollbar">
            {displayCollections && displayCollections.length > 0 ? (
              displayCollections.map((collection) => (
                <CollectionDisplay
                  key={collection.collection.collectionId}
                  showPoints={true}
                  showSavings={true}
                  showSwapCost={true}
                  {...collection}
                  handleCollectionClick={handleCollectionClick}
                />
              ))
            ) : (
              <div className="w-full flex items-center justify-center px-3 py-20 text-center">
                No collection found.
              </div>
            )}
          </Box>
        </div>
      </>
    )
  }

  return (
    <BaseCollectionModal
      key={'get-collection-modal'}
      isOpen={isOpen}
      renderCollections={renderCollections}
      handleClose={handleClose}
    />
  )
}
