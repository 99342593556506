import "../css/components/homepage.scss";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ChakraLink, Box, Button, Flex, Center, Heading } from "@chakra-ui/react";
import Navbar from "../components/navbar";
import { BsTwitter, BsDiscord, BsMedium } from "react-icons/bs";
import PartnerGroup from "../components/PartnerGroup";
import lines2 from "../assets/lines.png";
import backgroundImage from '../assets/bg-sa.png'; // Adjust the path as necessary
import potatoCatBig from '../assets/potatocat-big.png'; // Adjust the path as necessary
import FAQList from "../components/FAQs";
import LandingFooter from "./LandingFooter";

function isMobile() {
  return (
    "ontouchstart" in document.documentElement || navigator.maxTouchPoints > 0
  );
}

export default function Home() {


  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll event triggered');
      const wrapper = document.querySelector('.tn-atom') as HTMLElement;
      const image = document.querySelector('.tn-atom__img') as HTMLImageElement;

      if (!wrapper || !image) {
        console.log('Wrapper or image not found');
        return;
      }

      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      console.log('Scroll Position:', scrollPosition, 'Window Height:', windowHeight);

      if (scrollPosition < windowHeight) {
        const opacity = 1 - (scrollPosition / windowHeight) - (scrollPosition / windowHeight) * 0.5;
        const scale = 1 - (scrollPosition / windowHeight) * 0.5;

        console.log('Opacity:', opacity, 'Scale:', scale);

        if (opacity >= 0.8) {
          wrapper.style.opacity = '0.8';
        } else {
          wrapper.style.opacity = opacity.toString();
          if (opacity <= 0) {
            wrapper.style.visibility = 'hidden';
          } else {
            wrapper.style.visibility = 'visible';
          }
        }
        image.style.transform = `scale(${Math.max(scale, 0.5)})`;
      } else {
        wrapper.style.opacity = '0';
        image.style.transform = 'scale(0.5)';
      }
    };

    console.log("addding event")
    window.addEventListener('scroll', handleScroll);

    const wrapper = document.querySelector('.tn-atom') as HTMLElement;
    const image = document.querySelector('.tn-atom__img') as HTMLImageElement;
    if (wrapper && image) {
      wrapper.style.opacity = '0.8';
      image.style.transform = 'scale(1)';
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("home-page");

    if (isMobile()) document.body.classList.add("is-mobile");

    const scrollElem = document.getElementById("scroll-to");

    if (scrollElem) {
      scrollElem.onclick = function () {
        document.getElementById("app-links-list")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      };
    }

    return () => {
      document.body.classList.remove("home-page");
      document.body.classList.remove("is-mobile");
    };
  }, []);

  type CustomArrowProps = {
    onClick: () => void; // assuming onClick is a function
  };

  const CustomNextArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -right-10 top-44 z-10  bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 2L13.5 14L1.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const CustomPrevArrow = (props: CustomArrowProps) => {
    const { onClick } = props;
    return (
      <div
        className="w-16 h-16 absolute -left-10 top-44 z-10 bg-opacity-30 rounded-full backdrop-blur-3xl flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <svg
          width="16"
          height="28"
          viewBox="0 0 16 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 2L2.5 14L14.5 26"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };

  const settings = {
    customPaging: function (i: number) {
      return <div className="w-2 h-2 rounded-full opacity-30 bg-white" />;
    },
    dots: true,
    dotsClass:
      "justify-center items-center gap-2.5 !flex w-full mt-6 aitradeslider",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: (
      <CustomNextArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
    prevArrow: (
      <CustomPrevArrow
        onClick={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
  };

  const navigate = useNavigate();
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`No element with ID ${sectionId} found`);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Potato Cat AI
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="title"
          content="NeoSwap"
        />
        <meta
          name="description"
          content="Get NFTs you want using NFTs you own."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://neoswap.xyz" />
        <meta property="og:title" content="NeoSwap" />
        <meta
          property="og:description"
          content="Get NFTs you want using NFTs you own."
        />
        <meta property="og:image" content="" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://neoswap.xyz" />
        <meta property="twitter:title" content="NeoSwap" />
        <meta
          property="twitter:description"
          content="Get NFTs you want using NFTs you own."
        />
        <meta property="twitter:image" content="" />
      </Helmet>

      <svg style={{ display: "none" }} aria-hidden="true" xmlSpace="preserve">
        <symbol id="svg-twitter" viewBox="0 0 30 30">
          <path
            d="m29.3 6.2c-1.1 0.5-2.2 0.8-3.4 0.9 1.2-0.7 2.1-1.9 2.6-3.2-1.1 0.7-2.4 1.2-3.7 1.4-1.1-1.1-2.6-1.8-4.3-1.8-3.2 0-5.8 2.6-5.8 5.9 0 0.5 0 0.9 0.1 1.3-4.9-0.3-9.2-2.7-12.1-6.2-0.5 0.9-0.8 1.9-0.8 3 0 2 1 3.8 2.6 4.9-0.9-0.1-1.8-0.4-2.6-0.8v0.1c0 2.8 2 5.2 4.7 5.8-0.5 0.1-1 0.2-1.5 0.2-0.4 0-0.7 0-1.1-0.1 0.8 2.3 2.9 4 5.5 4.1-2 1.6-4.5 2.5-7.2 2.5-0.5 0-0.9 0-1.4-0.1 2.6 1.7 5.7 2.6 9 2.6 10.8 0 16.7-8.9 16.7-16.6v-0.8c0.9-0.9 1.9-2 2.7-3.1z"
            fill="currentColor"
          ></path>
        </symbol>
        <symbol id="svg-discord" viewBox="0 0 30 30">
          <path
            d="m5.7 25.2h15.8l-0.8-2.4 1.8 1.6 1.7 1.5 3 2.5v-24c-0.1-1.5-1.3-2.7-2.9-2.7h-18.6c-1.6 0-2.9 1.3-2.9 2.8v18c0 1.5 1.3 2.7 2.9 2.7zm11.7-17.2h-0.1 0.1zm-8.5 1.4c2-1.5 3.9-1.4 3.9-1.4l0.2 0.2c-2.5 0.6-3.6 1.7-3.6 1.7s0.3-0.1 0.8-0.4c3.4-1.3 7-1.2 10.4 0.5 0 0-1.1-1-3.5-1.7l0.2-0.2c0.3 0 2 0.1 3.9 1.4 0 0 2.1 3.5 2.1 7.8-0.1-0.1-1.3 1.9-4.4 1.9 0 0-0.5-0.6-0.9-1.1 1.8-0.5 2.5-1.6 2.5-1.6-0.6 0.4-1.1 0.6-1.6 0.8-0.7 0.3-1.3 0.4-2 0.6-3.2 0.5-5-0.3-6.7-1l-0.6-0.3s0.7 1 2.4 1.6c-0.5 0.5-0.9 1.1-0.9 1.1-3.1-0.1-4.2-2-4.2-2 0-4.4 2-7.9 2-7.9z"
            fill="currentColor"
          ></path>
          <path
            d="m17.6 15.9c0.8 0 1.4-0.7 1.4-1.5s-0.6-1.5-1.4-1.5-1.4 0.7-1.4 1.5c-0.1 0.8 0.6 1.5 1.4 1.5z"
            fill="currentColor"
          ></path>
          <path
            d="m12.4 15.9c0.8 0 1.4-0.7 1.4-1.5s-0.6-1.5-1.4-1.5-1.4 0.7-1.4 1.5 0.7 1.5 1.4 1.5z"
            fill="currentColor"
          ></path>
        </symbol>
        <symbol id="svg-medium" viewBox="0 0 30 30">
          <path
            d="M16.84,15c0,4.51-3.63,8.17-8.11,8.17S0.62,19.51,0.62,15s3.63-8.17,8.11-8.17C13.21,6.83,16.84,10.49,16.84,15"
            fill="currentColor"
          ></path>
          <path
            d="M25.74,15c0,4.25-1.82,7.69-4.06,7.69s-4.06-3.44-4.06-7.69s1.82-7.69,4.06-7.69 C23.93,7.31,25.74,10.75,25.74,15"
            fill="currentColor"
          ></path>
          <path
            d="M29.38,15c0,3.8-0.64,6.89-1.43,6.89c-0.79,0-1.43-3.08-1.43-6.89s0.64-6.89,1.43-6.89 C28.74,8.11,29.38,11.2,29.38,15"
            fill="currentColor"
          ></path>
        </symbol>
        <symbol id="svg-cross" viewBox="0 0 30 30">
          <path
            d="M19.2 15l7.8-7.8c0.9-0.9 0.9-2.2 0-3.1l-1-1c-0.9-0.9-2.2-0.9-3.1 0L15 10.9 7.2 3.1c-0.9-0.9-2.2-0.9-3.1 0L3.1 4.1c-0.9 0.9-0.9 2.3 0 3.1L10.9 15l-7.8 7.8c-0.9 0.9-0.9 2.3 0 3.1l1 1c0.9 0.9 2.3 0.9 3.1 0L15 19.2l7.8 7.8c0.9 0.9 2.3 0.9 3.1 0l1-1c0.9-0.9 0.9-2.2 0-3.1L19.2 15z"
            fill="currentColor"
          />
        </symbol>
        <symbol id="svg-solana" viewBox="0 0 30 30">
          <path
            d="M5.76,20.65c0.17-0.17,0.39-0.26,0.63-0.26h21.84c0.4,0,0.6,0.48,0.32,0.76l-4.31,4.31 c-0.17,0.17-0.39,0.26-0.63,0.26H1.77c-0.4,0-0.6-0.48-0.32-0.76L5.76,20.65z"
            fill="currentColor"
          ></path>
          <path
            d="M5.76,4.54c0.17-0.17,0.4-0.26,0.63-0.26h21.84c0.4,0,0.6,0.48,0.32,0.76l-4.31,4.31 c-0.17,0.17-0.39,0.26-0.63,0.26H1.77c-0.4,0-0.6-0.48-0.32-0.76L5.76,4.54z"
            fill="currentColor"
          ></path>
          <path
            d="M24.24,12.54c-0.17-0.17-0.39-0.26-0.63-0.26H1.77c-0.4,0-0.6,0.48-0.32,0.76l4.31,4.31 c0.17,0.17,0.39,0.26,0.63,0.26h21.84c0.4,0,0.6-0.48,0.32-0.76L24.24,12.54z"
            fill="currentColor"
          ></path>
        </symbol>
        <symbol id="svg-stacks" viewBox="0 0 30 30">
          <path
            d="M19.16,18.93L23.7,26h-3.39l-5.32-8.31L9.67,26H6.3l4.53-7.06h-6.5v-2.68h21.33v2.66H19.16z"
            fill="currentColor"
          ></path>
          <path
            d="M25.67,10.98v2.68v0.02H4.33v-2.7h6.38L6.23,4h3.39l5.37,8.42L20.38,4h3.39l-4.48,6.98H25.67z"
            fill="currentColor"
          ></path>
        </symbol>
        <symbol id="svg-arrow" viewBox="0 0 30 30">
          <path
            d="M15,22.52c-0.47,0-0.95-0.18-1.31-0.54L2.36,10.64c-0.72-0.72-0.72-1.89,0-2.61c0.72-0.72,1.89-0.72,2.61,0 L15,18.06L25.03,8.03c0.72-0.72,1.89-0.72,2.61,0c0.72,0.72,0.72,1.89,0,2.61L16.31,21.97C15.94,22.34,15.47,22.52,15,22.52z"
            fill="currentColor"
          ></path>
        </symbol>
      </svg>

      <Box
        id="container"
        position={"relative"}
        px={"60px"}
        pt={"30px"}
      >
        {window.location.pathname == '/star-atlas-skins' ? (
          <div
            style={{
              backgroundImage: `url(${backgroundImage})`,
              zIndex: -1,

            }}
            className="fixed w-full h-full bg-no-repeat bg-cover bg-center"
          />
        ) : (
          <div
            style={{
              backgroundImage: `url(${lines2})`,
              zIndex: -1,
            }}
            className="fixed w-full h-full bg-no-repeat bg-cover bg-center opacity-10"
          />
        )}
        <div className="inner-content">


          <div id="hero-section" className="w-full">
            <div className="container">
              <div className="flex flex-col mb-8 gap-4 items-center justify-center font-poppins">
                {/* <div className="px-7 py-1 bg-cyan-400 bg-opacity-25 rounded-3xl gap-2.5 inline-flex text-center text-cyan-400 text-base font-medium uppercase">
                      Paused
                      
                    </div> */}


                <h1 className="text-white text-center text-6xl archivo-black">
                  Next-Gen <br></br>
                  NFT Swap
                </h1>
                <p className="text-center text-white text-xl font-regular leading-loose">
                  Get NFTs you want using NFTs you own
                </p>


                <Flex gap="4">
                  <button
                    onClick={() => navigate("/create")}
                    className="px-8 py-2 potato-btn-radius primary-potato-btn gap-2 inline-flex text-center archivo-black"
                  >
                    Start Swapping
                  </button>

                </Flex>
                <div className="tn-atom__sbs-anim-wrapper">
                  <div className="tn-atom">
                    <img className="tn-atom__img" src={potatoCatBig} alt=""
                    />
                  </div>
                </div>


              </div>
            </div>
          </div>

          <PartnerGroup />

          <FAQList />
        </div>
      </Box>
    </>
  );
}
