import { useUserback } from '@userback/react';
import Picker from '../components/CollectionSwap/Picker'
import { Box, Text, Button, Flex, Image } from '@chakra-ui/react'
import PartnerGroup from '../components/PartnerGroup';
import FloatingImage from '../components/FloatingImage';
import { useState } from 'react';
import FloatingPotatoCat from "../assets/potatocat-pet.png"
import { Helmet } from 'react-helmet-async';

const CollectionSwap = () => {
  return (
    <>
      <Helmet>
        <title>Potato Cat - Swap</title>
      </Helmet>
      <div className='mb-20'>
        <Box position="relative" overflow="hidden" minHeight="100vh">
          <FloatingImage
            imageSrc={FloatingPotatoCat}
          />

          <Box className="swap-ui potato-radius bg-opacity-50 " p={4} boxShadow="md" maxW="sm" mx="auto" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize="sm" mt={2} className='text-center'>
                Share your feedback and earn potatoes 🥔!
              </Text>
              <a className='primary-potato-btn-outline  potato-btn-radius text-center px-6 py-2 text-white archivo-black mt-3 mb-3  ' href="https://tulle.cc/potato-cat-survey" target='_blank'>
                Earn 🥔500
              </a>
            </Flex>
          </Box>
          <Box className="mt-4" >
            <Picker />
          </Box>
          <PartnerGroup />
        </Box>
      </div>
    </>

  )
}

export default CollectionSwap
