import React, { useState } from 'react'
import { formatSignificantDigits } from '../../utils'
import { Item, SwapOverview } from '../../types/collectionSwapV2'

const SwapDetailsComponent: React.FC<SwapOverview & { past: boolean }> = ({
  giveItem,
  getItem,
  userSavings,
  endTime,
  isMaker,
  past = false,
}) => {
  const [activeTab, setActiveTab] = useState<'neoswap' | 'marketplace'>('neoswap')

  const neoSwapCost = userSavings.swapCost
  const marketplaceCost = userSavings.marketplaceCost

  const renderSwapDetails = (swapCost: number) => (
    <>
      {swapCost !== 0 ? (
        <>
          <div className="text-xl">+</div>
          <div
            className={`w-full potato-radius aspect-square border mobile-swap-bg flex items-center archivo-black text-2xl justify-center rounded-md ${activeTab === 'marketplace' ? 'text-secondary-pinkFloyd' : ''
              }`}
          >
            {formatSignificantDigits(Math.abs(swapCost), 2)} SOL
          </div>
        </>
      ) : null}
    </>
  )

  const handleImageClick = (item: Item) => {
    window.open(`https://solscan.io/token/${item.onChainId}`, '_blank')
  }

  return (
    <div className="">
      <div className="flex items-center border-2 border-b-0  swap-ui  w-fit rounded-t overflow-hidden"
        style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}>
        <button
          className={`px-4 archivo-black py-0 text-sm ${activeTab === 'neoswap' ? ' primary-potato-btn  text-white' : 'bg-transparent'
            }`}
          onClick={() => setActiveTab('neoswap')}
        >
          With Potato Cat
        </button>
        <button
          className={`px-4 archivo-black py-0 text-sm ${activeTab === 'marketplace' ? 'primary-potato-btn  text-white' : 'bg-transparent'
            }`}
          onClick={() => setActiveTab('marketplace')}
        >
          Without Potato Cat
        </button>
      </div>

      <div className="flex justify-center items-center gap-4 border-2   p-2  swap-ui " style={{ borderTopRightRadius: "30px", borderBottomLeftRadius: "30px", borderBottomRightRadius: "30px", minHeight: "380px" }}>
        <div className="flex flex-col">
          {/* Give Item */}
          <h4 className="archivo-black pb-2">
            {past ? 'I' : 'You'}{' '}
            <span className="text-secondary-pinkFloyd">{past ? 'Gave' : 'Give'}</span>
          </h4>
          <div className="flex flex-col items-center justify-center gap-3">
            <div className="cursor-pointer" onClick={() => handleImageClick(giveItem)}>
              <img
                className="hover:opacity-80 potato-radius"
                src={giveItem.image}
                alt="Placeholder"
                style={{ maxHeight: '100px', maxWidth: '100px' }}
              />
              <h5 className="archivo-black pt-2">{giveItem.nftName}</h5>
              <h6>{giveItem.collectionName}</h6>
            </div>
            {activeTab === 'neoswap' && neoSwapCost > 0 && renderSwapDetails(neoSwapCost)}
            {activeTab === 'marketplace' &&
              marketplaceCost > 0 &&
              renderSwapDetails(marketplaceCost)}
          </div>
        </div>

        <div>
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 16L0 11L5 6L6.4 7.425L3.825 10H11V12H3.825L6.4 14.575L5 16ZM15 10L13.6 8.575L16.175 6H9V4H16.175L13.6 1.425L15 0L20 5L15 10Z"
              fill="#444444"
            ></path>
          </svg>
        </div>

        <div className="flex flex-col">
          {/* Get Item */}
          <h4 className="archivo-black pb-2">
            {past ? 'I' : 'You'} <span className="text-strongGreen">{past ? 'Got' : 'Get'}</span>
          </h4>
          <div className="flex flex-col items-center justify-center gap-3">
            <div className="cursor-pointer" onClick={() => handleImageClick(getItem)}>
              <img
                className="hover:opacity-80  potato-radius"
                src={getItem.image}
                alt="Placeholder"
                style={{ maxHeight: '100px', maxWidth: '100px' }}
              />
              <h5 className="archivo-black pt-2">{getItem.nftName}</h5>
              <h6>{getItem.collectionName}</h6>
            </div>
            {activeTab === 'neoswap' && neoSwapCost < 0 && renderSwapDetails(-neoSwapCost)}
            {activeTab === 'marketplace' &&
              marketplaceCost < 0 &&
              renderSwapDetails(-marketplaceCost)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SwapDetailsComponent
