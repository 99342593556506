import {
  Connection,
  VersionedTransaction,
} from '@solana/web3.js'
import bs58 from 'bs58'

import {
  neoColTypes as nCT,
  BTv,
} from '@neoswap/solana-collection-swap'

import useSolanaAuth from './useSolanaAuth'

import { sleep } from '../utils'
import { isVersionedTransaction } from '@solana/wallet-adapter-base'

/**
 * Represents possible errors that can occur during bundle operations.
 */
export type BundleError = {
  error:
    | 'no order found'
    | 'not connected'
    | 'no wallet'
    | 'no address'
    | 'no provider'
    | 'wallet error' // TODO: add more errors
  detail?: string
}

let PRIORITIZATION_FEE: number | undefined = undefined
PRIORITIZATION_FEE = 1e6

/**
 * Custom hook for Solana-related operations.
 * Provides functions for signing and checking transactions.
 */
export default function useSolana() {
  const solanaAuth = useSolanaAuth()

  /**
   * Refreshes the blockhash for a set of versioned transactions.
   * @param vTxs - Array of versioned transactions to update.
   * @param connection - Optional Solana connection instance.
   * @returns Object containing updated transactions and blockheight.
   */
  const refreshVTxBlockhash = async (vTxs: VersionedTransaction[], connection?: Connection) => {
    if (!connection) connection = new Connection(solanaAuth.network)
    let getLatestBlockhash = await connection.getLatestBlockhash()
    vTxs.map((vTx) => (vTx.message.recentBlockhash = getLatestBlockhash.blockhash))
    return { vTxs, blockheight: getLatestBlockhash.lastValidBlockHeight }
  }

  /**
   * Signs a bundle of transactions.
   * @param Data - Array of bundle transactions to sign.
   * @param connection - Optional Solana connection instance.
   * @param is_simulate - Optional flag to simulate transactions instead of sending.
   * @returns Promise resolving to an array of signed bundle transactions.
   */
  const signTransactions = async (
    Data: nCT.BundleTransaction[],
    connection?: Connection,
    is_simulate?: boolean
  ): Promise<nCT.BundleTransaction[]> => {
    if (!!!connection) connection = new Connection(solanaAuth.network)
    if (!solanaAuth.connected) await solanaAuth.signIn()
    if (!solanaAuth.publicKey) throw 'not connected'

    if (!isConnectedWalletSigner(Data))
      throw (
        'Connected wallet is not the signer. Please switch wallet to ' +
        solanaAuth.publicKey.toString() +
        ' to sign the transaction'
      )

    if (!solanaAuth.signAllTransactions) throw 'wallet error: no signAllTransactions'

    let vData = Data.map((tx) => {
      if (!isVersionedTransaction(tx.tx)) tx.tx = new VersionedTransaction(tx.tx.compileMessage())
      return tx as BTv
    })
    let { vTxs: txs, blockheight } = await refreshVTxBlockhash(
      vData.map((tx) => tx.tx),
      connection
    )
    let simu = await connection!.simulateTransaction(vData[0].tx!)
    console.log('simu', simu.value.err)
    if (simu.value.err) simu.value.logs?.map(v=>console.log(v))
      let signed = await solanaAuth.signAllTransactions(txs)
    signed.forEach((stx, i) => {
      vData[i].stx = stx
      vData[i].hash = bs58.encode(stx.signatures[0])
    })

    return await Promise.all(
      vData.map(async (txData, i) => {
        await sleep(i * 150)
        txData.blockheight = blockheight
        try {
          if (is_simulate) {
            let simu = await connection!.simulateTransaction(txData.stx!)
            txData.status = 'broadcast'
            console.log('simu', simu)
            if (simu.value.err) simu.value.logs?.map(console.log)
          } else {
            let hash = await connection!.sendTransaction(txData.stx!,{skipPreflight:true})
            console.log(txData.description, 'hash', hash)

            txData.status = 'broadcast'
            txData.hash = hash
          }

          // Data[i].hash = hash;
        } catch (error) {
          console.log('error',error);
          txData.status = 'failed'
          txData.failedReason = 'failed to send' + JSON.stringify(error)
        }
        return txData
      })
    )
  }

  /**
   * Checks the status of a set of transactions.
   * @param Data - Array of bundle transactions to check.
   * @param connection - Optional Solana connection instance.
   * @param rebroadcast - Optional flag to rebroadcast unconfirmed transactions.
   * @param commitment - Optional commitment level for transaction confirmation.
   * @returns Promise resolving to an array of updated bundle transactions.
   */
  const checkTransactions = async (
    Data: nCT.BundleTransaction[],
    connection?: Connection,
    rebroadcast?: boolean,
    commitment?: 'finalized' | 'confirmed' | 'dynamic'
  ): Promise<nCT.BundleTransaction[]> => {
    if (!connection) connection = new Connection(solanaAuth.network)

    let hashesToConfirm = Data.filter((tx) => tx.status === 'broadcast' && !!tx.hash).map(
      (tx) => tx.hash!
    )

    if (hashesToConfirm.length == 0) return Data
    let hasSubsequentTxs = Data.some((tx) => tx.status === 'pending')
    if (commitment == 'dynamic' || commitment == undefined)
      commitment = hasSubsequentTxs ? 'finalized' : 'confirmed'

    await connection
      .getTransactions(hashesToConfirm, {
        commitment,
        maxSupportedTransactionVersion: 0,
      })
      .then((resu) =>
        resu.map((tx) => {
          if (tx) {
            let hash = tx!.transaction.signatures[0]
            let txIndex = Data.findIndex((t) => t.hash === hash)

            if (txIndex != -1) {
              if (!!tx?.meta?.err) {
                Data[txIndex].status = 'failed'
                Data[txIndex].failedReason = JSON.stringify(tx.meta.logMessages) /// to be improved
              } else Data[txIndex].status = 'success'

              hashesToConfirm = hashesToConfirm.filter((h) => h !== hash)
            } else console.log('txIndex not found', Data, hash, txIndex)
          } else console.log('tx not found', tx)
        })
      )

    if (hashesToConfirm.length > 0) {
      let latestBheight = (await connection.getLatestBlockhash()).lastValidBlockHeight
      await Promise.all(
        hashesToConfirm.map(async (hash) => {
          let txIndex = Data.findIndex((t) => t.hash === hash)
          let txBcht = Data[txIndex].blockheight
          if (txBcht && latestBheight > txBcht + 151) {
            console.log(latestBheight, ' > ', txBcht + 151, 'unconfirmed failed', hash)
            Data[txIndex].status = 'Timeout'
          } else {
            console.log('too early for unconfirmed', hash)
            if (rebroadcast)
              console.log(
                'rebroadcasted',
                await connection!
                  //@ts-ignore
                  .sendTransaction(Data[txIndex].stx!)
                  .catch((error) => console.log('error rebroadcasting', error))
              )
          }
        })
      )
    }

    return Data
  }

  /**
   * Checks if the connected wallet is the signer for all transactions.
   * @param txs - Array of bundle transactions to check.
   * @returns Boolean indicating if the connected wallet is the signer for all transactions.
   */
  const isConnectedWalletSigner = (txs: nCT.BundleTransaction[]): boolean => {
    let user = solanaAuth.publicKey!

    let isSignerArray = txs.map((btx, i) => {
      if (isVersionedTransaction(btx.tx)) {
        let signerIndex = btx.tx.message.staticAccountKeys.findIndex((k) => k.equals(user))

        if (btx.tx.message.isAccountSigner(signerIndex)) return true
        console.log(
          'not signer vTX',
          i,
          'feesigner',
          btx.tx.message.getAccountKeys().get(i),
          'user',
          user.toString()
        )
      } else {
        if (btx.tx.feePayer && btx.tx.feePayer.equals(user)) return true
        console.log(
          'not signer Tx',
          i,
          'feepayer',
          btx.tx.feePayer?.toString(),
          'user',
          user.toString()
        )
      }
      if (window?.solana?.publicKey)
        console.log('window.solana.publicKey', window.solana.publicKey.toString())

      return false
    })
    return !isSignerArray.some((isSigner) => isSigner == false)
  }
  return {
    ...solanaAuth,

    /// COLLECTION SWAP
    signTransactions,
    checkTransactions,
  }
}
