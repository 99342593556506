import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
} from 'react'

import { useSearchParams } from 'react-router-dom'
import { useAppContext } from './appContext'
import useSwaps, { UseSwapsType } from '../hooks/useSwaps'
import useTransactions, { UseTransactionsType } from '../hooks/useTransactions'

import { SwapData, CollectionData } from '../types/collectionSwapV2'
import { Nft } from '../types/nft'

const REFRESH_RATE_INTERVAL = 300000

type Points = {
  pointsPerActiveBid: number[]
  pointsPerTakerAcceptedSwap: number
  pointsPerMakerAcceptedSwap: number
  pointsProfileCompletion: number
  pointsFirstConnection: number
  pointsBackpackWallet: number
  pointsPerPetttingCat: number
  pointsCompletingSurvey: number
  pointsChatbotSurvey: number
  pointsTwitter: number
  points3DaysStreak: number
  points8DaysStreak: number
  points13DaysStreak: number
  points20DaysStreak: number
  points27DaysStreak: number
}
// Define the context shape
interface CollectionSwapsContextType extends UseSwapsType, UseTransactionsType {
  isLoading: boolean
  
  selectedTraitCollectionSlug: string | null
  setSelectedTraitCollectionSlug: (slug: string | null) => void
  selectedTraitCollection: CollectionData | undefined

  selectBidId: (bidId: string | null) => void
  selectedBidId: string | null
  setSelectedBid: (bid: SwapData | undefined) => void
  selectedBid: SwapData | undefined

  giveNfts: Nft[]
  setGiveNfts: (nfts: Nft[]) => void

  handleCreateSwaps: (bids: SwapData[], mode: 'collection' | 'trait') => void
  setBidsToCreate: (bids: SwapData[] | undefined) => void
  bidsToCreate: SwapData[] | undefined

  solToUsd: number | undefined

  waveFees: boolean

  isCreateModalOpen: boolean
  setIsCreateModalOpen: (isOpen: boolean) => void

  createModalStep: number
  setCreateModalStep: (step: number) => void

  modalMode: 'collection' | 'trait'
  setModalMode: (mode: 'collection' | 'trait') => void

  onModalClose: () => void

  shouldRefresh: boolean
  setShouldRefresh: (shouldRefresh: boolean) => void
  pointsConfiguration: Points
  calculatedPointsConfiguration: Points

  setActiveTab: (tab: 'swap' | 'explore') => void
  activeTab: 'swap' | 'explore'
}

// Create the context
const CollectionSwapsContext = createContext<CollectionSwapsContextType | undefined>(undefined)

// Create a provider component
export const CollectionSwapsProvider = ({ children }: { children: ReactNode }) => {
  const {
    uid,
    getUserNfts,
    getTokenBalance,
    getRewards,
    pointsMultipliers,
    rewards,
    rewardsWBonus,
  } = useAppContext()

  const swaps = useSwaps()
  const {
    collectionsData,
    onChainOffers,
    onChainTraitOffers,
    refreshOnChainSwaps,
    isLoadingOnChainSwaps,
  } = swaps

  const isLoading = isLoadingOnChainSwaps

  const [selectedTraitCollectionSlug, setSelectedTraitCollectionSlug] = useState<string | null>(null)
  const selectedTraitCollection = useMemo(() => {
    return collectionsData.find((collection) => collection.slug === selectedTraitCollectionSlug)
  }, [collectionsData, selectedTraitCollectionSlug])

  const [giveNfts, setGiveNfts] = useState<Nft[]>([])

  const [selectedBidId, setSelectedBidId] = useState<string | null>(null)
  const [selectedBid, setSelectedBid] = useState<SwapData | undefined>(undefined)

  const [bidsToCreate, setBidsToCreate] = useState<SwapData[] | undefined>(undefined)

  const [solToUsd, setSolToUsd] = useState<number | undefined>(undefined)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [createModalStep, setCreateModalStep] = useState(-1)
  const [modalMode, setModalMode] = useState<'collection' | 'trait'>('collection')

  const [activeTab, setActiveTab] = useState<'swap' | 'explore'>('swap')

  const [searchParams, setSearchParams] = useSearchParams()

  const [shouldRefresh, setShouldRefresh] = useState(false)

  useEffect(() => {
    getRewards()
  }, [uid])

  const waveFees = useMemo(() => {
    return pointsMultipliers && pointsMultipliers.length > 0 ? true : false
  }, [pointsMultipliers])

  const pointsConfiguration = useMemo(() => {
    return {
      pointsPerActiveBid: rewards ? rewards?.nBidsPoints.map((x) => Math.round(x)) : [],
      pointsPerTakerAcceptedSwap: rewards ? Math.round(rewards.makeSwapPoints) : 0,
      pointsPerMakerAcceptedSwap: rewards ? Math.round(rewards.takeSwapPoints) : 0,
      pointsProfileCompletion: rewards ? Math.round(rewards.profileCompletePoints) : 0,
      pointsFirstConnection: rewards ? Math.round(rewards.firstTimeConnectPoints) : 0,
      pointsBackpackWallet: rewards ? Math.round(rewards.backpackConnectPoints) : 0,
      pointsPerPetttingCat: rewards ? Math.round(rewards.petCatPoints) : 0,
      pointsCompletingSurvey: rewards ? Math.round(rewards.typeformSurveyPoints) : 0,
      pointsChatbotSurvey: rewards ? Math.round(rewards.chatbotSurveyPoints) : 0,
      pointsTwitter: 0,
      points3DaysStreak: rewards ? Math.round(rewards.streakPoints.day3) : 0,
      points8DaysStreak: rewards ? Math.round(rewards.streakPoints.day8) : 0,
      points13DaysStreak: rewards ? Math.round(rewards.streakPoints.day13) : 0,
      points20DaysStreak: rewards ? Math.round(rewards.streakPoints.day20) : 0,
      points27DaysStreak: rewards ? Math.round(rewards.streakPoints.day27) : 0,
    }
  }, [rewards])

  const calculatedPointsConfiguration = useMemo(() => {
    return {
      pointsPerActiveBid: rewardsWBonus ? rewardsWBonus?.nBidsPoints.map((x) => Math.round(x)) : [],
      pointsPerTakerAcceptedSwap: rewardsWBonus ? Math.round(rewardsWBonus.makeSwapPoints) : 0,
      pointsPerMakerAcceptedSwap: rewardsWBonus ? Math.round(rewardsWBonus.takeSwapPoints) : 0,
      pointsProfileCompletion: rewardsWBonus ? Math.round(rewardsWBonus.profileCompletePoints) : 0,
      pointsFirstConnection: rewardsWBonus ? Math.round(rewardsWBonus.firstTimeConnectPoints) : 0,
      pointsBackpackWallet: rewardsWBonus ? Math.round(rewardsWBonus.backpackConnectPoints) : 0,
      pointsPerPetttingCat: rewardsWBonus ? Math.round(rewardsWBonus.petCatPoints) : 0,
      pointsCompletingSurvey: rewardsWBonus ? Math.round(rewardsWBonus.typeformSurveyPoints) : 0,
      pointsChatbotSurvey: rewardsWBonus ? Math.round(rewardsWBonus.chatbotSurveyPoints) : 0,
      pointsTwitter: 0,
      points3DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day3) : 0,
      points8DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day8) : 0,
      points13DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day13) : 0,
      points20DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day20) : 0,
      points27DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day27) : 0,
    }
  }, [rewardsWBonus])

  useEffect(() => {
    const fetchSolToUsd = async () => {
      try {
        const url = 'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'

        const response = await fetch(url)
        const data = await response.json()

        setSolToUsd(data.solana.usd)
      } catch (error) {
        console.log('error', error)
      }
    }

    // refresh every 5 minutes
    const interval = setInterval(() => {
      fetchSolToUsd()
    }, REFRESH_RATE_INTERVAL)

    fetchSolToUsd()

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  useEffect(() => {
    if (bidsToCreate && giveNfts.length === 1) {
      bidsToCreate.forEach((bid) => {
        bid.makerNft = giveNfts[0]
      })
    }
  }, [bidsToCreate, giveNfts])

  const handleCreateSwaps = (bids: SwapData[], mode: 'collection' | 'trait') => {
    console.log('bids', bids)
    bids = bids.filter((bid) => !bid.isOnChain)
    setBidsToCreate(bids)
    setModalMode(mode)
    if (mode === 'trait') {
      setCreateModalStep(0)
    } else {
      setCreateModalStep(-1)
    }
    setIsCreateModalOpen(true)
  }

  const selectBidId = (bidId: string | null) => {
    if (bidId) {
      setSearchParams({ bidId: bidId })
    } else {
      setSearchParams({})
    }
    setSelectedBidId(bidId)
  }

  useEffect(() => {
    if (!selectedBidId) {
      return
    }

    let newSelectedBid = onChainOffers.find((offer) => offer.bidId === selectedBidId)

    if (!newSelectedBid) {
      newSelectedBid = onChainTraitOffers.find((offer) => offer.bidId === selectedBidId)
    }

    if (newSelectedBid) {
      setSelectedBid(newSelectedBid)
    } else {
      console.warn(
        `Selected bid with ID ${selectedBidId} not found in onChainOffers or onChainTraitOffers`
      )
    }
  }, [selectedBidId, onChainOffers, onChainTraitOffers])

  const isSwapTraitBid = useMemo(() => {
    return selectedBid && 'traitId' in selectedBid.takerCollection
  }, [selectedBid])

  const transactions = useTransactions({
    selectedBid,
    bidsToCreate,
    isSwapTraitBid,
    waveFees,
  })

  const onModalClose = () => {
    setModalMode('collection')
    setSelectedBidId(null)
    setSelectedBid(undefined)
    transactions.setTransactions([])
    setIsCreateModalOpen(false)
    setSearchParams({})
    if (shouldRefresh) {
      setShouldRefresh(false)
      refreshOnChainSwaps()
      if (uid) {
        getTokenBalance(uid)
        getUserNfts(uid)
      }
    }
  }

  return (
    <CollectionSwapsContext.Provider
      value={{
        ...swaps,
        ...transactions,

        isLoading,

        selectedTraitCollectionSlug,
        setSelectedTraitCollectionSlug,
        selectedTraitCollection,

        selectBidId,
        selectedBidId,
        setSelectedBid,
        selectedBid,

        giveNfts,
        setGiveNfts,

        handleCreateSwaps,
        setBidsToCreate,
        bidsToCreate,

        solToUsd,
        waveFees,
        pointsConfiguration,
        calculatedPointsConfiguration,


        isCreateModalOpen,
        setIsCreateModalOpen,
        createModalStep,
        setCreateModalStep,
        modalMode,
        setModalMode,
        onModalClose,

        shouldRefresh,
        setShouldRefresh,
        setActiveTab,
        activeTab,
      }}
    >
      {children}
    </CollectionSwapsContext.Provider>
  )
}

// Hook to use the context
export const useCollectionSwaps = () => {
  const context = useContext(CollectionSwapsContext)
  if (context === undefined) {
    throw new Error('useCollectionSwaps must be used within a CollectionSwapsProvider')
  }
  return context
}
