import { useMemo } from 'react';
import {
    Text,
    Box
} from '@chakra-ui/react'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext';
import { floorPriceWithFees } from '../../../utils/price';

export const InCollectionSwapHeader = () => {
    const {
        selectedTraitCollection,
        getTraitOnChainOffers,
    } = useCollectionSwaps();

    const offerCount = useMemo(() => {
        let traitOffers = getTraitOnChainOffers({
            makerCollectionIds: [selectedTraitCollection?.onChainId || ''],
        })
        return traitOffers.length
    }, [getTraitOnChainOffers, selectedTraitCollection])

    return (
        <Box className="w-full max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center rounded-[30px] sm:rounded-full sm:p-4 mb-2 text-xs sm:text-sm new-bg-transparent text-white transition-all duration-200 shadow-md" px={3} py={2}>
            {!selectedTraitCollection?.imageUrl ? (
                <Text fontSize="large" fontWeight="bold" color="gray.700">No collection to display</Text>
            ) : (
                <>
                    <div className="flex items-center mb-4 sm:mb-0 w-full justify-center sm:justify-start">
                        <img
                            src={selectedTraitCollection!.imageUrl}
                            alt={selectedTraitCollection!.slug}
                            className="w-16 h-16 object-cover rounded-full shadow-sm mr-4"
                        />
                        <h2 className="font-bold text-xl">{selectedTraitCollection?.collectionName}</h2>
                    </div>

                    <div className="flex flex-row sm:flex-row items-stretch sm:items-center justify-center sm:justify-end space-x-12 w-full sm:px-6">

                        <Box textAlign="center" mb={{ base: 0, md: 0 }}>
                            <Text className="text-sm sm:text-md font-semibold whitespace-nowrap" px={5}>Floor</Text>
                            <Box className="potato-radius new-bg-transparent py-1 sm:py-2 mt-1 sm:mt-2" px={5} mb={2}>
                                <Text className="text-lg sm:text-xl md:text-2xl font-bold">{(floorPriceWithFees(selectedTraitCollection) / 10 ** 9).toFixed(2)} SOL</Text>
                            </Box>
                        </Box>

                        <Box textAlign="center" mb={{ base: 0, md: 0 }} mr={{ base: 14, md: 20 }}>
                            <Text className="text-sm sm:text-md font-semibold whitespace-nowrap" px={5}>Live swaps</Text>
                            <Box className="potato-radius new-bg-transparent py-1 sm:py-2 mt-1 sm:mt-2" px={5} mb={2}>
                                <Text className="text-lg sm:text-xl md:text-2xl font-bold">{offerCount}</Text>
                            </Box>
                        </Box>
                    </div>
                </>
            )
            }
        </Box >
    )
}