import {
  collection,
  doc,
  FieldPath,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  query,
  where,
  Timestamp,
  onSnapshot,
  getCountFromServer,
} from 'firebase/firestore'
import { FirestoreUser } from 'ns-types'
import Firebase from './firebase'
import { Agreement, Email, FbUser } from '../contexts/appContext'
import { httpsCallable } from 'firebase/functions'
import {
  LeaderboardUserPoints,
  PointsConfig,
  PointsMultiplier,
  UserPoints,
} from '../types/collectionSwapV2'

export const updateUser = async (uid: string, data: Partial<FirestoreUser>) => {
  console.log('updateUser', uid, data)
  const ref = doc(Firebase.getDBApp(), 'users', uid)
  return updateDoc(ref, data as FieldPath, { merge: true })
}

export const getUser = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid)
  return getDoc(ref)
}

export const getChainInfo = async (chain: string) => {
  const ref = doc(Firebase.getDBApp(), 'blockchain', chain)
  return getDoc(ref)
}

export const getChains = async () => {
  const ref = query(collection(Firebase.getDBApp(), 'blockchain'), where('disabled', '==', false))
  return getDocs(ref)
}

export const getWaitlistUser = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'waitlist', uid)
  return getDoc(ref)
}

export const getNotificationCollectionSwap = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'private', 'notificationCollectionSwap')
  return getDoc(ref)
}

export const setNotificationCollectionSwap = async (uid: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'private', 'notificationCollectionSwap')
  return setDoc(ref, data, { merge: true })
}

export const addToWaitList = async (
  chain: string,
  address: string,
  email: string,
  heardFrom: string
) => {
  const ref = doc(Firebase.getDBApp(), 'waitlist', `${chain}-${address}`)
  return setDoc(ref, { address, email, heardFrom, allowed: false }, { merge: true })
}

export const getLeaderboardPoints = async (uid: string): Promise<LeaderboardUserPoints> => {
  try {
    console.log('getLeaderboardPoints', uid)

    const ref = doc(Firebase.getDBApp(), 'pointsLeaderboard', uid)
    const docSnap = await getDoc(ref)

    if (!docSnap.exists()) {
      throw new Error(`No leaderboard points found for user ID: ${uid}`)
    }

    let data = docSnap.data() as LeaderboardUserPoints

    const refCollection = collection(Firebase.getDBApp(), 'pointsLeaderboard')
    const refHigherRank = query(refCollection, where('points', '>', data.points || 0))
    const [totalUsers, higherUsers] = await Promise.all([
      getCountFromServer(refCollection),
      getCountFromServer(refHigherRank),
    ])

    data.rank = higherUsers.data().count + 1
    data.totalUsers = totalUsers.data().count

    return data
  } catch (error) {
    console.error('Error getting leaderboard points:', error)
    throw error
  }
}

export const getEmail = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'email')
  return getDoc(ref)
}

export const getTelegram = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'telegram')
  return getDoc(ref)
}

export const getDiscord = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'discord')
  return getDoc(ref)
}

export const getTwitter = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'twitter')
  return getDoc(ref)
}

export const updateTerms = async (uid: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), `users`, uid!, 'private', 'info')
  return setDoc(ref, data, { merge: true })
}

export const updateInfo = async (uid: string, service: string, data: any) => {
  const ref = doc(Firebase.getDBApp(), `users`, uid, 'contact', service)
  return setDoc(ref, data, { merge: true })
}

export const addToVerified = async (email: any) => {
  const ref = doc(Firebase.getDBApp(), 'verifedEmails', email)
  return setDoc(ref, { verified: true }, { merge: true })
}

export const getPointsMultiplier = async (uid: string) => {
  const ref = doc(Firebase.getDBApp(), 'pointsMultipliers', uid)
  const pointsMultiplierDoc = await getDoc(ref)
  let pointsMultipliers = Object.values(pointsMultiplierDoc.data() || {}) as PointsMultiplier[]
  return pointsMultipliers
}

export const getUserData = async (
  uid: string
): Promise<{
  user: FbUser
  agreements: Agreement
  email: Email
  pointsMultipliers: PointsMultiplier[]
}> => {
  console.log('UID', uid)
  const userRef = doc(Firebase.getDBApp(), 'users', uid)
  const agreementRef = doc(Firebase.getDBApp(), 'users', uid, 'private', 'info')
  const emailRef = doc(Firebase.getDBApp(), 'users', uid, 'contact', 'email')
  const pointsRef = doc(Firebase.getDBApp(), 'pointsMultipliers', uid)
  const [user, agreements, email, pointsMultipliersDoc] = await Promise.all([
    getDoc(userRef),
    getDoc(agreementRef),
    getDoc(emailRef),
    getDoc(pointsRef),
  ])

  let pointsMultipliers = Object.values(pointsMultipliersDoc.data() || {}) as PointsMultiplier[]

  return {
    user: user.data() as FbUser,
    agreements: agreements.data() as Agreement,
    email: email.data() as Email,
    pointsMultipliers: pointsMultipliers,
  }
}

export const getUserPoints = async (uid: string): Promise<UserPoints> => {
  console.log('getUserPoints', uid)
  const ref = doc(Firebase.getDBApp(), 'userPoints', uid)
  const points = await getDoc(ref)

  if (!points.exists()) {
    throw new Error(`No points found for user ID: ${uid}`)
  }

  return points.data() as UserPoints
}

export const generateCSSwapImage = async (
  swapData: {
    giveName: string
    giveCollectionName: string
    giveImage: string
    getName: string
    getCollectionName: string
    getImage: string
    makerNeoswapCost: number
    takerNeoswapCost: number
  },
  swapId: string
) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'cs-generateSwapImage')
  return await fn({ swapData, swapId })
}

export const getUserBalance = async (userId: string) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'nft-userBudget')
  return await fn({ userId })
}

export const getTwitterRedirectUrl = async () => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'auth-redirectToTwitterAuthLink')
  return await fn()
}

export const callTwitterConnectCallback = async (oauth_token: string, oauth_verifier: string) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'auth-twitterConnectCallback')
  return await fn({ oauth_token, oauth_verifier })
}

export const tweetWithOptionalImage = async (
  message: string,
  imageUrl?: string,
  swapImageName?: string
) => {
  const fn = httpsCallable(Firebase.getFnsApp(), 'twitter-tweetWithOptionalImage')
  const payload: { message: string; imageUrl?: string; swapImageName?: string } = { message }
  if (imageUrl) {
    payload.imageUrl = imageUrl
  }
  if (swapImageName) {
    payload.swapImageName = swapImageName
  }
  return await fn(payload)
}

export const petCat = async () => {
  try {
    const petCatFunction = httpsCallable(Firebase.getFnsApp(), 'points-petCat')
    await petCatFunction()
  } catch (error) {
    console.error('Error calling function:', error)
  }
}

export const checkProfileCompletion = async () => {
  try {
    const checkProfileCompletion = httpsCallable(
      Firebase.getFnsApp(),
      'points-checkProfileCompletion'
    )
    await checkProfileCompletion()
  } catch (error) {
    console.error('Error calling function:', error)
  }
}

export const refreshLastConnected = async (isBackpack?: boolean) => {
  try {
    const refreshLastConnected = httpsCallable(Firebase.getFnsApp(), 'points-refreshLastConnected')
    await refreshLastConnected({ isBackpack: isBackpack })
  } catch (error) {
    console.error('Error calling function:', error)
  }
}

export const claimStreak = async (streakKey: string) => {
  try {
    const claimStreak = httpsCallable(Firebase.getFnsApp(), "points-claimStreak");
    const resp = await claimStreak({ streakKey: streakKey });
    let { success } = resp.data as { success: boolean, message: string }
    return success
  } catch (error) {
    console.error("Error calling function:", error);
    return false
  }
}

export const userRewards = async () => {
  try {
    const getUserRewards = httpsCallable(Firebase.getFnsApp(), 'points-userRewards')
    const rewards = (await getUserRewards()) as any
    return rewards.data as {
      rewards: PointsConfig
      rewardsWBonus: PointsConfig
      bonuses: PointsMultiplier
    }
  } catch (error) {
    console.error('Error calling function:', error)
  }
}

export const streamUserPoints = (uid: string, callback: (points: UserPoints) => void) => {
  const ref = doc(Firebase.getDBApp(), 'userPoints', uid)
  return onSnapshot(ref, (doc) => {
    const data = doc.data() as UserPoints
    callback(data)
  })
}
