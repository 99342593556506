import React, { useEffect, useState } from 'react'
import { getLeaderboardData } from '../../services/leaderboard'
import { LeaderboardUserPoints } from '../../types/collectionSwapV2'
import { Avatar } from '@chakra-ui/react'
import { useAppContext } from '../../contexts/appContext'
import { FaMedal, FaTrophy, FaAward } from 'react-icons/fa'

interface LeaderboardTableProps { }

const LeaderboardTable: React.FC<LeaderboardTableProps> = () => {
  const { uid } = useAppContext()
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardUserPoints[]>([])

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const data = await getLeaderboardData()
        setLeaderboardData(data)
      } catch (error) {
        console.error('Error fetching leaderboard data:', error)
      }
    }

    fetchLeaderboardData()
  }, [])


  const getBadgeIcon = (index: number) => {
    switch (index) {
      case 0: return <FaTrophy className="text-yellow-400 text-2xl  animate-bounce" />
      case 1: return <FaMedal className="text-green-400 text-xl" />
      case 2: return <FaAward className="text-blue-400 text-xl" />
      default: return null
    }
  }

  const getRowStyle = (index: number) => {
    switch (index) {
      case 0: return 'bg-gradient-to-r from-[#fc822f] to-[#d9b892] text-white shadow-md transform scale-105'
      case 1: return 'bg-gradient-to-r from-[#FF9393] to-[#FF4ADA] text-white shadow-sm'
      case 2: return 'bg-gradient-to-r from-[#99d6e9] to-[#FFE0F2] text-gray-800 shadow-sm'
      default: return ' new-bg-transparent  text-white transition-colors duration-200'
    }
  }
  return (
    <div className="w-full potato-radius new-bg-transparent  shadow-lg p-2 sm:p-4 mb-10">

      {/* Header row */}
      <div className="flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 archivo-black text-white text-xs sm:text-sm  ">
        <div className="flex items-center flex-1">
          <span className="w-10 sm:w-12">Rank</span>
          <span className="ml-2 sm:ml-12">User</span>
        </div>
        <div className="flex items-center">
          <span className="w-14 sm:w-24 text-center">Swaps</span>
          <span className="w-20 sm:w-24 text-right">Potatoes</span>
        </div>
      </div>

      {/* Leaderboard entries */}
      {leaderboardData.map((user, index) => {
        const isCurrentUser = user.uid === uid
        return (
          <div
            key={user.uid}
            className={`flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius ${isCurrentUser ? 'bg-[#FFE0F2] text-pink-800 shadow-md transform scale-105' : getRowStyle(index)
              } transition-all duration-200`}
          >
            <div className="flex items-center flex-1 min-w-0">
              <span className="w-8 sm:w-12 text-base font-semibold flex items-center justify-center">
                {getBadgeIcon(index) || index + 1}
              </span>
              <Avatar src={user.image} size="sm" mr={2} />
              <span className="font-medium truncate">{user.name}</span>
            </div>
            <div className="flex items-center">
              <span className="w-14 sm:w-24 text-center">{user.swaps}</span>
              <span className="archivo-black w-20 sm:w-24 text-right whitespace-nowrap">🥔 {Math.round(user.points || 0)}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default LeaderboardTable