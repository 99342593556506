import { SwapData, SortConfig } from '../types/collectionSwapV2'

const compare = (a: any, b: any, direction: 'asc' | 'desc') => {
  if (a < b) return direction === 'asc' ? -1 : 1
  if (a > b) return direction === 'asc' ? 1 : -1
  return 0
}

const belongToUser = (swap: SwapData, sortConfig: SortConfig) => {
  if(!sortConfig.uid) return false

  return swap.onChainSwap?.data.maker === sortConfig.uid?.split("-")[1]
}

export function sort(offers: SwapData[], sortConfig?: SortConfig): SwapData[] {
  if (sortConfig) {
    if (sortConfig.sortBy === 'time') {
      offers.sort((a, b) =>
        compare(a.onChainSwap?.data.endTime, b.onChainSwap?.data.endTime, sortConfig.sortDirection)
      )
    } else if (sortConfig.sortBy === 'savings') {
      offers.sort((a, b) => {
        const aSavings = (a.isOnChain && !belongToUser(a, sortConfig))?  a.takerSwapSavings.savings : a.makerSwapSavings.savings
        const bSavings = (b.isOnChain && !belongToUser(a, sortConfig))?  b.takerSwapSavings.savings : b.makerSwapSavings.savings
        return compare( aSavings, bSavings, sortConfig.sortDirection)
      })
    }
  }

  return offers
}
