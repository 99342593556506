import { ReactNode, useState, createContext, useMemo } from 'react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { clusterApiUrl } from '@solana/web3.js'
import {
  // BraveWalletAdapter,
  CoinbaseWalletAdapter,
  // SolflareWalletAdapter,
  // LedgerWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'

require('@solana/wallet-adapter-react-ui/styles.css')

const network = process.env.REACT_APP_SOLANA_NETWORK
if (!network) throw new Error('Solana network must be set!')

// https://github.com/GtpsFinance/gtpsfinance-dex-interface/blob/c6a45f378b39e3a86d061ad0da1698783c242a30/src/components/Web3Provider/index.tsx

type ConnectionState =
  | 'notConnected'
  | 'connecting'
  | 'connected'
  | 'aborted'
  | 'initiateAuth'
  | 'authInProgress'

interface WalletContextData {
  isConnected: boolean
  setIsConnected: (open: boolean) => void
  isAuthenticating: boolean
  setIsAuthenticating: (open: boolean) => void
  connectionState: ConnectionState
  setConnectionState: React.Dispatch<React.SetStateAction<ConnectionState>>
}

const defaultWalletContextData: WalletContextData = {
  isConnected: false,
  setIsConnected: () => {}, // Provide a default no-op function
  isAuthenticating: false,
  setIsAuthenticating: () => {}, // Provide a default no-op function
  connectionState: 'notConnected',
  setConnectionState: () => {}, // Provide a default no-op function
}

export const WalletContext = createContext<WalletContextData>(defaultWalletContextData)

interface Props {
  children: ReactNode
}

export default function NeoSwapWalletProvider({ children }: Props) {
  const network = WalletAdapterNetwork.Mainnet
  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network])

  const wallets = useMemo(
    () => [
      // new SolflareWalletAdapter(),
      // new BraveWalletAdapter(),
      new CoinbaseWalletAdapter(),
      // new LedgerWalletAdapter(),
    ],
    [network]
  )

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
