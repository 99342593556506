import NftItem from './NftItem'
import { formatSignificantDigits, getPotateosForBids } from '../../utils'
import { SwapOverview } from '../../types/collectionSwapV2'
import { useCallback } from 'react'
import { Box, Text, Spinner, Button } from '@chakra-ui/react'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext'
import CustomTooltip from './CustomTooltip'

const SHOW_POTATEOS = true

export default function MobileSwapCard({
  giveItem,
  getItem,
  userSavings,
  isOnChain,
  isMaker,
  onSelectSwap,
  onCreateSwaps,
  onView,
  showView,
}: SwapOverview & {
  onSelectSwap: () => void
  onCreateSwaps: () => void
  onView?: () => void
  showView?: boolean
}) {


  const handleCardClick = useCallback(() => {
    if (showView && onView) {
      onView()
    } else if (isOnChain) {
      onSelectSwap()
    } else {
      onCreateSwaps()
    }
  }, [showView, onView, isOnChain, onSelectSwap, onCreateSwaps])

  return (
    <div
      onClick={handleCardClick}
      className={`mt-1 w-full text-dark-potato mobile-swap-bg justify-between ${isOnChain
        ? 'border-dark-potato'
        : 'border-dark-potato-dashed'
        } new-bg-transparent potato-radius-sm px-2 py-2 flex 
    items-center gap-2 w-fit md:w-full`}
    >
      <NftItem direction="Give" {...giveItem} />

      {userSavings.swapCost > 0 && (
        <>
          <span>+</span>
          <div className="   flex items-center justify-center rounded-md">
            <span className="archivo-black text-center text-sm">
              {formatSignificantDigits(Math.abs(userSavings.swapCost), 2)}
            </span>
          </div>
        </>
      )}

      <div>
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 16L0 11L5 6L6.4 7.425L3.825 10H11V12H3.825L6.4 14.575L5 16ZM15 10L13.6 8.575L16.175 6H9V4H16.175L13.6 1.425L15 0L20 5L15 10Z"
            fill="#444444"
          />
        </svg>
      </div>

      {userSavings.swapCost < 0 && (
        <>
          <div className=" flex items-center justify-center rounded-md">
            <span className="archivo-black text-center text-sm">
              {formatSignificantDigits(Math.abs(userSavings.swapCost), 2)}
            </span>
          </div>
          <span>+</span>
        </>
      )}

      <NftItem direction="Get" {...getItem} />

      <div>
        <div className="text-center" style={{ fontSize: '10px' }}>
          Savings
        </div>

        <div>
          <span
            className="text-strongGreen no-shadow py-1  text-xs archivo-black text-center w-full block"
            style={{ marginTop: '5px' }}
          >
            {formatSignificantDigits(userSavings.savings, 2)} <br></br>SOL
          </span>
        </div>
      </div>

      {showView && onView ? (
        <Button
          className="primary-potato-btn p-1 px-4 text-xs potato-radius"
          size={{ base: 'xs', md: 'xs' }}
          onClick={handleCardClick}
        >
          View
        </Button>
      ) : isOnChain && isMaker ? (
        <Button
          className="secondary-potato-btn p-1 px-4 text-xs potato-radius"
          size={{ base: 'xs', md: 'xs' }}
          onClick={handleCardClick}
        >
          Cancel
        </Button>
      ) : isOnChain && !isMaker ? (
        <Button
          className="primary-potato-btn p-1 px-4 text-xs potato-radius"
          size={{ base: 'xs', md: 'xs' }}
          onClick={handleCardClick}
        >
          Accept
        </Button>
      ) : (
        <Button
          className="secondary-potato-btn p-1 px-4 text-xs potato-radius"
          size={{ base: 'xs', md: 'xs' }}
          onClick={handleCardClick}
        >
          Create
        </Button>
      )}
    </div>
  )
}

export const MobileSwapCardWithPotateos = ({
  swapOverview,
  onSelectSwap,
  onCreateSwaps,
}: {
  swapOverview: SwapOverview;
  onSelectSwap: () => void;
  onCreateSwaps: () => void;
}) => {

  const { calculatedPointsConfiguration } = useCollectionSwaps();

  const isLoading = !calculatedPointsConfiguration || !calculatedPointsConfiguration.pointsPerActiveBid || !calculatedPointsConfiguration.pointsPerTakerAcceptedSwap;

  return (
    <Box position="relative">
      <MobileSwapCard
        key={swapOverview.bidId}
        {...swapOverview}
        onSelectSwap={onSelectSwap}
        onCreateSwaps={onCreateSwaps}
      />
      {(SHOW_POTATEOS && !(swapOverview.isOnChain && swapOverview.isMaker)) && (
        <Box
          position="absolute"
          top="-10px"
          right="-5px"
          bg="white"
          border="1px solid #FC822F"
          borderRadius="full"
          px={0.5}
          py={0.5}
        >
          {isLoading ? (
            <Text fontSize="sm" fontWeight="medium" color="gray.700">
              <Spinner size="sm" color="gray.700" />{`🥔`}
            </Text>
          ) : swapOverview.isOnChain ? (
            <Text fontSize="sm" fontWeight="medium" color="gray.700">
              {`+ ${calculatedPointsConfiguration.pointsPerTakerAcceptedSwap} 🥔`}
            </Text>
          ) : (
            <CustomTooltip label={`Earn ${calculatedPointsConfiguration.pointsPerActiveBid[1]}  🥔  every day for an active bid and ${calculatedPointsConfiguration.pointsPerTakerAcceptedSwap} 🥔 when your swap is accepted`}>
              <Text fontSize="sm" fontWeight="medium" color="gray.700">
                {`+ ${calculatedPointsConfiguration.pointsPerTakerAcceptedSwap +
                  getPotateosForBids(1, calculatedPointsConfiguration.pointsPerActiveBid)
                  } 🥔`}
              </Text>
            </CustomTooltip>
          )}
        </Box>
      )}
    </Box>
  );
};
