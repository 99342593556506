import React from 'react'
import { Text, Box } from '@chakra-ui/react'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext';
import { getPotateosForBids } from '../../utils';



interface PointsProps {
    mode: "create" | "accept" | "proposal_accepted";
    bidsNumber?: number;
}

export const Points: React.FC<PointsProps> = ({ mode, bidsNumber }) => {
    const { calculatedPointsConfiguration } = useCollectionSwaps()
    let label
    if (!bidsNumber) bidsNumber = 1

    if (mode === "create") {
        let bidsText = bidsNumber > 1 ? `${bidsNumber} active bids` : "an active bid"
        label = `Earn ${getPotateosForBids(bidsNumber, calculatedPointsConfiguration.pointsPerActiveBid)} 🥔 Potatoes every day for ${bidsText}!`
    }
    else if (mode === "proposal_accepted") {
        label = `Earn ${calculatedPointsConfiguration.pointsPerMakerAcceptedSwap} 🥔 Potatoes when your swap is accepted!`
    }
    else {
        label = `Earn ${calculatedPointsConfiguration.pointsPerTakerAcceptedSwap} 🥔 Potatoes for accepting the swap!`
    }

    return (
        <Box
            className="info-alert my-4 potato-radius swap-ui"
            p={4}
            borderRadius="md"
            textAlign="center"
        >
            <Text className='font-medium text-sm'>{label}</Text>
        </Box>
    )
}
