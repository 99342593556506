import React from 'react'
import CustomTooltip from '../CustomTooltip'

export interface ActionButtonProps {
  onClick: () => void
  isDisabled?: boolean
  text: string
  tooltipActiveText?: string
  tooltipDisabledText?: string
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  isDisabled,
  text,
  tooltipActiveText,
  tooltipDisabledText,
}) => {
  let tooltipLabel = isDisabled ? tooltipDisabledText : tooltipActiveText
  let tooltipDisabled = !tooltipLabel

  return (
    <CustomTooltip label={tooltipLabel || ''} isDisabled={tooltipDisabled}>
      <button
        className="w-full p-2 p-2 potato-radius secondary-potato-btn archivo-black"
        onClick={onClick}
        disabled={isDisabled}
      >
        {text}
      </button>
    </CustomTooltip>
  )
}

export default ActionButton
