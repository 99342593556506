import React, { useMemo } from 'react'
import { useCollectionSwaps } from '../../../../contexts/CollectionSwapsContext'
import { Flex, Box, Image, Text } from '@chakra-ui/react'
import { useAppContext } from '../../../../contexts/appContext'
import SwapOfferCard from './SwapOfferCard'
import { SwapData, TraitCollectionData } from '../../../../types/collectionSwapV2'

type BestSwapsTableProps = {
    traitOffers: { trait: TraitCollectionData, offers: SwapData[] }[]
}

const BestSwapsTable: React.FC<BestSwapsTableProps> = ({ traitOffers }) => {
    const { uid } = useAppContext()
    const {
        giveNfts,
        selectBidId,
        handleCreateSwaps
    } = useCollectionSwaps()

    const handleCreateSwapAction = () => {
        let offers = traitOffers.flatMap(({ offers }) => offers)
        handleCreateSwaps(offers, 'trait')
    }

    return (
        <>
            <Flex justifyContent="center" width={{ base: '100%', md: '60%' }}>
                <Box width="100%" textAlign="left">
                    <Text fontSize="xx-large" fontWeight="bold" color="white" mt={4} mb={4}>
                        Best Swaps
                    </Text>

                    {traitOffers.map(({ trait, offers }) => {
                        return (
                            <Box key={trait.traitId} mb={{ base: 2, md: 4 }}>
                                <Flex
                                    alignItems="center"
                                    className='new-bg-transparent'
                                    // border="2px solid #a1a1a1"
                                    borderRadius="full"
                                    p={{ base: 1, md: 2 }}
                                    mb={{ base: 1, md: 2 }}
                                // bg="gray.70"
                                >
                                    <Image
                                        src={trait.image}
                                        alt={trait.traitValue}
                                        boxSize={{ base: '30px', md: '45px' }}
                                        borderRadius="full"
                                        mr={{ base: 1, md: 2 }}
                                        border="1px solid #a1a1a1"
                                    />
                                    <Text fontWeight="bold" color="white" fontSize={{ base: 'md', md: 'large' }}>
                                        {trait.traitKey}: {trait.traitValue}
                                    </Text>
                                </Flex>
                                {giveNfts.length > 0 && offers.map((offer) => (
                                    <SwapOfferCard
                                        key={offer.bidId || offer.traitId}
                                        offer={offer}
                                        giveNfts={giveNfts}
                                        uid={uid}
                                        handleCreateSwapAction={handleCreateSwapAction}
                                        selectBidId={selectBidId}
                                    />
                                ))}
                                {giveNfts.length === 0 && (
                                    <Text color="gray.500" fontSize="sm" mt={2}>
                                        No NFTs available for swap.
                                    </Text>
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Flex>
        </>
    )
}

export default BestSwapsTable
