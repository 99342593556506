import { SwapSavings } from '../../types/collectionSwapV2';
import { formatSignificantDigits } from "../../utils";
interface SurplusComponentProps {
  userSavings: SwapSavings;
  chainUsdValue: number | undefined;
  calculatedDate: string;
}

const SurplusComponent: React.FC<SurplusComponentProps> = function ({
  userSavings,
  chainUsdValue,
  calculatedDate
}) {
  const formatSol = (value: any) => {
    return `${!isNaN(parseFloat(value)) ? formatSignificantDigits(parseFloat(value), 2) : '0.00'} SOL`;
  };

  // console.log('chainUsdValue', chainUsdValue);

  const toUsdFormat = (value: any, chainUsdValue: any) => {
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    const usdValue = numericValue * (chainUsdValue || 0);
    return `~${formatSignificantDigits(Math.abs(usdValue), 2)} USD`;
  };

  const savingsUsd = toUsdFormat(userSavings.savings, chainUsdValue);
  const neoswapCostUsd = toUsdFormat(userSavings.swapCost, chainUsdValue);
  const marketplaceCostUsd = toUsdFormat(userSavings.marketplaceCost, chainUsdValue);

  const savings = formatSol(userSavings.savings);
  const neoswapCost = formatSol(userSavings.swapCost);
  const marketplaceCost = formatSol(userSavings.marketplaceCost);

  return (
    <div className='flex flex-col items-center justify-center gap-2 text-dark-potato'>
      <span className='text-center   text-base archivo-black'>Meow! You Save 💰</span>
      <span className='text-center text-green-600 text-5xl archivo-black'>{savings}</span>
      <span className='text-center  text-base font-normal'>{savingsUsd}</span>
      {calculatedDate.length > 0 && <span className='text-center t e text-xs' style={{ fontStyle: 'italic' }}>Calculated at {calculatedDate}</span>}
    </div>
  );
}

export default SurplusComponent;