import {
  SwapData,
  SwapOffer,
  CollectionData,
  SwapSavings,
  Bid,
  OnChainSwap,
} from '../types/collectionSwapV2'
import { Nft } from '../types/nft'

const NEOSWAP_FEE = 0.05
const MARKETPLACE_FEE = 0.015
const MAKER_SAVINGS_SHARE = 0.5

const SOL_DECIMALS = 9

export const calculateOffers = ({
  giveCollections,
  getCollections,
  waveMakerFees,
  waveTakerFees,
}: {
  giveCollections: CollectionData[]
  getCollections: CollectionData[]
  waveMakerFees?: boolean
  waveTakerFees?: boolean
}): SwapData[] => {
  const offers = []
  for (const giveCollection of giveCollections) {
    for (const getCollection of getCollections) {
      offers.push(calculateOffer({
        makerCollection: giveCollection,
        takerCollection: getCollection,
        waveMakerFees: waveMakerFees,
        waveTakerFees: waveTakerFees,
      }))
    }
  }
  return offers
}

export const calculateOffer = ({
  makerCollection,
  takerCollection,
  waveMakerFees,
  waveTakerFees,
}: {
  makerCollection: CollectionData
  takerCollection: CollectionData
  waveMakerFees?: boolean
  waveTakerFees?: boolean
}): SwapData => {
  // Calculate marketplace offers for both maker and taker
  const makerMarketplaceOffer = calculateMarketplaceOffer({
    sellCollection: makerCollection,
    buyCollection: takerCollection,
  })
  const takerMarketplaceOffer = calculateMarketplaceOffer({
    sellCollection: takerCollection,
    buyCollection: makerCollection,
  })

  // Calculate swap base cost for both maker and taker
  const makerBaseCost = calculateSwapBaseCost({
    sellCollection: makerCollection,
    buyCollection: takerCollection,
    savingsShare: MAKER_SAVINGS_SHARE,
  })
  const takerBaseCost = -makerBaseCost

  // Calculate swap offers for both maker and taker
  const makerSwapOffer = calculateSwapOffer({
    sellCollection: makerCollection,
    buyCollection: takerCollection,
    swapBaseCost: makerBaseCost,
  })

  const takerSwapOffer = calculateSwapOffer({
    sellCollection: takerCollection,
    buyCollection: makerCollection,
    swapBaseCost: takerBaseCost,
  })

  // Calculate savings for both maker and taker
  const makerSwapSavings = calculateSavings(makerMarketplaceOffer, makerSwapOffer)
  const takerSwapSavings = calculateSavings(takerMarketplaceOffer, takerSwapOffer)

  let swap: SwapData = {
    makerCollection,
    takerCollection,

    makerMarketplaceOffer,
    makerSwapOffer,
    makerSwapSavings,

    takerMarketplaceOffer,
    takerSwapOffer,
    takerSwapSavings,

    isOnChain: false,
  }
  // let isSameCollection = makerCollection.onChainId === takerCollection.onChainId
  // if (waveMakerFees || isSameCollection) swap = updateFees({ swapData: swap, makerFeeRate: 0 })
  // if (waveTakerFees || isSameCollection) swap = updateFees({ swapData: swap, takerFeeRate: 0 })

  if (waveMakerFees) swap = updateFees({ swapData: swap, makerFeeRate: 0 })
  if (waveTakerFees) swap = updateFees({ swapData: swap, takerFeeRate: 0 })

  return swap
}

const calculateSwapOffer = ({
  sellCollection,
  buyCollection,
  swapBaseCost,
  feeRate,
}: {
  sellCollection: CollectionData
  buyCollection: CollectionData
  swapBaseCost: number
  feeRate?: number
}): SwapOffer => {
  // User pays fees on the savings
  const marketTotalCost = calculateMarketplaceOffer({ sellCollection, buyCollection }).totalCost
  const savings = marketTotalCost - swapBaseCost
  if (feeRate === undefined) feeRate = NEOSWAP_FEE
  const fee = Math.ceil(savings * feeRate)

  // User pays royalties on buy collection based on absolute value of base cost weighted by buy collection value
  const sellValue = calculateItemValue(sellCollection)
  const buyValue = calculateItemValue(buyCollection)
  const royaltiesShare = buyValue / (sellValue + buyValue)
  const royaltiesRate = buyCollection.royaltyRate
  const royalties = Math.ceil(Math.abs(swapBaseCost) * royaltiesRate * royaltiesShare)

  const totalCost = swapBaseCost + fee + royalties

  const volume = totalCost + sellValue

  return {
    totalCost,
    baseCost: swapBaseCost,
    fee,
    feeRate,
    royalties,
    royaltiesRate,
    volume,
  }
}

const calculateOnChainMakerSwapOffer = ({ bid }: { bid: Bid }): SwapOffer => {
  const baseCost = -bid.amount
  const fee = bid.makerNeoswapFee
  const royalties = bid.makerRoyalties
  const totalCost = baseCost + fee + royalties

  return {
    totalCost,
    baseCost,
    fee,
    royalties,
  }
}

const calculateOnChainTakerSwapOffer = ({ bid }: { bid: Bid }): SwapOffer => {
  const baseCost = bid.amount
  const fee = bid.takerNeoswapFee
  const royalties = bid.takerRoyalties
  const totalCost = baseCost + fee + royalties

  return {
    totalCost,
    baseCost,
    fee,
    royalties,
  }
}

export const calculateOnChainOffer = ({
  onChainSwap,
  makerCollection,
  takerCollection,
  makerNft,
  takerNft,
  bid,
  waveMakerFees,
  waveTakerFees,
  roots,
}: {
  onChainSwap: OnChainSwap
  makerCollection: CollectionData
  takerCollection: CollectionData
  makerNft?: Nft
  takerNft?: Nft
  bid: Bid
  waveMakerFees?: boolean
  waveTakerFees?: boolean
  roots?: string[]
}): SwapData | undefined => {
  const bidId = `${onChainSwap.sda}-${bid.collection}`

  const onChainSwapWBid: OnChainSwap = {
    ...onChainSwap,
    bid,
    bidId,
    numBids: onChainSwap.data.bids.length,
  }

  const calculatedOffer = calculateOffer({
    makerCollection,
    takerCollection,
    waveMakerFees,
    waveTakerFees,
  })

  const makerSwapOffer = calculateOnChainMakerSwapOffer({ bid })
  const takerSwapOffer = calculateOnChainTakerSwapOffer({ bid })

  // Calculate savings for both maker and taker
  const makerSwapSavings = calculateSavings(calculatedOffer.makerMarketplaceOffer, makerSwapOffer)
  const takerSwapSavings = calculateSavings(calculatedOffer.takerMarketplaceOffer, takerSwapOffer)

  let swapData: SwapData = {
    ...calculatedOffer,
    makerSwapOffer,
    takerSwapOffer,
    makerSwapSavings,
    takerSwapSavings,
    onChainSwap: onChainSwapWBid,
    makerNft,
    takerNft,
    isOnChain: true,
    bidId,
    roots,
  }

  return swapData
}

const calculateItemValue = (collection: CollectionData): number => {
  return Math.ceil((collection.sellPrice + collection.buyPrice) / 2)
}

const calculateMarketplaceOffer = ({
  sellCollection,
  buyCollection,
}: {
  sellCollection: CollectionData
  buyCollection: CollectionData
}): SwapOffer => {
  const buyCollectionPrice = buyCollection.buyPrice
  const sellCollectionPrice = sellCollection.sellPrice
  // User pays fee on the buy collection
  const baseCost = buyCollectionPrice - sellCollectionPrice
  const feeRate = MARKETPLACE_FEE
  const fee = Math.ceil(buyCollectionPrice * feeRate)

  // User pays royalties on the buy collection
  const royaltiesRate = buyCollection.royaltyRate
  const royalties = Math.ceil(buyCollectionPrice * royaltiesRate)
  const totalCost = baseCost + fee + royalties

  const sellValue = calculateItemValue(sellCollection)
  const volume = totalCost + sellValue

  return {
    totalCost,
    baseCost,
    fee,
    feeRate,
    royalties,
    royaltiesRate,
    volume,
  }
}

const calculateSavings = (marketplaceOffer: SwapOffer, swapOffer: SwapOffer): SwapSavings => {
  let marketplaceCost = marketplaceOffer.totalCost
  let swapCost = swapOffer.totalCost
  let savings = marketplaceCost - swapCost
  let savingsPercentage = swapOffer.volume ? (savings / swapOffer.volume) * 100 : undefined

  marketplaceCost = marketplaceCost / 10 ** SOL_DECIMALS
  swapCost = swapCost / 10 ** SOL_DECIMALS
  savings = savings / 10 ** SOL_DECIMALS

  return {
    marketplaceCost,
    swapCost,
    savings,
    savingsPercentage,
  }
}

const calculateSwapBaseCost = ({
  sellCollection,
  buyCollection,
  savingsShare,
}: {
  sellCollection: CollectionData
  buyCollection: CollectionData
  savingsShare: number
}): number => {
  const totalCost = calculateMarketplaceOffer({ sellCollection, buyCollection }).totalCost
  const totalCostCounerpart = calculateMarketplaceOffer({
    sellCollection: buyCollection,
    buyCollection: sellCollection,
  }).totalCost
  const valueGap = totalCost + totalCostCounerpart

  const savings = valueGap * savingsShare
  return totalCost - savings
}

export const updateFees = ({
  swapData,
  makerFeeRate,
  takerFeeRate,
}: {
  swapData: SwapData
  makerFeeRate?: number
  takerFeeRate?: number
}): SwapData => {
  if (makerFeeRate !== undefined) {
    const makerSwapOffer = calculateSwapOffer({
      sellCollection: swapData.makerCollection,
      buyCollection: swapData.takerCollection,
      swapBaseCost: swapData.makerSwapOffer.baseCost,
      feeRate: makerFeeRate,
    })

    const newMakerSwapSavings = calculateSavings(swapData.makerMarketplaceOffer, makerSwapOffer)

    swapData = {
      ...swapData,
      makerSwapOffer,
      makerSwapSavings: newMakerSwapSavings,
    }
  }

  if (takerFeeRate !== undefined) {
    const takerSwapOffer = calculateSwapOffer({
      sellCollection: swapData.takerCollection,
      buyCollection: swapData.makerCollection,
      swapBaseCost: swapData.takerSwapOffer.baseCost,
      feeRate: takerFeeRate,
    })

    const newTakerSwapSavings = calculateSavings(swapData.takerMarketplaceOffer, takerSwapOffer)

    swapData = {
      ...swapData,
      takerSwapOffer,
      takerSwapSavings: newTakerSwapSavings,
    }
  }

  return swapData
}
