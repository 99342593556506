import { httpsCallable } from 'firebase/functions'
import { doc, getDoc, collection, getDocs, onSnapshot, query } from 'firebase/firestore'
import Firebase from './firebase'
import { scrubOfUndefined } from '../utils'

// https://www.bezkoder.com/react-hooks-firestore/

export const streamNftCollectionsV2 = (callback: any) => {
    const ref = collection(Firebase.getDBApp(), 'nftCollectionsV2')
    return onSnapshot(ref, querySnapshot => {
        const data: any = []
        querySnapshot.forEach(doc => {
            if (!doc || !doc.data() || !doc.data().enabled) return
            data.push({ ...doc.data(), collectionId: doc.id })
        })
        callback(data)
    })
}

export const streamNftCollectionsV2Traits = (collectionId: string, callback: any) => {
    const ref = collection(Firebase.getDBApp(), 'nftCollectionsV2', collectionId, 'traitStats')
    return onSnapshot(ref, querySnapshot => {
        const data: any = []
        querySnapshot.forEach(doc => {
            if (!doc || !doc.data() || !doc.data()) return
            data.push({ ...doc.data(), collectionId, traitId: doc.id })
        })
        callback(data)
    })
}

export async function loadNfts(userId: string) {
    const nfts = httpsCallable(Firebase.getFnsApp(), 'nft-userNfts')
    return await nfts({ userId })
}

export const getNftDetails = async (ids: string[]): Promise<any> => {
    if (!ids) throw new Error('MISSING PARAMS')
    if (ids.length < 1) {
        return []
    }
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-nftMetadata')
    return ref({ ids })
}

export const getCollectionTraits = async (collectionId: string) =>
{
    const ref = collection(Firebase.getDBApp(), `nftCollectionsV2/${collectionId}/traits`)
    const docs = await getDocs(ref)
    return docs.docs.map((doc) => ({ ...doc.data(), collectionId: doc.id }))
}

export const getCollectionTraitStats = async (collectionId: string) => 
{
    const ref = collection(Firebase.getDBApp(), `nftCollectionsV2/${collectionId}/traitStats`)
    const docs = await getDocs(ref)
    return docs.docs.map((doc) => ({ ...doc.data(), collectionId: doc.id }))
}

export const getNftCollections = async (): Promise<any> => {
    const ref = collection(Firebase.getDBApp(), 'nftCollections')

    const docs = await getDocs(ref)

    // return a array of objects with the data and add doc.id as the collectionId
    return docs.docs.map((doc) => ({ ...doc.data(), collectionId: doc.id }))
}

export const getNftMetadata = (
    ids: string[],
    userId?: string,
    blockchain?: string
): Promise<{ data: any }> => {
    console.log(ids, userId, blockchain)
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-nftMetadata')
    console.log(scrubOfUndefined({ ids, userId, blockchain }))
    return ref(scrubOfUndefined({ ids, userId, blockchain }))
}

export const getNftCollectionMetadata = ({
    ids,
    blockchain,
    userId,
    getVolume,
}: {
    ids: string[]
    blockchain?: string
    userId?: string
    getVolume?: boolean
}): Promise<{ data: any }> => {
    console.log(ids)
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionMetadata')
    return ref({ ids, blockchain, getVolume })
}

export const getNftCollectionMetadataWithRoyalty = ({
    ids,
    blockchain,
    userId,
    getVolume,
}: {
    ids: string[]
    blockchain?: string
    userId?: string
    getVolume?: boolean
}): Promise<{ data: any }> => {
    console.log(ids)
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionMetadataWithRoyalty')
    return ref({ ids, blockchain, getVolume })
}

export const getNftCollectionMetadataByIds = ({
    ids,
    blockchain,
}: {
    ids: string[]
    blockchain: string
}): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionMetadataByIds')
    return ref({ ids, blockchain })
}

export const getCollectionStats = ({
    slugs,
    collectionIds,
}: {
    slugs?: string[]
    collectionIds?: string[]
}): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-collectionStats')
    return ref({ slugs, collectionIds })
}

export const getExchangeRates = (blockchain: string): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-getExchangeRates')
    return ref({ blockchain })
}

export const getUserCollections = (uid: string): Promise<{ data: any }> => {
    const ref = httpsCallable(Firebase.getFnsApp(), 'nft-userCollections')
    return ref({ userId: uid })
}
