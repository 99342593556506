import React, { useState, useEffect, useMemo } from 'react'
import { useCollectionSwaps } from '../../../../contexts/CollectionSwapsContext'
import { ChevronDownIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Flex, Image, VStack, Text, Button, Box } from '@chakra-ui/react'
import PickerNftModal from '../../PickerModal/NftModal'
import { Nft } from '../../../../types/nft'
import CustomTooltip from '../../CustomTooltip'
import BestSwapsTable from './BestSwapsTable'
import TraitPickerTable from './TraitPickerTable'
import { useAppContext } from '../../../../contexts/appContext'
import { TraitFilterConfigV2 } from '../../../../types/collectionSwapV2'
import { filterOffersByMakerNftTraitsV2 } from '../../../../utils/filterV2'

export const TraitPicker = () => {
  const {
    giveNfts,
    setGiveNfts,
    selectedTraitCollection,
    traitCollectionsData,
    handleCreateSwaps,
    getTraitOnChainOffers,
    getTraitCalculatedOffers,
  } = useCollectionSwaps()
  
  const { uid, publicKey } = useAppContext()

  const [getTraitCollectionIds, setGetTraitCollectionIds] = useState<string[]>([])
  const getTraitCollections = useMemo(() => {
    return traitCollectionsData.filter((trait) => getTraitCollectionIds.includes(trait.traitId))
  }, [getTraitCollectionIds, traitCollectionsData])

  const onChainFilterConfig = useMemo(() => {
    return {
      makerCollectionIds: [selectedTraitCollection?.onChainId || ''],
      takerNfts: giveNfts,
      takerTraitIds: getTraitCollectionIds,
      makerExclude: [publicKey || ''],
    } as TraitFilterConfigV2
  }, [selectedTraitCollection, giveNfts, publicKey, getTraitCollectionIds])

  const offChainFilterConfig = useMemo(() => {
    return {
      makerNfts: giveNfts,
      takerTraitIds: getTraitCollectionIds,
      takerCollectionIds: [selectedTraitCollection?.onChainId || ''],
    } as TraitFilterConfigV2
  }, [getTraitCollectionIds, selectedTraitCollection, giveNfts])

  const traitOffers = useMemo(() => {
    const onChainOffers = getTraitOnChainOffers(onChainFilterConfig)
    const calculatedOffers = getTraitCalculatedOffers(offChainFilterConfig)
    // console.log("onChainOffers", onChainOffers)
    // console.log("calculatedOffers", calculatedOffers)

    // find best onchain and offchain offer for each trait
    const offers = getTraitCollections.map((traitCollection) => {
      let id = traitCollection.traitId.toLowerCase()
      let calculatedOffer = calculatedOffers.find((offer) => {
        if ('traitId' in offer.takerCollection) {
          return offer.takerCollection.traitId.toLowerCase() === id
        }
        return false
      })
      let offers = filterOffersByMakerNftTraitsV2(onChainOffers, getTraitCollectionIds)
        .sort((a, b) => a.takerSwapSavings.swapCost - b.takerSwapSavings.swapCost)
        .slice(0, 1)

      if (calculatedOffer) {
        offers.push(calculatedOffer)
      }
      return {
        trait: traitCollection,
        offers,
      }
    })

    return offers
  }, [getTraitOnChainOffers, getTraitCalculatedOffers, getTraitCollections, offChainFilterConfig, onChainFilterConfig])

  const handleGiveClick = () => {
    setGiveModalOpen(true)
  }
  const handleGetClick = () => {
    const traitSelectionElement = document.getElementById('trait-selection')
    if (traitSelectionElement) {
      traitSelectionElement.scrollIntoView({ behavior: 'smooth' })
    }
    if (traitPickerMode === 'BestSwaps') {
      setTraitPickerMode('TraitPicker')
    }
  }

  const [traitPickerMode, setTraitPickerMode] = useState<'TraitPicker' | 'BestSwaps'>('TraitPicker')
  const [giveModalOpen, setGiveModalOpen] = useState(false)

    const handlePickNfts = async (nfts: Nft[]) => {
        setGiveNfts(nfts)
        setGiveModalOpen(false)
    }

    const deselectGiveNft = (nft: Nft) => {
        setGiveNfts(giveNfts.filter((giveNft) => giveNft.itemId !== nft.itemId))
    }

  const [canFindSwaps, setCanFindSwaps] = useState(false)

  useEffect(() => {
    setCanFindSwaps(giveNfts.length > 0 && getTraitCollectionIds.length > 0)
    if (!canFindSwaps) {
      setTraitPickerMode('TraitPicker')
    }
  }, [giveNfts, getTraitCollectionIds])

  const currentCollectionTraitCollectionsData = useMemo(() => {
    return traitCollectionsData.filter(
      (trait) => trait.collectionId === selectedTraitCollection?.collectionId
    )
  }, [selectedTraitCollection, traitCollectionsData])

  const selectGetTrait = (traitId: string) => {
    setGetTraitCollectionIds((prev: string[]) => {
      if (prev.includes(traitId)) {
        return prev.filter((id) => id !== traitId)
      } else {
        // Find the matching trait from traitCategories
        const matchingTrait = traitCollectionsData.find((trait) => trait.traitId === traitId)
        if (matchingTrait) {
          return [...prev, traitId]
        }
        return prev
      }
    })
  }

  const handleSwapAction = () => {
    if (traitPickerMode === 'TraitPicker') {
      // Logic to find swaps
      console.log('Finding swaps...')
      setTraitPickerMode('BestSwaps')
    } else {
      let offers = traitOffers.flatMap(({ offers }) => offers)
      handleCreateSwaps(offers, 'trait')
    }
  }

    return (
        <VStack>
            <Flex className="w-full" flexDirection={{ base: "column", md: "row" }} justifyContent="space-between">
                <Flex
                    w={{ base: "100%", md: "40%" }}
                    alignItems="center"
                    justifyContent={giveNfts?.length > 0 ? 'space-between' : 'flex-end'}
                    className="flex justify-between items-center p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius new-bg-transparent text-white transition-all duration-200 px-4 py-2" mb={{ base: 4, md: 0 }}
                    mr={{ base: 0, md: 4 }}
                >
                    {giveNfts?.length > 0 ? (
                        <Box position="relative" display="inline-block">
                            <Image
                                key={'giveCollection'}
                                src={giveNfts[0].image}
                                alt={giveNfts[0].collection.name}
                                className="potato-radius-sm new-bg-transparent"
                                boxSize={["35px", "35px", "50px"]}
                                minWidth={["35px", "35px", "50px"]}
                            />
                            <SmallCloseIcon
                                color="white"
                                position="absolute"
                                background="#FC822F"
                                rounded="full"
                                top="0"
                                right="0"
                                boxSize="12px"
                                cursor="pointer"
                                onClick={() => { deselectGiveNft(giveNfts[0]) }}
                                _hover={{ color: 'gray.500' }}
                            />
                        </Box>
                    ) : (
                        <Box boxSize={["35px", "35px", "50px"]} minWidth={["35px", "35px", "50px"]} mr={1} ml={1} />)}

                    <Button
                        className="px-6 py-2 potato-btn-radius primary-potato-btn"
                        fontSize="large"
                        fontWeight="bold"
                        color="white"
                        onClick={handleGiveClick}
                    >
                        Give
                        <ChevronDownIcon ml={2} />
                    </Button>
                </Flex>

                <Flex w={{ base: "100%", md: "40%" }}
                    alignItems="center"
                    justifyContent="space-between"
                    className="flex justify-between items-center p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius new-bg-transparent text-white px-4 py-3"
                    mb={{ base: 4, md: 0 }}
                    mr={{ base: 0, md: 4 }}>
                    <Flex
                        alignItems="center"
                        overflowX="auto"
                        maxWidth={{ base: "80%", md: "80%" }}
                        pr={2}
                        className="potato-scrollbar"
                    >
                        {getTraitCollections.length > 0 ? (
                            getTraitCollections.map((trait, index) => (
                                <React.Fragment key={trait.traitId}>
                                    <Box position="relative" display="inline-block">
                                        <CustomTooltip label={trait.traitKey + ': ' + trait.traitValue}>

                                            <Image
                                                src={trait.image}
                                                alt={trait.traitKey + ': ' + trait.traitValue}
                                                className="potato-radius-sm new-bg-transparent"
                                                boxSize={["35px", "35px", "50px"]}
                                                minWidth={["35px", "35px", "50px"]}
                                                mr={1}
                                                ml={1}
                                            />
                                        </CustomTooltip>
                                        <SmallCloseIcon
                                            color="white"
                                            position="absolute"
                                            background="#FC822F"
                                            rounded="full"
                                            top="0"
                                            right="1"
                                            boxSize="12px"
                                            cursor="pointer"
                                            onClick={() => { setTraitPickerMode("TraitPicker"); selectGetTrait(trait.traitId) }}
                                            _hover={{ color: 'gray.500' }}
                                        />


                                    </Box>
                                    {index < getTraitCollections.length - 1 && (
                                        <Text fontSize="sm" fontWeight="bold" color="white" textAlign="center" mr={1}>OR</Text>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <Box boxSize={["35px", "35px", "50px"]} minWidth={["35px", "35px", "50px"]} mr={1} ml={1} />
                        )}
                    </Flex>
                    <Button
                        className="py-2 potato-btn-radius primary-potato-btn"
                        fontSize="large"
                        fontWeight="bold"
                        color="white"
                        onClick={handleGetClick}
                        flexShrink={0}
                    >
                        Get
                        <ChevronDownIcon ml={2} />
                    </Button>
                </Flex>

        <Flex
          w={{ base: '100%', md: '20%' }}
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end' }}
        >
          <Button
            className="potato-radius px-6 py-2"
            bg="#FFE0F2"
            fontSize="large"
            fontWeight="bold"
            color="#FF4ADA"
            onClick={handleSwapAction}
            height="100%"
            _hover="#d7b0c7"
            width="100%"
            isDisabled={!canFindSwaps && traitPickerMode !== 'BestSwaps'}
          >
            {traitPickerMode === 'BestSwaps' ? 'Create Swaps' : 'Find Swaps'}
          </Button>
        </Flex>
      </Flex>

      {traitPickerMode === 'TraitPicker' ? (
        <TraitPickerTable
          traitCollectionsData={currentCollectionTraitCollectionsData}
          selectedTraitIds={getTraitCollectionIds}
          selectGetTrait={(traitId: string) => {
            setTraitPickerMode('TraitPicker')
            selectGetTrait(traitId)
          }}
        />
      ) : (
        <BestSwapsTable traitOffers={traitOffers} />
      )}

      <PickerNftModal
        isOpen={giveModalOpen}
        onClose={() => setGiveModalOpen(false)}
        onAction={async (nfts: Nft[]) => {
          setTraitPickerMode('TraitPicker')
          handlePickNfts(nfts)
        }}
      />
    </VStack>
  )
}

export default TraitPicker
