import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon
} from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { ChangeEvent } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAppContext } from "../../contexts/appContext";
import firebase from "../../services/firebase";
import { updateInfo } from "../../services/user.service";

export default function TelegramInput() {
  const { uid } = useAppContext();
  const [data] = useDocument(
    doc(firebase.getDBApp(), `users`, uid! || "not_found", "contact", "telegram")
  );
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await updateInfo(uid!, "telegram", { value: e.target.value });
  };
  const value = data?.data()?.value as string;
  return (
    <FormControl>
      <FormLabel htmlFor="telegram">Telegram</FormLabel>
      <InputGroup>
        <InputLeftAddon children="@" />
        <Input id="telegram" value={value || ""} onChange={handleChange} style={{
          borderTopRightRadius: "30px",
          borderBottomRightRadius: "30px"
        }} className="new-bg-transparent  " />
      </InputGroup>
    </FormControl>
  );
}
