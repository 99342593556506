import { useToast } from "@chakra-ui/react";
import { PublicKey } from "@solana/web3.js";
import {
  setPersistence,
  signInWithCustomToken,
  signOut as fbSignOut,
  browserLocalPersistence,
  onAuthStateChanged,
  updateEmail as fbUpdateEmail,
  sendEmailVerification as fbSendEmailVerification,
  User,
  updateProfile,
  reload,
} from "firebase/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getNonce, verifySignedMessage, verifySignedTransaction } from "../components/blockchains/utils";
import Firebase from "../services/firebase";
import {
  updateInfo,
  getEmail,
  getTelegram,
  getDiscord,
  updateUser as updateUserFb,
  getUserData,
  getUserBalance,
  getLeaderboardPoints,
  refreshLastConnected,
  checkProfileCompletion,
  userRewards,
  streamUserPoints,
} from "../services/user.service";
import { useUA } from "./userTracking";
import { loadNfts } from "../services/nfts.service";
import { Nft } from "../types/nft";

import { PointsConfig, PointsMultiplier, UserPoints } from "../types/collectionSwapV2";


import { useWallet } from "@solana/wallet-adapter-react"
// import { roundValue } from "../utils";
// import { useDocument } from "react-firebase-hooks/firestore";
// import { doc } from "firebase/firestore";
// import firebase from "../services/firebase";

interface Props {
  children?: ReactNode;
}

export type Email = {
  value?: string;
  isVerified?: boolean;
  prompt?: boolean;
  optOut?: boolean;
  verificationSent?: boolean;
};

export type Telegram = {
  value?: string;
};

export type Twitter = {
  value?: string;
};

export type Discord = {
  value?: string;
};

type Profile = {
  displayName?: string;
  photoURL?: string;
};

export type Agreement = {
  consentTosAndPp?: boolean;
  consentNotifications?: boolean;
};

export type FbUser = {
  address?: string;
  blockchain?: string;
  displayName?: string;
  rights?: { [key: string]: boolean };
  admin?: boolean;
  avatar?: string;
  twitterConnected?: boolean;
};

interface AppContextInterface extends State {
  signIn: () => void;
  signOut: () => Promise<void>;
  setToken: (token: string) => void;
  updateUser: (body: any) => void;
  setPublicKey: (publicKey: PublicKey) => void;
  returnUrl?: string;
  updateState: (state: Partial<State>) => void;
  setCurrentFilter: (val: string) => void;
  getEncodedNonce: (
    publicKey: string,
    blockchain: string,
    wallet?: string
  ) => Promise<Uint8Array | string>;
  getToken: (
    address: string,
    blockchain: string,
    signature: string,
    publicKey?: string
  ) => Promise<string>;
  getRewards: () => Promise<void>;
  getTokenFromSignedTransaction: (
    transaction: string,
    blockchain: string
  ) => Promise<string>;
  signInWithToken: (token: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  sendEmailVerification: (email: string) => Promise<void>;
  updateDisplayName: (displayName: string) => Promise<void>;
  reloadUser: () => Promise<void>;
  updateUserProfile: ({ displayName, photoURL }: Profile) => Promise<void>;
  getTokenBalance: (uid: string) => Promise<void>;
  getUserNfts: (uid: string) => Promise<void>;
  isFetchingBalance: boolean;
  setTwitterConnected: (val: boolean) => void;
  getUserLeaderboardPoints: (uid: string) => Promise<{ points: number, rank: number, swapCount: number, userId: string, saved: number }>;
  checkProfileCompletionPoints: () => Promise<void>;
  userPoints: UserPoints | null;
  totalUserPoints: number | null;
}

const AppCtx = createContext<AppContextInterface>({} as AppContextInterface);

const initialState = {
  admin: false,
  user: null,
  token: null,
  uid: null,
  publicKey: null,
  displayName: null,
  avatar: null,
  balance: null,
  nfts: [],
  signingIn: false,
  fetchingBalance: false,
  rights: {},
  currentFilter: "listing",
  signedIn: false,
  email: null,
  agreements: null,
  initialized: false,
  showContactModal: false,
  tokenBalances: undefined,
  activeChainBalance: "native",
  twitterConnected: false,
};

type Action =
  | { type: "update"; payload: any }
  | { type: "reset"; payload: any };

export type TokenBalances = {
  tokens: {
    id: string;
    value: number;
    decimals: number;
    abbr: string;
    truncate: number;
    name: string;
  }[];
  numNfts: number;
};

type State = {
  admin: boolean;
  rewards: PointsConfig;
  rewardsWBonus: PointsConfig;
  bonuses: PointsMultiplier;
  user?: User;
  token?: string;
  publicKey?: string;
  uid?: string;
  displayName?: string;
  avatar?: string;
  balance?: number;
  nfts?: Nft[];
  signingIn: boolean;
  fetchingBalance: boolean;
  rights: { [key: string]: boolean };
  currentFilter: string;
  signedIn: boolean;
  email?: Email;
  telegram?: Telegram;
  discord?: Discord;
  agreements?: Agreement;
  initialized: boolean;
  showContactModal: boolean;
  tokenBalances?: TokenBalances;
  activeChainBalance: string;
  twitterConnected?: boolean;
  pointsMultipliers?: PointsMultiplier[];
};

const reducer = (state: State, { type, payload }: Action) => {
  switch (type) {
    case "update":
      localStorage.setItem("neoswap", JSON.stringify({ ...state, ...payload }));
      return {
        ...state,
        ...payload,
      };
    case "reset":
      localStorage.setItem(
        "neoswap",
        JSON.stringify({
          ...initialState,
          ...payload,
          signedIn: false,
        })
      );
      return {
        ...initialState,
        ...payload,
        signedIn: false,
      };
    default:
      return state;
  }
};

export default function AppProvider({ children }: Props) {
  const { gaSignIn, gaBalance, gaSignOut } = useUA();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [rewards, setRewards] = useState<PointsConfig | null>(null);
  const [rewardsWBonus, setRewardsWBonus] = useState<PointsConfig | null>(null);
  const [bonuses, setBonuses] = useState<PointsMultiplier | null>(null);
  const [userPoints, setUserPoints] = useState<UserPoints | null>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);
  const toast = useToast();
  const returnUrl = searchParams.get("returnUrl");

  const getEncodedNonce = useCallback(
    async (publicKey: string, blockchain: string, wallet?: string) => {
      const { nonce } = await getNonce(publicKey, blockchain, wallet);
      return nonce;
      // if (blockchain === "stacks") return nonce;
      // const encoded = new TextEncoder().encode(nonce);
      // if (!encoded) throw new Error("Error encoding nonce!");
      // return encoded;
    },
    []
  );

  const getRewards = useCallback(async () => {
    try {
      const data = await userRewards();
      setRewards(data?.rewards || null);
      setRewardsWBonus(data?.rewardsWBonus || null);
      setBonuses(data?.bonuses || null);
    } catch (e: any) {
      console.log("ERROR GETTING USER REWARDS", e);
    }
  }, []);


  useEffect(() => {
    if (!state.uid) return;
    const unsubscribe = streamUserPoints(state.uid, setUserPoints)

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => {
      unsubscribe()
    }
  }, [state.uid])

  const totalUserPoints = useMemo(() => {
    if (!userPoints) return null;
    const { accumulatedPoints, bonuses } = userPoints;
    const bonusesSum = Object.values(bonuses).reduce((acc, cur) => acc + cur.points, 0);
    const accumulatedPointsSum = Object.values(accumulatedPoints).reduce((acc, cur) => acc + cur, 0);

    return bonusesSum + accumulatedPointsSum;
  }, [userPoints]);

  const getToken = useCallback(
    async (
      address: string,
      blockchain: string,
      signature: string,
      pubKey?: string
    ) => {
      const { token } = await verifySignedMessage(
        address,
        signature,
        blockchain,
        pubKey
      );
      return token;
    },
    []
  );

  const getTokenFromSignedTransaction = useCallback(
    async (transaction: string, blockchain: string) => {
      const { token } = await verifySignedTransaction(
        transaction,
        blockchain
      );
      return token;
    },
    []
  );

  const getUserEmail = async (uid: string) => {
    try {
      const res = await getEmail(uid);
      const data = res?.data();
      // if (!data) throw new Error("Error getting user email!");
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING EMAIL", e);
    }
  };

  const getUserLeaderboardPoints = async (uid: string) => {
    try {
      const data = await getLeaderboardPoints(uid);
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING LEADERBOARD POINTS", e);
    }
  };

  const getUserTelegram = async (uid: string) => {
    try {
      const res = await getTelegram(uid);
      const data = res?.data();
      // if (!data) throw new Error("Error getting user telegram!");
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING TELEGRAM", e);
    }
  };

  const getUserDiscord = async (uid: string) => {
    try {
      const res = await getDiscord(uid);
      const data = res?.data();
      // if (!data) throw new Error("Error getting user telegram!");
      return data;
    } catch (e: any) {
      console.log("ERROR GETTING DISCORD", e);
    }
  };

  const wrongUserCaseCorrectionHotfix = async (userId: string) => {
    const [blockchain, address] = userId.split("-");
    const chainLowerCase = blockchain.toLocaleLowerCase();
    const userUid = chainLowerCase + "-" + address;
    console.log(userUid);
    return userUid;
  };


  const userWallet = useWallet();

  const handleSignedIn = async () => {
    console.log("handleSignedIn", state);
    dispatch({ type: "update", payload: { signingIn: true } });
    const user = state.user;
    const userUid = await wrongUserCaseCorrectionHotfix(user.uid);
    const { user: fbUser, pointsMultipliers } = await getUserData(userUid);
    const [blockchain, publicKey] = userUid.split("-");

    let balanceAddress = publicKey;


    const [email, telegram, discord] = await Promise.all([getUserEmail(userUid), getUserTelegram(userUid), getUserDiscord(userUid)]);

    getTokenBalance(userUid);
    getUserNfts(userUid);

    dispatch({
      type: "update",
      payload: {
        user,
        uid: userUid,
        publicKey,
        signedIn: true,
        signingIn: false,
        email,
        telegram,
        discord,
        twitterConnected: fbUser?.twitterConnected,
        pointsMultipliers,
      },
    });
    // authWithSendbird(userUid);
    gaSignIn(userUid);
    if (returnUrl) navigate(returnUrl, { replace: true });
  };

  useEffect(() => {
    if (!state.signedIn) return;
    handleSignedIn();
  }, [state.signedIn]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      Firebase.getAuthApp(),
      async (user) => {
        if (user) {
          const { user: fbUser, ...rest } = await getUserData(user.uid);
          dispatch({
            type: "update",
            payload: {
              initialized: true,
              signedIn: true,
              user,
              ...fbUser,
              ...rest,
            },
          });
        } else {
          console.log("user is signed out");
          dispatch({
            type: "reset",
            payload: {},
          });
        }
      }
    );

    return unsubscribe;
  }, []);

  const reloadUser = useCallback(async () => {
    await reload(state.user);
    const currentUser = Firebase.getAuthApp().currentUser;
    if (!currentUser) return;
    const { user: fbUser, email, ...rest } = await getUserData(currentUser.uid);
    let isVerified = false;
    if (currentUser?.emailVerified) {
      isVerified = true;
      await updateInfo(currentUser.uid, "email", {
        ...email,
        isVerified: true,
      });
    }
    dispatch({
      type: "update",
      payload: {
        user: currentUser,
        email: { ...email, isVerified },
        ...fbUser,
        ...rest,
      },
    });
  }, [state.user]);

  const updateEmail = useCallback(
    async (email: string) => {
      await fbUpdateEmail(state.user, email);
    },
    [state.user]
  );

  const updateUserProfile = useCallback(
    async (data: Profile) => {
      console.log("Updating user profile", state.user, data);
      // await updateProfile(state.user, data);
      await updateUserFb(state.uid, data);
    },
    [state.user, state.uid]
  );

  const sendEmailVerification = useCallback(async () => {
    console.log(state.user);
    await fbSendEmailVerification(state.user);
    await updateInfo(state.uid, "email", { verificationSent: true });
  }, [state.user, state.uid]);


  const signInWithToken = useCallback(async (token: string) => {
    try {
      await setPersistence(Firebase.getAuthApp(), browserLocalPersistence);
      await signInWithCustomToken(Firebase.getAuthApp(), token);
      console.log("Signing in with token");
    } catch (e) {
      console.log("SIGNING IN WITH TOKEN FAILED", e);
      throw e;
    }
  }, []);

  const updateUser = async (data: any) => {
    try {
      if (data?.displayName) {
        await updateProfile(state.user, { displayName: data.displayName });
        dispatch({
          type: "update",
          payload: data,
        });
      }
      await updateUserFb(state.uid, data);
    } catch (e: any) {
      toast({
        title: "Error updating your name!",
        description: "Please Try Again!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      localStorage.setItem("neoswap", JSON.stringify({ ...state, ...data }));
    }
  };

  // const updateStateEmail = async (data: Partial<Email>) => {
  //   try {
  //     dispatch({
  //       type: "update",
  //       payload: { email: data },
  //     });
  //     if (data.value) {
  //       console.log("fb update email", state.user, data.value);
  //       await fbUpdateEmail(state.user, data.value);

  //       console.log("email updated", { ...state.user, email: data.value });
  //       await sendVerification({ ...state.user, email: data.value });
  //     }
  //     await updateEmail(state.uid, data);
  //   } catch (e: any) {
  //     let message = "Please Try Again!";

  //     if (e.code === "auth/requires-recent-login") {
  //       message = "Please re-login to update your email!";
  //     }
  //     toast({
  //       title: "Error updating your email!",
  //       description: message,
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //     throw e;
  //   }
  // };

  const signOut = async () => {
    try {
      await fbSignOut(Firebase.getAuthApp());
      dispatch({
        type: "reset",
        payload: {},
      });
      gaSignOut();
    } catch (e) {
      console.log("ERROR SIGNING OUT", e);
    }
  };



  const getTokenBalance = async (uid: string) => {
    setIsFetchingBalance(true);

    const isBackPackWallet = userWallet.wallet?.adapter.name.toLowerCase() === "backpack";
    refreshLastConnected(isBackPackWallet);

    try {
      dispatch({ type: "update", payload: { fetchingTokenBalance: true } });
      const res = (await getUserBalance(uid)) as any;
      dispatch({
        type: "update",
        payload: {
          tokenBalances: res.data,
        },
      });
    } catch (e) {
      console.log(`Error getting token balance for ${uid}`, e);
      toast({
        title: "Error getting your balance",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      dispatch({ type: "update", payload: { fetchingTokenBalance: false } });
      setIsFetchingBalance(false);
    }
  };

  const getUserNfts = async (uid: string) => {
    try {
      const res = await loadNfts(uid) as { data: Nft[] };
      dispatch({
        type: "update",
        payload: {
          nfts: res.data,
        },
      });
    } catch (e) {
      console.log("ERROR GETTING USER NFTS", e);
    }
  };

  const updateState = useCallback((payload: Partial<State>) => {
    dispatch({ type: "update", payload });
  }, []);

  const setCurrentFilter = useCallback((val: string) => {
    dispatch({ type: "update", payload: { currentFilter: val } });
  }, []);

  const setTwitterConnected = useCallback(async (val: boolean) => {
    dispatch({ type: "update", payload: { twitterConnected: val } });
    await reloadUser();
  }, [dispatch, reloadUser]);

  const createBalance = (
    tokenBalances: TokenBalances,
    activeChainBalance: string
  ) => {
    const found = tokenBalances?.tokens?.find(
      (ele) => ele.id === activeChainBalance
    );
    if (!tokenBalances || !found) return 0;

    const { value, decimals } = found;
    return value / 10 ** decimals;
  };

  const checkProfileCompletionPoints = useCallback(async () => {
    await checkProfileCompletion();
  }, []);

  const value = useMemo(() => {
    const balance = createBalance(
      state.tokenBalances,
      state.activeChainBalance
    );
    return { ...state, balance };
  }, [state]);

  return (
    <AppCtx.Provider
      value={{
        ...value,
        rewards,
        rewardsWBonus,
        bonuses,
        getRewards,
        signOut,
        updateUser,
        updateState,
        setCurrentFilter,
        getEncodedNonce,
        getToken,
        getTokenFromSignedTransaction,
        signInWithToken,
        getTokenBalance,
        getUserNfts,
        isFetchingBalance,
        updateEmail,
        updateUserProfile,
        sendEmailVerification,
        reloadUser,
        returnUrl,
        setTwitterConnected,
        getUserLeaderboardPoints,
        checkProfileCompletionPoints,
        userPoints,
        totalUserPoints,
      }}
    >
      {children}
    </AppCtx.Provider>
  );
}

export function useAppContext(): AppContextInterface {
  const ctx = useContext(AppCtx);
  if (ctx === undefined) {
    throw new Error("useAppContext requires AppCtx.Provider");
  }
  return ctx;
}
