import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  Icon,
  Button,
  useToast,
  Avatar,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { Nft } from '../types/nft'

import * as Yup from 'yup'
import { useAppContext } from '../contexts/appContext'
import { updateInfo, updateTerms, updateUser } from '../services/user.service'
import { hasError } from '../utils'
import DiscordInput from './Inputs/DiscordInput'
import TwitterInput from './Inputs/TwitterInput'
import TelegramInput from './Inputs/TelegramInput'
import { useUA } from '../contexts/userTracking'
import { useCollectionSwaps } from '../contexts/CollectionSwapsContext'
import { PickerNftUserImageModal } from './CollectionSwap/PickerModal/NftModal'
import { useState } from 'react'

const ClickableAvatar = ({ avatar, onClick }: any) => {
  const buttonStyle = {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
  }

  return (
    <button
      className="clickable-avatar"
      onClick={onClick}
      style={buttonStyle}
      onMouseEnter={(e) => (e.currentTarget.style.opacity = '0.8')}
      onMouseLeave={(e) => (e.currentTarget.style.opacity = '1')}
    >
      <Avatar size="xl" src={avatar} style={buttonStyle} />
    </button>
  )
}

const schema = Yup.object().shape({
  displayName: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Please enter a display name'),
  email: Yup.string().email('Please enter a valid email').required('Email is required!'), // pass your error message string
})

export default function ProfileForm() {
  const toast = useToast()
  const {
    uid,
    email,
    user,
    displayName,
    avatar,
    updateEmail,
    updateUserProfile,
    checkProfileCompletionPoints,
  } = useAppContext()

  const [ isPickerModalOpen, setIsPickerModalOpen ] = useState(false)

  const handleUpdateAvatar = async (nfts: Nft[]) => {
    try {
      await updateUser(uid!, { avatar: nfts[0].image })
      checkProfileCompletionPoints()
      toast({
        title: 'User Avatar Updated. Refresh the page to see the changes.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Error Updating Avatar!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const { addGAEvent } = useUA()

  const initialValues = {
    email: user?.email || email?.value || '',
    displayName: user?.displayName || displayName || '',
    tacChecked: false,
    notificationsChecked: false,
  }

  const handleError = (e: any) => {
    let message = 'Please Try Again!'

    if (e.code === 'auth/requires-recent-login') {
      message = 'Please re-login to update your email!'
    }

    if (e.code === 'auth/email-already-in-use') {
      message = 'Email already in use!'
    }

    if (e.code === 'auth/too-many-requests') {
      message = 'Too many requests, please try again later!'
    }

    toast({
      title: 'Error updating your email!',
      description: message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  const handleSubmit = async (values: typeof initialValues, props: any) => {
    try {
      addGAEvent('profile_update-button_click')

      await updateUserProfile({ displayName: values.displayName })

      await updateEmail(values.email)

      await updateInfo(uid!, 'email', { value: values.email })

      await updateTerms(uid!, {
        consentTosAndPp: values.tacChecked,
        consentNotifications: values.notificationsChecked,
      })

      await checkProfileCompletionPoints()
      toast({
        title: 'Profile Saved Successfully!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (e) {
      handleError(e)
    } finally {
      props.setSubmitting(false)
    }
  }
  return (
    <Box width="100%">
      <Center>
        <Box width={{ base: '100%', md: '75%' }} padding={10}>
          <Container width={{ base: '100%', md: 'md' }}>
            <div className="potato-radius new-bg-transparent shadow-lg  p-6 ">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
              >
                {({ errors, touched, values, handleChange, ...props }) => {
                  return (
                    <Flex flexDir="column" justifyContent="center" alignItems="center" gap="6">
                      <ClickableAvatar
                        avatar={avatar}
                        onClick={() => {
                          setIsPickerModalOpen(true)
                        }}
                      />
                      <PickerNftUserImageModal
                        isOpen={isPickerModalOpen}
                        onClose={() => setIsPickerModalOpen(false)}
                        onAction={handleUpdateAvatar}
                      />
                      <form onSubmit={props.handleSubmit}>
                        <Flex flexDir="column" justifyContent="center" alignItems="center" gap="6">
                          {/* <Avatar size="xl" src={avatar} /> */}

                          <FormControl
                            isRequired
                            isInvalid={hasError(errors, touched, 'displayName')}
                          >
                            <FormLabel htmlFor="displayName">Display Name</FormLabel>
                            <Input
                              id="displayName"
                              name="displayName"
                              onChange={handleChange}
                              value={values.displayName}
                              className="new-bg-transparent potato-radius"
                            />
                            <FormErrorMessage>{errors.displayName}</FormErrorMessage>
                          </FormControl>
                          <FormControl isRequired isInvalid={hasError(errors, touched, 'email')}>
                            <Flex>
                              <FormLabel htmlFor="email">Email</FormLabel>
                              <Tooltip label="The email is needed so we can notify you when smart contracts are ready to sign and your sign transaction status. We will not share your email nor spam you.">
                                <Icon as={InfoIcon} />
                              </Tooltip>
                            </Flex>
                            <Input
                              id="email"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              className="new-bg-transparent potato-radius"
                              type="email"
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                          </FormControl>
                          <TwitterInput />
                          <TelegramInput />
                          <DiscordInput />
                          <Box
                            width="100%"
                            className="primary-potato-btn potato-radius archivo-black"
                          >
                            <Button type="submit" width="100%" isLoading={props.isSubmitting}>
                              Update
                            </Button>
                          </Box>
                        </Flex>
                      </form>
                    </Flex>
                  )
                }}
              </Formik>
            </div>
          </Container>
        </Box>
      </Center>
    </Box>
  )
}
