import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useAppContext } from "../contexts/appContext";
import { getAbbr, getTruncate, roundValue } from "../utils";
import { useUA } from "../contexts/userTracking";
import { useNavigate } from "react-router-dom";
import { BiLogOut, BiRefresh } from "react-icons/bi";
import useSolana from "../hooks/useSolana";
import CustomTooltip from "./CollectionSwap/CustomTooltip";


export default function SignInButton({ version }: { version?: string }) {
  const { addGAEvent } = useUA();
  const {
    uid,
    publicKey,
    avatar,
    balance,
    activeChainBalance,
    tokenBalances,
    getTokenBalance,
    getUserNfts,
    isFetchingBalance,
    pointsMultipliers,
    totalUserPoints,
  } = useAppContext();
  const { signIn, connecting, signOut } = useSolana();
  const abbr = getAbbr(tokenBalances, activeChainBalance);
  const truncate = getTruncate(tokenBalances, activeChainBalance);
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const [multiplier, setMultiplier] = useState<string>("Oh sweet potato! No multipliers active 😿");

  useEffect(() => {
    if (pointsMultipliers && pointsMultipliers.length) {
      setMultiplier("🥔Potatoes Boost! " + pointsMultipliers.map((booster) => booster.name + " +" + booster.multiplier * 100 + "%").join(", "));
    }
  }, [pointsMultipliers]);

  const refreshBalance = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (uid) {
      await Promise.all([getTokenBalance(uid), getUserNfts(uid)]);
    }
  };

  const handleSignOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    addGAEvent('any_wallet_disconnect');
    signOut();
  };

  if (uid) {
    return (
      <Flex className="div-signout" w={{ base: "full", md: "initial" }} alignItems="center">

        <Button
          className="profile-button potato-radius new-bg-transparent"
          variant="outline"
          size="md"
          _hover={{ bg: "none" }}
          _focus={{ bg: "none" }}
          onClick={handleProfileClick}
          padding={"5px"}
        >

          <CustomTooltip label={multiplier} >
            <Text fontSize="md" fontWeight="bold" mr={1} ml={1}>
              {totalUserPoints ? `${Math.round(totalUserPoints)} 🥔` : "..."}
            </Text>
          </CustomTooltip>
        </Button>

        <Button
          className="profile-button potato-radius new-bg-transparent"
          variant="outline"
          size="md"
          _hover={{ bg: "none" }}
          _focus={{ bg: "none" }}
          onClick={handleProfileClick}
          padding={"5px"}
        >
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Avatar src={avatar} loading="lazy" className="avatar" mr={2} size="sm" />
            <Text fontSize="md" fontWeight="bold" mr={2}>
              {isFetchingBalance ? "..." : `${roundValue(balance || 0, truncate)} ${abbr}`}
            </Text>
          </Flex>


          <CustomTooltip label="Refresh balance">
            <Box as="span">
              <BiRefresh
                className={`${isFetchingBalance ? "animate-spin" : ""} cursor-pointer`}
                size="20"
                onClick={refreshBalance}
              />
            </Box>
          </CustomTooltip>
        </Button>

        <CustomTooltip label="Sign Out" >
          <Button
            className="potato-radius-sm new-bg-transparent"
            variant="outline"
            size="md"
            onClick={handleSignOut}
            ml={2}
            padding={"5px"}
          >
            <BiLogOut size="20" />
          </Button>
        </CustomTooltip>

        {/* <Tooltip label={hasCopied ? "Copied!" : "Copy address"} placement="bottom">
          <Button
            size="sm"
            variant="ghost"
            onClick={onCopy}
            ml={2}
          >
            {trucPublicKey(publicKey || "")} <FaCopy style={{ marginLeft: '5px' }} />
          </Button>
        </Tooltip> */}
      </Flex>
    );
  }

  return (
    <Box className="primary-potato-btn potato-radius archivo-black">
      <Button
        isLoading={connecting}
        loadingText="Connecting..."
        onClick={() => {
          signIn();
        }}
      >
        {version === "connect-page" ? "Connect Wallet" : "Connect"}
      </Button>
    </Box>
  );
}