import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  Text,
} from '@chakra-ui/react'
import CloseButton from '../Buttons/CloseButton'
import ProposeSwapButton from '../ProposeSwapButton'
import AngryCat from "../../../assets/angrypc.png";

interface NotFoundModalProps {
  isOpen: boolean
  onClose: () => void
}

const NotFoundModal: React.FC<NotFoundModalProps> = function ({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent className="potato-modal-bg potato-radius text-dark-potato">
        <ModalHeader></ModalHeader>
        <ModalBody p={0}>
          <div className="flex flex-col items-center justify-center mb-4">
            <div className='w-full flex gap-4  flex-col items-center justify-center px-3 py-20   text-center'>
              <p className='archivo-black text-lg text-dark-potato' >Meow! Swap not found</p>
              <img
                src={AngryCat}
                style={{ maxWidth: '200px', width: '100%', height: 'auto' }}
                alt="Angry Potato Cat"
              />
              <ProposeSwapButton />
            </div>
          </div>
          <div className="w-full flex justify-between gap-2 px-6 pb-6">
            <CloseButton onClick={onClose} />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NotFoundModal
