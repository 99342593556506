import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  Text,
} from '@chakra-ui/react'
import CloseButton from '../Buttons/CloseButton'

interface LoadingModalProps {
  isOpen: boolean
  onClose: () => void
}

const LoadingModal: React.FC<LoadingModalProps> = function ({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent className="potato-modal-bg potato-radius text-dark-potato">
        <ModalHeader></ModalHeader>
        <ModalBody p={0}>
          <div className="flex flex-col items-center justify-center mb-4">
            <div className="w-full flex gap-4  flex-col items-center justify-center px-3 py-20   text-center">
              <div className="w-full flex flex-col items-center justify-center ">
                <Spinner color="#FC822F" size={'xl'} thickness="8px" emptyColor="#666666" />
                <h4 className="pt-2 archivo-black text-dark-potato">Loading Swap...</h4>
              </div>
            </div>
          </div>
          <div className="w-full flex gap-2 px-6 pb-6">
            <CloseButton onClick={onClose} />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoadingModal
