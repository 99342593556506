import axios from "axios";
import Firebase from "../../services/firebase";

export const getNonce = async (
  address: string,
  blockchain: string,
  wallet?: string
): Promise<{ nonce: string }> => {
  const res = await axios.post(
    `${Firebase.environment.api}/auth-getNonceToSign`,
    {
      address,
      blockchain,
      wallet,
    }
  );

  return res.data;
};

export const verifySignedMessage = async (
  address: string,
  signature: string,
  blockchain: string,
  publicKey?: string
) => {
  const res = await axios.post(
    `${Firebase.environment.api}/auth-verifySignedMessage`,
    {
      address,
      signature,
      blockchain,
      publicKey,
    }
  );

  return res.data;
};

export const verifySignedTransaction = async (
  transaction: string,
  blockchain: string,
) => {
  const res = await axios.post(
    `${Firebase.environment.api}/auth-verifySignedTransaction`,
    {
      transaction,
      blockchain,
    }
  );

  return res.data;
};