import { ModalBody } from '@chakra-ui/react'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SurplusComponent from '../Surplus'
import SwapDetailsComponent from '../SwapDetails'
import useSolana from '../../../hooks/useSolana'
import ActionButton from '../Buttons/ActionButton'
import CloseButton from '../Buttons/CloseButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import { Points } from '../Points'
import { useUA } from '../../../contexts/userTracking'
import { CopyButton, TwitterButton } from '../Buttons/SharingButtons'

interface OfferStepProps {
  onClose: () => void
  nextStep: () => void
}

const OfferStep: React.FC<OfferStepProps> = function ({ onClose, nextStep }) {
  const { uid } = useAppContext()
  const { connected, connect } = useSolana()
  const { selectedBid, solToUsd } = useCollectionSwaps()
  const { addGAEvent } = useUA()

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect])

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid, true)
  }, [selectedBid, uid])

  const handleNextStep = useCallback(() => {
    if (!connected) return connect()

    addGAEvent('my-swap_swap_cancel')
    return nextStep()
  }, [connected, connect, nextStep])

  return (
    <ModalBody p={0}>
      <div className="p-6">
        <h2 className="pb-4 archivo-black font-2xl text-dark-potato">My Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && <SwapDetailsComponent {...swapOverview} past={false} />}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && (
              <div className="flex flex-col justify-center h-full">
                <SurplusComponent
                  userSavings={swapOverview?.userSavings}
                  chainUsdValue={solToUsd}
                  calculatedDate={''}
                />
                <Points mode="proposal_accepted" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full justify-between flex gap-2 px-6 pb-6">
        <CloseButton onClick={onClose} />
        <CopyButton onClick={() => addGAEvent('my-swap_swap-link_copy')} mode={'bid'} />
        <TwitterButton onClick={() => addGAEvent('my-swap_swap_share')} mode="you-will-save" />
        <ActionButton onClick={handleNextStep} text="Cancel Swap" />
      </div>
    </ModalBody>
  )
}

export default OfferStep
