import React, { useEffect, useState } from 'react'
import { QuestionIcon } from '@chakra-ui/icons'
import { useUA } from '../../contexts/userTracking'
import { formatSignificantDigits } from '../../utils'
import CustomTooltip from './CustomTooltip'

import { SwapOverview } from '../../types/collectionSwapV2'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext'

const CollectionSwapCard: React.FC<
  SwapOverview & { onSelectSwap: () => void; onCreateSwaps: () => void }
> = ({ giveItem, getItem, userSavings, endTime, isMaker, onSelectSwap, onCreateSwaps }) => {
  const { addGAEvent } = useUA()
  const [countdown, setCountdown] = useState('')
  const [isHovered, setIsHovered] = useState(false)
  const { calculatedPointsConfiguration } = useCollectionSwaps()

  useEffect(() => {
    if (!endTime) return

    // Convert endTime to a Date object
    const endTimeParsed = new Date(endTime * 1000) // Assuming endTime is in seconds

    // Update countdown every second
    const interval = setInterval(() => {
      const now = new Date()
      const timeLeft = endTimeParsed.getTime() - now.getTime()

      if (timeLeft <= 0) {
        clearInterval(interval)
        setCountdown('Ended')
        return
      }

      // Calculate days, hours, minutes, and seconds remaining
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)

      // Update state with the new countdown time
      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`)
    }, 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [endTime])

  const savings = `${formatSignificantDigits(userSavings.savings, 2)}`
  const neoswapCost = userSavings.swapCost

  const handleCardClick = () => {
    if (isMaker) {
      addGAEvent("explore_my-swap_open")
    } else {
      addGAEvent('explore_accept-modal_open')
    }
    onSelectSwap()
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      <div className="flex flex-col items-center w-full" tabIndex={0}>
        <div
          className={`border ${isMaker ? 'border-primary-potato' : ' '
            }   potato-radius new-bg-transparent shadow-lg w-full h-full flex flex-col relative`}
        >
          <div
            className={`  w-full h-full flex flex-col relative`}
            onClick={handleCardClick}
            role="button"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isHovered ? (
              <div className="absolute top-0 right-0 p-2 w-full  potato-radius h-full bg-black bg-opacity-30 z-50 flex items-center justify-center">
                {isMaker ? (
                  <button
                    className="secondary-potato-btn mt-2 px-3 sm:px-4 py-1 sm:py-2 font-bold text-sm sm:text-base"
                    onClick={handleCardClick}
                  >
                    Cancel
                  </button>
                ) : (
                  <div className="new-bg-transparent potato-radius p-1 flex-col items-center text-center">
                    <div>
                      <button
                        id="viewSwap1"
                        onClick={handleCardClick}
                        className="secondary-potato-btn potato-btn-radius  px-6 py-2  archivo-black"
                      >
                        View Swap
                      </button>
                    </div>
                    <div className=" flex items-center p-1 text-center">
                      <span className="text-xs pl-4">{`Earn ${calculatedPointsConfiguration.pointsPerTakerAcceptedSwap} 🥔 Potatoes!`}</span>
                      <CustomTooltip label={`When you accept the swap, you will get 🥔 ${calculatedPointsConfiguration.pointsPerTakerAcceptedSwap} Potatoes.`}>
                        <div tabIndex={0} style={{ cursor: 'pointer', display: 'inline-block' }}>
                          <svg
                            width="12"
                            height="12"
                            style={{ marginLeft: '5px' }}
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.6665 12.6666H9.33317V7.66663H7.6665V12.6666ZM8.49984 5.99996C8.73595 5.99996 8.93386 5.9201 9.09359 5.76038C9.25331 5.60065 9.33317 5.40274 9.33317 5.16663C9.33317 4.93051 9.25331 4.7326 9.09359 4.57288C8.93386 4.41315 8.73595 4.33329 8.49984 4.33329C8.26373 4.33329 8.06581 4.41315 7.90609 4.57288C7.74636 4.7326 7.6665 4.93051 7.6665 5.16663C7.6665 5.40274 7.74636 5.60065 7.90609 5.76038C8.06581 5.9201 8.26373 5.99996 8.49984 5.99996ZM8.49984 16.8333C7.34706 16.8333 6.26373 16.6145 5.24984 16.177C4.23595 15.7395 3.354 15.1458 2.604 14.3958C1.854 13.6458 1.26025 12.7638 0.822754 11.75C0.385254 10.7361 0.166504 9.65274 0.166504 8.49996C0.166504 7.34718 0.385254 6.26385 0.822754 5.24996C1.26025 4.23607 1.854 3.35413 2.604 2.60413C3.354 1.85413 4.23595 1.26038 5.24984 0.822876C6.26373 0.385376 7.34706 0.166626 8.49984 0.166626C9.65261 0.166626 10.7359 0.385376 11.7498 0.822876C12.7637 1.26038 13.6457 1.85413 14.3957 2.60413C15.1457 3.35413 15.7394 4.23607 16.1769 5.24996C16.6144 6.26385 16.8332 7.34718 16.8332 8.49996C16.8332 9.65274 16.6144 10.7361 16.1769 11.75C15.7394 12.7638 15.1457 13.6458 14.3957 14.3958C13.6457 15.1458 12.7637 15.7395 11.7498 16.177C10.7359 16.6145 9.65261 16.8333 8.49984 16.8333Z"
                              fill="#828282"
                            />
                          </svg>
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {isMaker ? (
              <h2 className="px-6 pt-4 archivo-black text-primary-potato no-shadow">My Swap</h2>
            ) : null}
            <div className="flex items-center  h-full justify-center space-x-4 p-4">
              <div className="flex flex-col justify-start items-center w-2/5">
                <span className="text-xs font-normal uppercase tracking-wide">You Give</span>
                {neoswapCost > 0 ? (
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="w-[100px] h-[100px] border bg-white bg-opacity-20 flex items-center justify-center rounded-md">
                      <span className="archivo-black text-center">
                        {formatSignificantDigits(Math.abs(neoswapCost), 2)} SOL
                      </span>
                    </div>
                    <span>+</span>
                  </div>
                ) : null}
                <div className="relative">
                  <img
                    src={giveItem.image}
                    alt="item"
                    className="mt-2 opacity-80 rounded-md"
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                  />
                  {!isMaker && (
                    <QuestionIcon
                      className="potato-primary-color absolute top-11 left-[39px]  "
                      boxSize={6}
                    />
                  )}
                </div>
                <div className="archivo-black text-lg text-center">{giveItem.nftName}</div>
                <div className="font-normal text-md text-center">{giveItem.collectionName}</div>
              </div>

              <div>
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 16L0 11L5 6L6.4 7.425L3.825 10H11V12H3.825L6.4 14.575L5 16ZM15 10L13.6 8.575L16.175 6H9V4H16.175L13.6 1.425L15 0L20 5L15 10Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="flex flex-col justify-start items-center w-2/5">
                <span className="text-xs font-normal uppercase tracking-wide">You Get</span>
                {neoswapCost < 0 ? (
                  <div className="flex flex-col items-center justify-center gap-2">
                    <div className="w-[100px] h-[100px] border bg-white bg-opacity-20 flex items-center justify-center rounded-md">
                      <span className="archivo-black text-center">
                        {formatSignificantDigits(Math.abs(neoswapCost), 2)}
                        <br />
                        SOL
                      </span>
                    </div>
                    <span>+</span>
                  </div>
                ) : null}
                <div className="relative">
                  <img
                    src={getItem.image}
                    alt="item"
                    className="mt-2 rounded-md"
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                  />
                  {isMaker && (
                    <QuestionIcon
                      className="potato-primary-color absolute top-11 left-[39px]  "
                      boxSize={6}
                    />
                  )}
                </div>
                <div className="archivo-black text-lg text-center">{getItem.nftName}</div>
                <div className="text-xs text-center">{getItem.collectionName}</div>
              </div>
            </div>
          </div>
          <div
            className={`flex justify-between border-t ${isMaker ? 'border-primary-potato' : ''
              } items-center mt-auto`}
          >
            {/* Savings Category */}
            <div className="flex flex-1 flex-col items-center px-3 justify-center">
              <div className="flex items-center">
                <span className="text-xs font-normal mr-2">Savings</span>
                <CustomTooltip
                  label={`Using a marketplace for this swap will cost an extra ${savings} SOL`}
                >
                  <div tabIndex={0} style={{ cursor: 'pointer', display: 'inline-block' }}>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.6665 12.6666H9.33317V7.66663H7.6665V12.6666ZM8.49984 5.99996C8.73595 5.99996 8.93386 5.9201 9.09359 5.76038C9.25331 5.60065 9.33317 5.40274 9.33317 5.16663C9.33317 4.93051 9.25331 4.7326 9.09359 4.57288C8.93386 4.41315 8.73595 4.33329 8.49984 4.33329C8.26373 4.33329 8.06581 4.41315 7.90609 4.57288C7.74636 4.7326 7.6665 4.93051 7.6665 5.16663C7.6665 5.40274 7.74636 5.60065 7.90609 5.76038C8.06581 5.9201 8.26373 5.99996 8.49984 5.99996ZM8.49984 16.8333C7.34706 16.8333 6.26373 16.6145 5.24984 16.177C4.23595 15.7395 3.354 15.1458 2.604 14.3958C1.854 13.6458 1.26025 12.7638 0.822754 11.75C0.385254 10.7361 0.166504 9.65274 0.166504 8.49996C0.166504 7.34718 0.385254 6.26385 0.822754 5.24996C1.26025 4.23607 1.854 3.35413 2.604 2.60413C3.354 1.85413 4.23595 1.26038 5.24984 0.822876C6.26373 0.385376 7.34706 0.166626 8.49984 0.166626C9.65261 0.166626 10.7359 0.385376 11.7498 0.822876C12.7637 1.26038 13.6457 1.85413 14.3957 2.60413C15.1457 3.35413 15.7394 4.23607 16.1769 5.24996C16.6144 6.26385 16.8332 7.34718 16.8332 8.49996C16.8332 9.65274 16.6144 10.7361 16.1769 11.75C15.7394 12.7638 15.1457 13.6458 14.3957 14.3958C13.6457 15.1458 12.7637 15.7395 11.7498 16.177C10.7359 16.6145 9.65261 16.8333 8.49984 16.8333Z"
                        fill="#828282"
                      />
                    </svg>
                  </div>
                </CustomTooltip>
              </div>
              <div className="flex items-center">
                <span className="text-primary-potato text-sm mr-2  no-shadow archivo-black">{savings}</span>
              </div>
            </div>

            {/* Separator */}
            <div
              className={`border-r ${isMaker ? 'border-primary-potato' : ' '}`}
              style={{ height: '50px' }}
            />

            {/* Ends In Category */}
            <div className="flex flex-1 flex-col items-center relative px-2">
              <span className="text-sm font-normal">Ends in</span>
              <span className="text-secondary-pinkFloyd text-xs">{countdown}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectionSwapCard
