import { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useCollectionSwaps } from '../contexts/CollectionSwapsContext';
import { VStack, Text, Spinner, Tabs, TabList, Tab, TabPanels, TabPanel, Box } from '@chakra-ui/react';
import { InCollectionSwapHeader } from '../components/CollectionSwap/IncollectionSwap/header';
import SwapModal from '../components/CollectionSwap/SwapModal';
import { TraitPicker } from '../components/CollectionSwap/IncollectionSwap/Picker/index';
import { useUA } from '../contexts/userTracking';
import SwapTable from '../components/CollectionSwap/SwapTable';

export default function Collection() {
    const {
        collectionsData,
        setSelectedTraitCollectionSlug,
        selectedTraitCollection,
        selectBidId,
        setModalMode,
        getTraitOnChainOffers,
        activeTab,
        setActiveTab,
        setGiveNfts,
    } = useCollectionSwaps();

    const { collectionSlug } = useParams();
    const [searchParams] = useSearchParams()
    const { addGAEvent } = useUA()

    useEffect(() => {
        if (collectionSlug) {
            setSelectedTraitCollectionSlug(collectionSlug);
        }
    }, [collectionSlug]);

    const bidIdFromURL = searchParams.get('bidId')

    const offers = useMemo(() => {
        let traitOffers = getTraitOnChainOffers({
            makerCollectionIds: [selectedTraitCollection?.onChainId || ''],
            // keepNegativeSavings: true,
        })
        return traitOffers
    }, [getTraitOnChainOffers, selectedTraitCollection])

    useEffect(() => {
        if (bidIdFromURL) {
            addGAEvent('incollection_url_select-bid', { bidIdFromURL })
            selectBidId(bidIdFromURL)
            setModalMode('trait')
        }
    }, [bidIdFromURL])

    if (!selectedTraitCollection) {
        if (collectionsData.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center h-screen">
                    <Spinner color="#FC822F" size={'xl'} thickness="8px" emptyColor="#666666" />
                    <Text className='archivo-black text-lg' mt={4}>Loading collections...</Text>
                </div>
            );
        }
        return <Text>No collection found</Text>;
    }

    return (
        <VStack width="100%" spacing={4}>
            <InCollectionSwapHeader />

            <Tabs
                width="100%"
                variant="enclosed-colored"
                index={activeTab === 'swap' ? 0 : 1}
                onChange={(index) => {
                    if (index === 1) {
                        setGiveNfts([])
                    }
                    setActiveTab(index === 0 ? 'swap' : 'explore')
                }}
            >
                <Box width="100%" className="justify-between items-center" px={3}>
                    <TabList
                        position="relative"
                        top="0"
                        left="0"
                        zIndex="1"
                    >
                        <Tab
                            _selected={{
                                background: "#FC822F",
                                fontWeight: 'bold'
                            }}
                            background="rgba(60, 60, 60, 0.17)"
                            border="1px solid rgba(102, 100, 100, 0.13)"
                            borderTopRadius="md"
                            borderBottom="none"
                        >
                            Swap
                        </Tab>
                        <Tab
                            _selected={{
                                background: "#FC822F",
                                fontWeight: 'bold'
                            }}
                            background="rgba(60, 60, 60, 0.17)"
                            border="1px solid rgba(102, 100, 100, 0.13)"
                            borderTopRadius="md"
                            borderBottom="none"
                        >
                            Explore
                        </Tab>
                    </TabList>

                    <TabPanels pb={2}>
                        <TabPanel className="w-full max-w-7xl mb-10 new-bg-transparent" style={{ borderRadius: '0 30px 30px 30px' }}>
                            <TraitPicker />
                        </TabPanel>
                        <TabPanel className="w-full max-w-7xl mb-10 new-bg-transparent" style={{ borderRadius: '0 30px 30px 30px' }}>
                            <SwapTable
                                offers={offers}
                                type="trait"
                            />
                        </TabPanel>
                    </TabPanels>
                </Box>
            </Tabs>

            <SwapModal />
        </VStack >
    );
}