import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Text } from '@chakra-ui/react';
import CustomTooltip from './CollectionSwap/CustomTooltip';
import useSolana from '../hooks/useSolana';
import { petCat } from '../services/user.service';
import { useAppContext } from '../contexts/appContext';

interface FloatingImageProps {
  imageSrc: string;
}

const FloatingImage: React.FC<FloatingImageProps> = ({ imageSrc }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const imageRef = useRef<HTMLImageElement>(null);
  const animationRef = useRef<number>();
  const { connected } = useSolana()
  const { userPoints, rewards } = useAppContext();
  const [direction, setDirection] = useState<'up' | 'down' | 'left' | 'right' | 'upLeft' | 'upRight' | 'downLeft' | 'downRight'>('up');

  const moveImage = useCallback(() => {
    if (imageRef.current) {
      let { top, left } = imageRef.current.getBoundingClientRect();
      const speed = 2;

      switch (direction) {
        case 'up':
          top -= speed;
          if (top < -100) {
            imageRef.current.style.top = '100%';
            imageRef.current.style.left = `${Math.random() * 80}%`;
          } else {
            imageRef.current.style.top = `${top}px`;
          }
          break;
        case 'down':
          top += speed;
          if (top > window.innerHeight) {
            imageRef.current.style.top = '-100px';
            imageRef.current.style.left = `${Math.random() * 80}%`;
          } else {
            imageRef.current.style.top = `${top}px`;
          }
          break;
        case 'left':
          left -= speed;
          if (left < -100) {
            imageRef.current.style.left = '100%';
            imageRef.current.style.top = `${Math.random() * 80}%`;
          } else {
            imageRef.current.style.left = `${left}px`;
          }
          break;
        case 'right':
          left += speed;
          if (left > window.innerWidth) {
            imageRef.current.style.left = '-100px';
            imageRef.current.style.top = `${Math.random() * 80}%`;
          } else {
            imageRef.current.style.left = `${left}px`;
          }
          break;
        case 'upLeft':
          top -= speed;
          left -= speed;
          if (top < -100 || left < -100) {
            imageRef.current.style.top = `${Math.random() * 80}%`;
            imageRef.current.style.left = '100%';
          } else {
            imageRef.current.style.top = `${top}px`;
            imageRef.current.style.left = `${left}px`;
          }
          break;
        case 'upRight':
          top -= speed;
          left += speed;
          if (top < -100 || left > window.innerWidth) {
            imageRef.current.style.top = '100%';
            imageRef.current.style.left = '-100px';
          } else {
            imageRef.current.style.top = `${top}px`;
            imageRef.current.style.left = `${left}px`;
          }
          break;
        case 'downLeft':
          top += speed;
          left -= speed;
          if (top > window.innerHeight || left < -100) {
            imageRef.current.style.top = '-100px';
            imageRef.current.style.left = '100%';
          } else {
            imageRef.current.style.top = `${top}px`;
            imageRef.current.style.left = `${left}px`;
          }
          break;
        case 'downRight':
          top += speed;
          left += speed;
          if (top > window.innerHeight || left > window.innerWidth) {
            imageRef.current.style.top = '-100px';
            imageRef.current.style.left = '-100px';
          } else {
            imageRef.current.style.top = `${top}px`;
            imageRef.current.style.left = `${left}px`;
          }
          break;
      }
    }
    animationRef.current = requestAnimationFrame(moveImage);
  }, [direction]);

  const resetImagePosition = useCallback(() => {
    const directions: ('up' | 'down' | 'left' | 'right' | 'upLeft' | 'upRight' | 'downLeft' | 'downRight')[] = [
      'up', 'down', 'left', 'right', 'upLeft', 'upRight', 'downLeft', 'downRight'
    ];
    const randomDirection = directions[Math.floor(Math.random() * directions.length)];
    setDirection(randomDirection);

    if (imageRef.current) {
      switch (randomDirection) {
        case 'up':
          imageRef.current.style.top = '100%';
          imageRef.current.style.left = `${Math.random() * 80}%`;
          break;
        case 'down':
          imageRef.current.style.top = '-100px';
          imageRef.current.style.left = `${Math.random() * 80}%`;
          break;
        case 'left':
          imageRef.current.style.left = '100%';
          imageRef.current.style.top = `${Math.random() * 80}%`;
          break;
        case 'right':
          imageRef.current.style.left = '-100px';
          imageRef.current.style.top = `${Math.random() * 80}%`;
          break;
        case 'upLeft':
        case 'upRight':
          imageRef.current.style.top = '100%';
          imageRef.current.style.left = randomDirection === 'upLeft' ? '100%' : '-100px';
          break;
        case 'downLeft':
        case 'downRight':
          imageRef.current.style.top = '-100px';
          imageRef.current.style.left = randomDirection === 'downLeft' ? '100%' : '-100px';
          break;
      }
    }

    if (animationRef.current) cancelAnimationFrame(animationRef.current);
    animationRef.current = requestAnimationFrame(moveImage);
  }, [moveImage]);

  useEffect(() => {
    resetImagePosition();
    return () => {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, [resetImagePosition]);

  const handleClick = () => {
    const petCatCount = userPoints?.daily?.petCat || 0;
    let TIMEOUT = 1000;
    if (!connected) {
      setMessage("Connect your wallet to claim 🥔 potatoes!");
    } else if (petCatCount >= rewards.maxDailyPetCat) {
      setMessage("You can only pet the cat 300 times per day");
      TIMEOUT = 2000;
    } else {
      setMessage("Meow you got 🥔1 !");
      petCat();
    }

    setIsVisible(false);
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
      setIsVisible(true);
      resetImagePosition(); // Reset position after the message disappears
    }, TIMEOUT);
  };

  return (
    <>
      {isVisible && (
        <Box position="fixed" zIndex={9999}>
          <CustomTooltip label="Pet me for a 🥔1">
            <img
              ref={imageRef}
              src={imageSrc}
              alt="Floating"
              className="floating-potato"
              onClick={handleClick}
              style={{
                position: 'fixed',
                width: '100px',
                cursor: 'pointer',
              }}
            />
          </CustomTooltip>
        </Box>
      )}
      {showMessage && (
        <Box
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          zIndex={10000}
          className="potato-radius message-animation swap-ui"
        >
          <Text
            className="archivo-black"
            px={4}
            py={2}
            borderRadius="md"
            fontSize="xl"
            fontWeight="bold"
            boxShadow="lg"
          >
            {message}
          </Text>
        </Box>
      )}
    </>
  );
};

export default FloatingImage;