import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_TRAITBIDS_ENDPOINT

export type TraitFilter = {
  trait: string
  values: string[]
}

export type TraitBidCreateParams = {
  collectionId: string
  traits: TraitFilter[]
  maxDepth?: number
}

export type ProofParams = {
  mint: string
  roots: string[]
}

export type BidParams = {
  roots: string[]
}[]

export type BidTraits = {
  bidAccount?: string
  collectionId: string
  roots: string[]
  traits: TraitFilter[]
}

export const createTraitBid = async (params: TraitBidCreateParams) => {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append('collectionId', params.collectionId);
    
    params.traits.forEach((trait, index) => {
      queryParams.append(`traits[${index}][trait]`, trait.trait);
      trait.values.forEach(value => {
        queryParams.append(`traits[${index}][values][]`, value);
      });
    });

    if (params.maxDepth !== undefined) {
      queryParams.append('maxDepth', params.maxDepth.toString());
    }

    const response = await axios.get(`${API_BASE_URL}/create?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to create trait bid')
  }
}

export const getProof = async (params: ProofParams) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/proof`, { params })
    return response.data
  } catch (error) {
    throw new Error('Failed to get proof')
  }
}

export const getBidsTraits = async (params: BidParams): Promise<BidTraits[]> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/get-bids`, params)
    return response.data.data
  } catch (error) {
    console.log('error', error)
    throw new Error('Failed to get bids traits')
  }
}
