import React, { useMemo, useState } from 'react'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext'
import { FilterConfig, SortConfig } from '../../types/collectionSwapV2'
import { Text, Flex, Box, Select, Icon, Stack } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import ReactSelect from 'react-select'

interface FilterProps {
  filterConfig: { giveCollections?: string[]; getCollections?: string[] }
  setFilterConfig: (filterConfig: { giveCollections?: string[]; getCollections?: string[] }) => void
}

interface SortProps {
  sortConfig: SortConfig
  setSortConfig: (sortConfig: SortConfig) => void
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    ':hover': {
      borderColor: '#a0a0a0',
    },
    minWidth: '200px',
    width: '100%',
    background: 'transparent',
    display: 'flex',
    overflow: 'hidden',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '2rem',
    overflow: 'hidden',
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: '20px',
    '::-webkit-scrollbar': {
      width: '8px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white',
    fontSize: '1.125rem',
    fontFamily: 'ArchivoBlack',
    marginBottom: '0',
    marginRight: '0.0',
    mariginLeft: '0.5rem',
  }),
}

export const Sort: React.FC<SortProps> = ({ setSortConfig, sortConfig }) => {
  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [sortBy, sortDirection] = event.target.value.split('|') as [
      SortConfig['sortBy'],
      SortConfig['sortDirection']
    ]
    let newSortConfig = { ...sortConfig }
    newSortConfig.sortBy = sortBy
    newSortConfig.sortDirection = sortDirection
    setSortConfig(newSortConfig)
  }

  return (
    <Flex position="relative" alignItems="center">
      <Select
        onChange={handleSortChange}
        className="potato-radius new-bg-transparent font-medium pt-0 pr-8 pl-4 rounded w-full appearance-none"
        icon={<></>}
        value={`${sortConfig.sortBy}|${sortConfig.sortDirection}`}
      >
        <option value="time|asc">Sort by expiration</option>
        <option value="time|desc">Sort by most recent</option>
        <option value="savings|asc">Sort by savings (low to high)</option>
        <option value="savings|desc">Sort by savings (high to low)</option>
      </Select>
      <Icon
        as={ChevronDownIcon}
        position="absolute"
        right="10px"
        top="50%"
        transform="translateY(-50%)"
        pointerEvents="none"
      />
    </Flex>
  )
}

export const FilteredCollections: React.FC<FilterProps> = ({
  filterConfig,
  setFilterConfig,
}) => {
  const SelectedOptions = ({
    selectedOptions,
    onDeselect,
  }: {
    selectedOptions: any[]
    onDeselect: (option: any) => void
  }) => {
    selectedOptions = selectedOptions.filter((option: any) => option)
    return (
      <Box
        display="flex"
        overflowX={['auto', 'auto', 'auto']}
        alignItems="center"
        width="100%"
        sx={{
          '&::-webkit-scrollbar': {
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        {selectedOptions.map((option) => (
          <Box
            key={option.value}
            className="px-8 py-2 potato-btn-radius primary-potato-btn text-center text-white archivo-black"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            fontSize={['9px', '9px', '13px']}
            m="2px"
            px={['4px', '4px', '6px']}
            maxWidth={['120px', '120px', '200px']}
            height={['32px', '32px', 'auto']}
            flex="0 0 auto"
            boxShadow="none"
          >
            <Box display="flex" alignItems="center" overflow="hidden">
              {option.imageUrl && (
                <img
                  src={option.imageUrl}
                  alt=""
                  style={{
                    width: '28px',
                    height: '28px',
                    marginRight: '8px',
                    flexShrink: 0,
                    borderRadius: '50%',
                  }}
                />
              )}
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {option.label}
              </span>
            </Box>
            <button
              onClick={() => onDeselect(option)}
              style={{
                marginLeft: '8px',
                marginRight: '4px',
                background: 'none',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                flexShrink: 0,
              }}
              onMouseEnter={(e) => ((e.target as HTMLElement).style.color = '#d0d0d0')}
              onMouseLeave={(e) => ((e.target as HTMLElement).style.color = 'white')}
            >
              X
            </button>
          </Box>
        ))}
      </Box>
    )
  }

  const options = useCollectionOptions()
  const deselectGetOption = (optionToDeselect: any) => {
    let selectedValues = getSelectedValues(filterConfig.getCollections, options)
    selectedValues = selectedValues.filter(
      (option: any) =>
        option !== undefined && option.value.toLowerCase() !== optionToDeselect.value.toLowerCase()
    )
    const selectedCollections =
      selectedValues.length === 0 ? undefined : selectedValues.map((option: any) => option.label)

    setFilterConfig({
      ...filterConfig,
      getCollections: selectedCollections as string[] | undefined,
    })
  }

  const deselectGiveOption = (optionToDeselect: any) => {
    let selectedValues = getSelectedValues(filterConfig.giveCollections, options)
    selectedValues = selectedValues.filter(
      (option: any) =>
        option !== undefined && option.value.toLowerCase() !== optionToDeselect.value.toLowerCase()
    )
    const selectedCollections =
      selectedValues.length === 0 ? undefined : selectedValues.map((option: any) => option.label)

    setFilterConfig({
      ...filterConfig,
      giveCollections: selectedCollections as string[] | undefined,
    })
  }

  const giveCollections = filterConfig.giveCollections
  const getCollections = filterConfig.getCollections

  if (!giveCollections && !getCollections) return null

  return (
    <Flex direction="column" alignItems="flex-start" width="100%" gap={4} mb={5}>
      {giveCollections && giveCollections.length > 0 && (
        <Flex alignItems="center" width="100%">
          <Text
            className="text-lg archivo-black text-white mb-0 mr-2"
            minWidth={['40px', '40px', '50px']}
            fontSize={['14px', '14px', 'lg']}
          >
            Give:
          </Text>
          <SelectedOptions
            selectedOptions={getSelectedValues(
              filterConfig.giveCollections,
              options
            )}
            onDeselect={(option) => {
              deselectGiveOption(option)
            }}
          />
        </Flex>
      )}
      {getCollections && getCollections?.length > 0 && (
        <Flex alignItems="center" width="100%">
          <Text
            className="text-lg archivo-black text-white mb-0 mr-2 "
            minWidth={['40px', '40px', '50px']}
            fontSize={['14px', '14px', 'lg']}
          >
            Get:
          </Text>
          <SelectedOptions
            selectedOptions={getSelectedValues(filterConfig.getCollections, options)}
            onDeselect={(option) => {
              deselectGetOption(option)
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}

export const useCollectionOptions = () => {
  const { collectionsData } = useCollectionSwaps()

  return useMemo(() => {
    return Object.entries(collectionsData)
      .sort(([, collectionA], [, collectionB]) =>
        collectionA.collectionName.localeCompare(collectionB.collectionName)
      )
      .map(([key, collection]) => ({
        value: collection.collectionName,
        label: collection.collectionName,
        imageUrl: collection.imageUrl || null,
      }))
  }, [collectionsData])
}

export const getSelectedValues = (collections: string[] | undefined, options: any[]) => {
  if (!collections || collections.length === 0) return []
  return collections.map((col) =>
    options.find((option: any) => option.value.toLowerCase() === col.toLowerCase())
  )
}

export const Filter: React.FC<FilterProps> = ({ setFilterConfig, filterConfig }) => {
  const options = useCollectionOptions()

  const handleGetChange = (selectedOptions: any) => {
    const selectedCollections =
      selectedOptions.length === 0 || selectedOptions[0].value === 'Any'
        ? undefined
        : selectedOptions.map((option: any) => option.label)
    setGetInputValue('Get')
    setFilterConfig({
      ...filterConfig,
      getCollections: selectedCollections as string[] | undefined,
    })
  }

  const handleGiveChange = (selectedOptions: any) => {
    const selectedCollections =
      selectedOptions.length === 0 || selectedOptions[0].value === 'Any'
        ? undefined
        : selectedOptions.map((option: any) => option.label)
    setGiveInputValue('Give')
    setFilterConfig({
      ...filterConfig,
      giveCollections: selectedCollections as string[] | undefined,
    })
  }

  const OptionWithImage = ({ innerProps, label, data, isFocused, isSelected }: any) => {
    return (
      <div
        {...innerProps}
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          backgroundColor:
            isSelected && isFocused
              ? '#E8640D'
              : isSelected
              ? '#FC822F'
              : isFocused
              ? '#f0f0f0'
              : 'white',
          padding: '8px 12px',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: '#e0e0e0',
          },
        }}
      >
        <img
          src={data.imageUrl}
          alt=""
          style={{
            width: '28px',
            height: '28px',
            marginRight: '8px',
            flexShrink: 0,
            borderRadius: '50%',
          }}
        />
        <span style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>{label}</span>
      </div>
    )
  }

  const ClearMultiValueContainer = (props: any) => null

  const customFilterOption = (option: any, inputValue: string) => {
    if (['get', 'give'].includes(inputValue.toLowerCase())) return true
    return option.label.toLowerCase().includes(inputValue.toLowerCase())
  }

  const [inputGiveValue, setGiveInputValue] = useState('Give')
  const [inputGetValue, setGetInputValue] = useState('Get')

  const handleGiveInputChange = (value: any, action: any) => {
    if (action.action === 'input-change') setGiveInputValue(value)
  }
  const handleGetInputChange = (value: any, action: any) => {
    if (action.action === 'input-change') setGetInputValue(value)
  }

  return (
    <Stack direction={['column', 'column', 'row']} spacing={4} width="100%">
      <Flex direction={['column', 'row']} alignItems={['flex-start', 'center']} width="100%">
        <Box flex={1} width="100%">
          <ReactSelect
            options={options}
            isMulti
            closeMenuOnSelect={false}
            value={getSelectedValues(filterConfig.giveCollections, options)}
            hideSelectedOptions={false}
            inputValue={inputGiveValue}
            onInputChange={handleGiveInputChange}
            filterOption={customFilterOption}
            components={{
              Option: OptionWithImage,
              MultiValueContainer: ClearMultiValueContainer,
            }}
            styles={customStyles}
            className="potato-radius new-bg-transparent"
            classNamePrefix="react-select"
            onChange={handleGiveChange}
            placeholder={''}
          />
        </Box>
      </Flex>
      <Flex direction={['column', 'row']} alignItems={['flex-start', 'center']} width="100%">
        <Box flex={1} width="100%">
          <ReactSelect
            options={options}
            isMulti
            closeMenuOnSelect={false}
            value={getSelectedValues(filterConfig.getCollections, options)}
            hideSelectedOptions={false}
            inputValue={inputGetValue}
            onInputChange={handleGetInputChange}
            filterOption={customFilterOption}
            components={{
              Option: OptionWithImage,
              MultiValueContainer: ClearMultiValueContainer,
            }}
            styles={customStyles}
            className="potato-radius new-bg-transparent"
            classNamePrefix="react-select"
            onChange={handleGetChange}
            placeholder={''}
          />
        </Box>
      </Flex>
    </Stack>
  )
}
