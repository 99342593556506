

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAppContext } from '../contexts/appContext'
import { Avatar, Box, Flex, useToast } from '@chakra-ui/react'
import { LeaderboardUserPoints } from '../types/collectionSwapV2'
import LeaderboardTable from '../components/CollectionSwap/LeaderboardTable'

import { useNavigate } from 'react-router-dom'
import { shortenAddress } from '../utils'

const Leaderboard: React.FC = () => {
  const {
    uid,
    displayName,
    avatar,
    pointsMultipliers,
    getUserLeaderboardPoints
  } = useAppContext();

  const [points, setPoints] = useState<LeaderboardUserPoints>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log('pointsMultipliers', pointsMultipliers)
  }, [uid, pointsMultipliers])

  const getLeaderboardPoints = async () => {
    if (uid) {
      setIsLoading(true)
      try {
        let data = await getUserLeaderboardPoints(uid);
        setPoints(data)
      } catch (e: any) {
        console.error("error fetching user leaderboard points", e);
      }
      setIsLoading(false)
    }
  }

  const navigate = useNavigate()


  const handleMenuClick = (path: string) => {
    navigate(path)
  };
  useEffect(() => {
    getLeaderboardPoints();
  }, [uid]);

  return (
    <>
      <Helmet>
        <title>Potato Cat - Leaderboard</title>
      </Helmet>
      {uid && (

        <Box className="max-w-7xl mx-auto">
          <Box className="potato-radius new-bg-transparent shadow-lg p-3 sm:p-4 md:p-6 flex flex-col items-center relative mb-4 sm:mb-8">
            <Flex
              align="center"
              justify="space-between"
              w="full"
              direction={{ base: 'column', md: 'row' }}
            >
              <Flex align="center" mb={{ base: 4, md: 0 }} direction={{ base: 'column', sm: 'row' }}>
                <Avatar size={{ base: "xl", sm: "2xl" }} src={avatar} mb={{ base: 3, sm: 0 }} mr={{ base: 0, sm: 4 }} />
                <Box textAlign={{ base: 'center', sm: 'left' }}>
                  <Flex align="center" justify={{ base: 'center', sm: 'flex-start' }} wrap="wrap">
                    <h1 className="text-2xl sm:text-3xl archivo-black avatar-name mb-2">{displayName || shortenAddress(uid)}</h1>
                  </Flex>
                  <Flex align="center" justify={{ base: 'center', sm: 'flex-start' }} wrap="nowrap">
                    <Box mr={2}>
                      <Flex
                        align="center"
                        justify={{ base: 'center', sm: 'flex-start' }}
                        wrap="nowrap"
                        className="bg-gradient-to-r from-yellow-300 via-orange-300 to-pink-300 potato-radius  mt-2 px-3 sm:px-4 py-1 sm:py-2 font-bold text-sm sm:text-base shadow-lg"
                        style={{
                          background: 'linear-gradient(222deg, #d9b892, #fc822f)',
                        }}
                      >
                        <h1 className="mr-2 text-white">Rank</h1>
                        <h1 className="archivo-black text-white">
                          <span className="inline-block transform -rotate-6 hover:rotate-0 transition-transform duration-300">
                            {points?.rank}
                          </span>
                          <span className="text-lg">/</span>
                          <span className="inline-block transform rotate-6 hover:rotate-0 transition-transform duration-300">
                            {points?.totalUsers}
                          </span>
                        </h1>
                        <span className="ml-2 animate-bounce">🔥</span>
                      </Flex>
                    </Box>
                    <button
                      className="secondary-potato-btn mt-2 px-3 sm:px-4 py-1 sm:py-2 font-bold text-sm sm:text-base"
                      onClick={() => {
                        handleMenuClick('/profile')
                      }}
                    >
                      View Profile
                    </button>

                  </Flex>

                </Box>
              </Flex>
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                gap={{ base: 4, sm: 8, md: 16 }}
                className="mt-4 w-full sm:w-auto"
              >
                <Box textAlign="center" mb={{ base: 0, md: 0 }} flex="1">
                  <p className="text-sm sm:text-md font-semibold whitespace-nowrap">Completed Swaps</p>
                  <div className="potato-radius new-bg-transparent py-1 sm:py-2 mt-1 sm:mt-2">
                    <p className="text-lg sm:text-xl md:text-2xl archivo-black">{isLoading ? "..." : points?.swaps || 0}</p>
                  </div>
                </Box>
                <Box textAlign="center" flex="2">
                  <p className="text-sm sm:text-md font-semibold whitespace-nowrap">Total Potatoes</p>
                  <div className="potato-radius new-bg-transparent py-1 sm:py-2 mt-1 sm:mt-2">
                    <p className="text-lg sm:text-xl md:text-2xl archivo-black  ml-2 mr-2">{isLoading ? "..." : `🥔 ${Math.round(points?.points || 0)}`}</p>
                  </div>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
      )}
      <h1 className="text-2xl archivo-black  mb-4">Leaderboard</h1>

      <div className="max-w-7xl mx-auto  ">
        <LeaderboardTable />
      </div>
      <div className="red-square"></div>
    </>
  )
}

export default Leaderboard

