import { neoColTypes } from '@neoswap/solana-collection-swap'
import { Nft, Collection, CollectionStats } from './nft'

export type OnChainSwapData = neoColTypes.SwapData
export const OnChainSwapType = neoColTypes.SwapType
export type Bid = neoColTypes.Bid

export type BundleTransaction = neoColTypes.BundleTransaction

export type OnChainSwap = {
  sda: string
  data: OnChainSwapData
  bid?: Bid
  bidId?: string
  numBids?: number
}

export type CollectionData = {
  buyPrice: number
  cnft: boolean
  collectionId: string
  collectionName: string
  description: string
  enabled: boolean
  enabledTraitBids?: boolean
  groupMetadataPointerAuthority: string
  imageUrl?: string
  metaplexFirstVerifiedCreator?: string
  metaplexMint?: string
  onChainId: string
  refreshDate: string
  royaltyRate: number
  sellPrice: number
  shCollectionId?: string
  slug: string
  spl20: boolean
  twitterHandle: string
  orderImportance?: number
}

export type TraitData = {
  traitId: string
  traitKey: string
  traitValue: string
  totalNfts: number
  collectionId: string
  image: string
  stats: {
    count: number
    floorPrice: number
    highestBid: number
  }
}

export type TraitCollectionData = CollectionData & TraitData

export type SwapData = {
  makerCollection: CollectionData
  takerCollection: CollectionData | TraitCollectionData

  makerMarketplaceOffer: SwapOffer
  makerSwapOffer: SwapOffer
  makerSwapSavings: SwapSavings

  takerMarketplaceOffer: SwapOffer
  takerSwapOffer: SwapOffer
  takerSwapSavings: SwapSavings

  isOnChain: boolean
  bidId?: string
  onChainSwap?: OnChainSwap
  makerNft?: Nft
  takerNft?: Nft

  roots?: string[]
  traitId?: string
  traitValue?: string
  traitKey?: string
}

export type SwapOffer = {
  totalCost: number
  baseCost: number
  feeRate?: number
  fee: number
  royaltiesRate?: number
  royalties: number
  volume?: number
}

export type SwapSavings = {
  marketplaceCost: number
  swapCost: number
  savings: number
  savingsPercentage?: number
}

export type Item = {
  image: string
  nftName?: string
  collectionName: string
  floorPrice?: number
  onChainId: string
}

export type SwapOverview = {
  giveItem: Item
  getItem: Item
  userSavings: SwapSavings
  endTime: number
  isMaker: boolean
  isOnChain: boolean
  bidId?: string
  traitId?: string
}

export type SortConfig = {
  sortBy: 'time' | 'savings'
  sortDirection: 'asc' | 'desc'
  uid?: string
}

export type FilterConfigV2 = {
  makerCollectionIds?: string[]
  makerCollectionNames?: string[]
  maker?: string[]
  makerExclude?: string[]

  takerCollectionIds?: string[]
  takerCollectionNames?: string[]
  taker?: string[]
  takerExclude?: string[]
  
  keepNegativeSavings?: boolean

  bestFor?: 'maker' | 'taker'
}

export type TraitFilterConfigV2 = FilterConfigV2 & {
  makerTraitIds?: string[]
  takerTraitIds?: string[]
  takerNfts?: Nft[]
  makerNfts?: Nft[]
}

export type FilterConfig = {
  makerCollections?: string[]
  takerCollections?: string[]
  maker?: string[]
  taker?: string[]
  makerExclude?: string[]
  takerExclude?: string[]
  type: 'all' | 'onChain' | 'offChain'
  bestFor?: 'maker' | 'taker'
  keepNegativeSavings?: boolean
}
// export type FilterConfig = {
//   getCollections?: string[]
//   giveCollections?: string[]
//   maker?: string
//   type?: 'all' | 'onChain' | 'offChain' | 'best' | 'mySwaps'
// }

export type LeaderboardUserPoints = {
  points?: number
  swaps?: number
  saved?: number
  rank?: number
  uid?: string
  totalUsers?: number
  name?: string
  image?: string
}

export type PointsStreakKey = 'day3' | 'day8' | 'day13' | 'day20' | 'day27'

export type PointsMultiplier = {
  multiplier: number
  name: string
  startTime: number
  endTime?: number
}

export type PointsConfig = {
  maxDailyPetCat: number
  streakHours: number
  typeformSurveyTimeGapDays: number

  petCatPoints: number
  typeformSurveyPoints: number
  chatbotSurveyPoints: number
  firstTimeConnectPoints: number
  profileCompletePoints: number
  backpackConnectPoints: number
  streakPoints: Record<PointsStreakKey, number>
  makeSwapPoints: number
  takeSwapPoints: number
  nBidsPoints: number[]
}

type BonusPoints = { timestamp: number; points: number }

export type PointsStreakValue = {
  timestamp: number
  value: number
  days: number
  claimed: boolean
}

export type UserPoints = {
  uid: string
  lastConnected: number
  bonuses: {
    connectBackpack: BonusPoints
    connectFirstTime: BonusPoints
    profileComplete: BonusPoints
    surveyTypeform: BonusPoints
    surveyChatbot: BonusPoints
  }
  streak: {
    day3: PointsStreakValue
    day8: PointsStreakValue
    day13: PointsStreakValue
    day20: PointsStreakValue
    day27: PointsStreakValue
  }
  daily: {
    streakDays: number
    petCat: number
    twitter: number
  }
  accumulatedPoints: {
    streak: number
    petCat: number
    makeSwap: number
    takeSwap: number
    bids: number
    twitter: number
  }
  stats: {
    makeSwap: number
    takeSwap: number
  }
}
