import { useCallback, useEffect, useMemo, useState } from 'react'
import { CollectionData, TraitCollectionData, TraitData } from '../types/collectionSwapV2'
import { streamNftCollectionsV2, streamNftCollectionsV2Traits } from '../services/nfts.service'
import { Nft } from '../types/nft'

/**
 * Interface for the return value of useCollections hook
 */
export interface UseCollectionsType {
  collectionsData: CollectionData[]
  traitCollectionsData: TraitCollectionData[]
  findTraitCollectionWBuySellForNfts: (nfts: Nft[]) => CollectionData[]
}

/**
 * Custom hook for managing NFT collections and their traits
 * 
 * @returns {UseCollectionsType} An object containing collections data, trait collections data, and a function to find trait collections with buy/sell prices for NFTs
 */
export default function useCollections(): UseCollectionsType {
  const [collectionsData, setCollectionsData] = useState<CollectionData[]>([])

  const [traitData, setTraitData] = useState<TraitData[]>([])


  useEffect(() => {
    const unsubscribe = streamNftCollectionsV2(setCollectionsData)

    // Unsubscribe from the realtime listener on unmount to prevent memory leaks
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const traitEnabledCollections = collectionsData.filter(col => col.enabledTraitBids)
    
    const unsubscribes = traitEnabledCollections.map(collection => {
      return streamNftCollectionsV2Traits(
        `solana-${collection.onChainId}`,
        (traits: TraitData[]) => setTraitData(prev => ({ ...prev, [collection.onChainId]: traits }))
      )
    })

    // Unsubscribe from all realtime listeners on unmount to prevent memory leaks
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe())
    }
  }, [collectionsData])

  const traitCollectionsData: TraitCollectionData[] = useMemo(() => {
    return Object.entries(traitData).flatMap(([collectionId, traits]) => {
      const collection = collectionsData.find(col => col.onChainId === collectionId)
      if (!collection) return []

      return (Array.isArray(traits) ? traits : [traits]).map((trait: TraitData) => ({
        ...collection,
        ...trait,
        imageUrl: trait.image,
        buyPrice: Math.max(trait.stats.floorPrice, collection.buyPrice),
        sellPrice: Math.max(trait.stats.highestBid, collection.sellPrice),
        traitId: `${trait.traitKey}_${trait.traitValue}`.toLowerCase(),
      }))
      .filter((trait: TraitCollectionData): trait is TraitCollectionData => trait.stats.count > 0 && trait.stats.count < trait.totalNfts)
    })
  }, [traitData, collectionsData])

  const findTraitCollectionWBuySellForNfts = useCallback((nfts: Nft[]) => {
    return nfts.map((nft) => {
      const traitCollection = traitCollectionsData.find((trait) => trait.onChainId === nft.collection.onChainId)
      if (!traitCollection) {
        return null
      }
      let traitCollectionForNft = JSON.parse(JSON.stringify(traitCollection))
      nft.attributes.map((attribute) => {
        let traitId = `${attribute.trait_type}_${attribute.value}`.toLowerCase()
        let tmp = traitCollectionsData.find((trait) => trait.traitId.toLowerCase() === traitId)
        if (tmp?.buyPrice && tmp.buyPrice > traitCollectionForNft.buyPrice) {
          traitCollectionForNft.buyPrice = tmp.buyPrice
        }

        if (tmp?.sellPrice && tmp.sellPrice > traitCollectionForNft.sellPrice) {
          traitCollectionForNft.sellPrice = tmp.sellPrice
        }
      })
      return traitCollectionForNft
    }).filter((col: TraitCollectionData | null): col is TraitCollectionData => col !== null)
  }, [traitCollectionsData])

  return {
    collectionsData,
    traitCollectionsData, 
    findTraitCollectionWBuySellForNfts,
  }
}
