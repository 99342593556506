import React from 'react';
import { Flex, Text, IconButton, useClipboard, useToast } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

interface ShortenedAddressProps {
    address: string;
}

const ShortenedAddress: React.FC<ShortenedAddressProps> = ({ address }) => {
    const { onCopy } = useClipboard(address);
    const toast = useToast();

    const shortenAddress = (addr: string): string => {
        return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
    };

    const handleCopy = () => {
        onCopy();
        toast({
            title: "Address copied successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    return (
        <Flex align="center" ml="10px" className="potato-radius new-bg-transparent mt-2" px={2} py={1}>
            <Text fontSize="sm" fontWeight="medium" mr={2}>
                {shortenAddress(address)}
            </Text>
            <IconButton
                aria-label="Copy address"
                icon={<CopyIcon />}
                onClick={handleCopy}
                size="sm"
                variant="ghost"
                className="potato-radius new-bg-transparent"
            />
        </Flex>
    );
};

export default ShortenedAddress;