import { ModalBody } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SurplusComponent from '../Surplus'
import SwapDetailsComponent from '../SwapDetails'
import CloseButton from '../Buttons/CloseButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import { confetti } from '../../../services/confetti'
import { useUA } from '../../../contexts/userTracking'
import { TwitterButton } from '../Buttons/SharingButtons'

interface OfferStepProps {
  onClose: () => void
  nextStep: () => void
}

const OfferStep: React.FC<OfferStepProps> = function ({ onClose, nextStep }) {
  const { uid, } = useAppContext()
  const { selectedBid, solToUsd } = useCollectionSwaps()
  const { addGAEvent } = useUA()

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid)
  }, [selectedBid, uid])

  useEffect(() => {
    confetti.fire();
  }, []);

  return (
    <ModalBody p={0}>
      <div className="p-6 text-dark-potato">
        <h2 className="pb-4 archivo-black font-2xl">Instant Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && <SwapDetailsComponent {...swapOverview} past={false} />}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && (
              <div className="flex flex-col justify-center h-full">
                <SurplusComponent
                  userSavings={swapOverview?.userSavings}
                  chainUsdValue={solToUsd}
                  calculatedDate={''}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-2 px-6 pb-6 justify-between">
        <CloseButton onClick={onClose} />
        <TwitterButton mode={"i-saved"} onClick={() => addGAEvent("accept-modal_swap_share")}/>
      </div>
    </ModalBody>
  )
}

export default OfferStep
