import React from 'react'
import { Tooltip, defineStyleConfig, useToast, useBreakpointValue } from '@chakra-ui/react'

// export the component theme
export const tooltipTheme = defineStyleConfig({
  baseStyle: { zIndex: 999999 },
})

interface CustomTooltipProps {
  children: React.ReactNode
  label?: string
  isDisabled?: boolean
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, label, isDisabled }) => {
  const toast = useToast()
  const isMobile = useBreakpointValue({ base: true, md: false })

  if (!label) return children
  const handleClick = () => {
    if (isMobile && !isDisabled && label) {
      const toastId = 'custom-toast'
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: label,
          status: 'warning',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  if (isMobile) {
    return (
      <a onTouchStart={handleClick} style={{ cursor: 'pointer', margin: '0', padding: '0' }}>
        {children}
      </a>
    )
  } else {
    return (
      <Tooltip
        label={label}
        isDisabled={isDisabled}
        className="custom-tooltip"
        sx={{
          '--tooltip-bg': '#051A28',
          border: '1px solid #FF4ADA',
          boxShadow: 'none',
          '.chakra-tooltip__arrow': {
            '--popper-arrow-bg': '#051A28',
            top: '-3px !important',
            background: '#fff',
            borderLeft: 'solid 2px #FF4ADA',
            borderTop: 'solid 2px #FF4ADA',
          },
          color: '#444',
          padding: '0.5rem 1rem',
          background: '#fff',
          borderRadius: '20',
          zIndex: 999999999,
        }}
      >
        {children}
      </Tooltip>
    )
  }
}

export default CustomTooltip
