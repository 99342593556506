import { useEffect, useRef } from 'react'
import Talk from 'talkjs'
import { generateRandomString } from '../utils'
import { useUA } from '../contexts/userTracking'

const TalkJSChatbox = () => {
  const { addGAEvent } = useUA();

  useEffect(() => {
    let userId = generateRandomString(20)
    let conversationId = `potatocat_${generateRandomString(20)}`

    Talk.ready.then(() => {
      const me = new Talk.User({
        id: userId,
        name: 'User',
        email: null,
        role: 'chatbotUser',
      })

      const other = new Talk.User({
        id: process.env.REACT_APP_THEO_TALKJS_USER_ID!,
        name: 'Potato Cat',
        email: null,
        role: 'chatbot',
        photoUrl:
          'https://firebasestorage.googleapis.com/v0/b/neoswap-production.appspot.com/o/theo%2FPat.png?alt=media&token=a80c9da3-3ee2-40e7-9895-0e5d8d068159',
        welcomeMessage:
          'Meow! How can Potato Cat assist you today?',
      })

      const session = new Talk.Session({
        appId: process.env.REACT_APP_TALKJS_APP_ID!,
        me: me,
      })

      const conversation = session.getOrCreateConversation(conversationId)

      conversation.setParticipant(me)
      conversation.setParticipant(other)

      const popup = session.createPopup({
        theme: 'potatoCatChatbot',
      })
      popup.select(conversation)
      popup.mount({ show: false })

      popup.onOpen(() => {
        addGAEvent('any_chatbot_open')
      })

      popup.onClose(() => {
        addGAEvent('any_chatbot_close')
      })

      session.onMessage(async (message) => {
        console.log('Message received', message)
        if (message.custom.unlock === 'true') {
          conversation.setParticipant(me, { access: 'ReadWrite' })
          popup.select(conversation)
        }
      })

      return () => session.destroy()
    })
  }, [])


  return <button type="button" id="btn-openPopup" ></button>
}

export default TalkJSChatbox
