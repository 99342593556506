import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore'
import Firebase from './firebase'
import { LeaderboardUserPoints } from '../types/collectionSwapV2'

export const getLeaderboardData = async (): Promise<any> => {
    const db = Firebase.getDBApp()
    const ref = collection(db, 'pointsLeaderboard')

    // Create a query against the collection
    const q = query(ref, orderBy('points', 'desc'), limit(10))

    try {
        const querySnapshot = await getDocs(q)
        const leaderboardData: LeaderboardUserPoints[] = []
        querySnapshot.forEach((doc) => {
            leaderboardData.push({ ...doc.data() })
        })
        return leaderboardData
    } catch (error) {
        console.error('Error fetching leaderboard data:', error)
        throw new Error('Failed to fetch leaderboard data')
    }
}
